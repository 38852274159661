import React from 'react';
import {TriggerTimingRelationElement} from './trigger_timing_relation_element';
import {TriggerTimingRelationExample} from './trigger_timing_relation_example';

export const TriggerTimingRelation =  ()=>{
  return (
  <React.Fragment>
    <TriggerTimingRelationElement />
    <TriggerTimingRelationExample />
  </React.Fragment>
  )
};
