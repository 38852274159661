export const UnderDirectionElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>To Place Kind</th><td>上に乗っているカードの行先を表します</td></tr>
                    <tr><th>To Deck</th><td>上に乗っているカードがデッキにいった際のデッキの種類を表します</td></tr>
                    <tr><th>To Grave</th><td>上に乗っているカードが墓地にいった際の墓地の種類を表します</td></tr>
                    <tr><th>To Hand</th><td>上に乗っているカードが手札にいった際の手札の種類を表します</td></tr>
                    <tr><th>To Field</th><td>上に乗っているカードがフィールドにいった際のフィールドの種類を表します</td></tr>
                    <tr><th>Dest Place Kind</th><td>カードの行先を表します</td></tr>
                    <tr><th>Dest Deck</th><td>カードがデッキにいく場合のデッキの種類を表します</td></tr>
                    <tr><th>Dest Grave</th><td>カードが墓地にいく際の墓地の種類を表します</td></tr>
                    <tr><th>Dest Hand</th><td>カードが手札にいく際の手札の種類を表します</td></tr>
                    <tr><th>Dest Field</th><td>カードがフィールドにいく際のフィールドの種類を表します</td></tr>
                    <tr><th>Dest Mine Or Other</th><td>カードが行く先の持ち主を表します</td></tr>
                    <tr><th>Shuffle</th><td>カードが行く先をシャッフルするかを表します</td></tr>


                </tbody>
            </table>
        </div>
    )
}