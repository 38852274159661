import React from 'react';
export const Tutorial =  ()=>{
    return (
  <React.Fragment>
        <div className="container">
            <h2>チュートリアルで作成するゲーム</h2>
        ここでは簡単なライフ制の対戦型tcgを作っていきたいと思います。以下のように作成します
        <br></br>
        <ul>
            <li>ライフはお互い５０００</li>
            <li>初期手札はお互い５枚</li>
            <li>フェイズはドローフェイズ、メインフェイズ、バトルフェイズ、エンドフェイズ</li>
            <li>ドローフェイズには１枚ドロー</li>
            <li>メインフェイズにはカードの使用、クリーチャーの召喚ができます</li>
            <li>クリーチャーはレベル４以下はノーコストで、レベル５、６以下は手札コスト１枚、レベル７以上は手札コスト２枚で召喚できます</li>
            <li>バトルフェイズにはカードどうしの戦闘及び直接攻撃ができます</li>
            <li>カードどうしの戦闘では攻撃クリーチャーの攻撃力分、対象クリーチャーのライフが減り、０以下になった場合は対象クリーチャーは墓地へ送られ、攻撃力がライフを上回っていた場合、超過分はプレイヤーのライフから引かれます</li>
            <li>直接攻撃では攻撃クリーチャーの攻撃力分相手プレイヤーのライフが引かれます</li>
            <li>エンドフェイズは次ターン移行のみで特に処理なし</li>
            <li>デッキは普通のデッキとEXデッキがあり、デッキの枚数は最低で１０毎最高で１５枚EXデッキの枚数は最低０枚最高５枚です</li>
            </ul>
        </div>
        <div className="container">
            <h2>チュートリアル目次</h2>
            <ul>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_field">フィールドの作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_phase">フェイズの作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_monster_variable">カードパラメータの作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_global_variable">グローバル変数の作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_deck">デッキの作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_grave">墓地の作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_hand">手札の作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_monster">カードの作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_start_up">ゲーム開始処理の作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_to_phase">フェイズ移動の作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_default_deck">構築済みデッキの作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_test">ひとまずテスト</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_draw_phase">ドローフェイズ処理の作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_summon">クリーチャー召喚の作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_battle">クリーチャーどうしの戦闘の作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_direct_attack">直接攻撃の作成</a> </li>
                <li><a href="/tcgcreatoreternalbetaver2/tutorial_end_phase">エンドフェイズ処理の作成</a> </li>
                </ul>
            </div>
  </React.Fragment>
    )
}
