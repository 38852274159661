
export const DownLoad =  ()=>{
    return (
        <div className="container">
            <h2>tcgcreator eternal betaのダウンロード</h2>
            <p>下記ではtcgcreator eternal betaのダウンロードをします。ダウンロードフォルダは以下のようにc:\ユーザー\jidpn(ユーザー名）に保存することをお勧めします</p>
            <p><img src={`${process.env.PUBLIC_URL}/download_folder.png`}></img></p>
            <p><img src={`${process.env.PUBLIC_URL}/download_folder2.png`}></img></p>
            <p><img src={`${process.env.PUBLIC_URL}/download_folder3.png`}></img></p>
            <p><a href={`${process.env.PUBLIC_URL}/tcgcreator_eternal_beta.zip`} download>ダウンロード</a></p>
            <p><a href="./download_element" >ダウンロードしたzipファイルの中身の説明です</a></p>
        </div>
    )
}
