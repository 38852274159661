export const ChainVariable = () => {
    return (
        <div className="container">
            <h2>チェーン変数設定</h2>
            <p>効果処理中のみに有効なglobal変数を設定します</p>
            <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/chain_variable.png`}></img></p>
            <p>この例ではchain_variable(①)という変数名で最小値が1(②)最大値が100(③)になるようにチェーン変数をユーザーからの入力で設定します

            </p>
        </div>
    )
}
