export const ChangeMonsterRelation = () => {
    return (
        <div className="container">
            <h2>モンスターリレーション</h2>
           <p>カードにカードを紐づけます</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/change_monster_relation.png`}></img></p>
           <p>この例ではtrigger(①) という<a href="/tcgcreatoreternalbetaver2//card_variable">カード変数</a>にbattleという名で(②)koka(③)という<a href="/tcgcreatoreternalbetaver2//card_variable">カード変数</a>にモンスター効果で(④)リレーションを受ける(⑤)ように紐づけています</p>
        </div>
    )
}
