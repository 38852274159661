export const AltVal =  ()=>{
    return (
        <div className="container">
            <h2>軽減変数条件</h2>
           <p>変数を軽減する場合、に真、しない場合に偽を返す条件によりMonsterEffectWrapperの次の行先を決めます</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/alt_val_input.png`}></img></p>
           <p>Monster Effectの右の追加をクリックして上図のように変数を選択します(⑪）    。この例では自分のライフに軽減が入っていることを表しています</p>
           <p><img src={`${process.env.PUBLIC_URL}/alt_val.png`}></img></p>
           <p>Monster Effectに図のように書かれれば成功です。この例では自分のライフに軽減条件があれば真、軽減条件がなければ偽となります</p>
        </div>
    )
}
