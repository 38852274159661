import React from 'react';
import {PacWrapperElement} from './pac_wrapper_element';
import {PacWrapperExample} from './pac_wrapper_example';

export const PacWrapper =  ()=>{
  return (
  <React.Fragment>
    <PacWrapperElement />
    <PacWrapperExample />
  </React.Fragment>
  )
};
