export const TriggerTimingRelationElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Trigger</th><td>カードが紐づけされた    場合に呼び出される効果を表します</td></tr>
                    <tr><th>Kinds</th><td>紐づけが有効なカード効果の種類を表します</td></tr>
                    <tr><th>Monster</th><td>紐づいたカードを表します</td></tr>
                    <tr><th>Monster Specify Flag</th><td>効果を受けなかったカードを特定するかを表します</td></tr>
                    <tr><th>Who</th><td>効果の主体のプレイヤーを表します</td></tr>
                    <tr><th>Chain User</th><td>紐づいけを受けたカードのプレイヤーを表します</td></tr>
                    <tr><th>Cost Or Effect</th><td>紐づけがコストか効果かを示します</td></tr>
                    <tr><th>Trigger Timing Name</th><td>この効果の名前です</td></tr>
                    <tr><th>Change Val</th><td>無視してください</td></tr>
                    <tr><th>Change Val Operator</th><td>無視してください</td></tr>
                    <tr><th>Org</th><td>チェックしておいてください</td></tr>
                    <tr><th>Relation Kind</th><td>紐づけの種類です</td></tr>
                    <tr><th>Relation Name</th><td>紐づけの名前です</td></tr>
                    <tr><th>Relation To</th><td>紐づけの向いている方向です</td></tr>
                    <tr><th>Clear Flag</th><td>紐づけが解消されたことを表します</td></tr>
                    <tr><th>Leave Flag Self</th><td>紐づけが場所を移動したことによって解消されたことを表します</td></tr>
                    <tr><th>Leave Flag Null Relate</th><td>紐づけされたカードが場所を移動したことで紐づけが解消されたことを示します</td></tr>
                    <tr><th>Which Monster Effect</th><td>発動する効果の主体を示します</td></tr>
                    <tr><th>Once Per Turn Relate</th><td>発動する効果が紐づけられていた場合1ターンに一度かを示します</td></tr>
                    <tr><th>Once Per Turn Exist</th><td>発動する効果が存在するカードを指定する場合1ターンに一度かを示します</td></tr>
                    <tr><th>Enemy</th><td>NPC対戦時に効果が有効かを示します</td></tr>
                    <tr><th>Enemy Own</th><td>NPC対戦時に効果がプレイヤーまたはNPCが発動できるかを示します</td></tr>
                    <tr><th>Chain</th><td>発動するときの効果チェーン数を表します    </td></tr>
                    <tr><th>Chain Kind</th><td>発動するときの効果チェーン数が以上か、以下かそれともそのチェーンかを表します</td></tr>
                    


                    
                </tbody>
            </table>
        </div>
    )
}