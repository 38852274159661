export const MoveToUnder =  ()=>{
    return (
        <div className="container">
            <h2>下に重ねる </h2>
           <p>フィールドのカードの下にカードを重ねます</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/move_to_under_example.png`}></img></p>
           この例では自分のデッキ(①)の一番上を自分フィールドのx座標が１(②)y座標が１(③)のカードの下にかさねています
           <p><img src={`${process.env.PUBLIC_URL}/move_to_under_card_variable_example.png`}></img></p>
           この例ではkoka(①)で示される<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納されたカードをtrigger(②)で示される<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>で示されるカードの下に送っています
        </div>
    )
}
