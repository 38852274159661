
export const TutorialDrawPhase =  ()=>{
    return (
        <div className="container">
            <h2>ドローフェイズの処理を作る</h2>
            <p>せっかく画面ができましたが、まだゲームができていないのでどんどん作っていきましょう。まあはドローフェイズを作ります。</p>
            <h3>効果処理作成</h3>
            <p>管理画面からMonster effectを選択し、Add Monster Effectを選択します</p>
            <p>Monster Effect Valは移動を選択します</p>
            <p>Monster Effectの右の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_1draw.png`}></img></p>
            <p>ドロー処理を作ります。基本的にスタートアップフェイズ時と同じですが、モンスターの数のminとmaxは１にします</p>
            <p>Monster Effect Nameには１ドローと入力し、Saveをクリックします</p>
            <p>次にメインフェイズに移動する処理を作ります。Add Monster Effectをクリックして、Monster Effect Valにフェイズ移動を入力します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_to_main.png`}></img></p>
            メインフェイズを選択したら選択をクリック、入力できたらMonster Effect Nameにメインフェイズ移動と入力してSaveをクリックします
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_draw_phase.png`}></img></p>
            <p>上記のようになったら成功です</p>
            <h3>効果処理ラッパー作成</h3>
            <p>管理画面からMonster effect wrapperを選択し、Add Monster Effect wrapperを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_wrapper_to_main.png`}></img></p>
            <p>記のように、Monster Effectをメインフェイズ移動Monster Effect Nameをメインフェイズに移動にしてSaveをクリックします</p>
            <p>次にAdd Monster Effect wrapperを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_wrapper_1draw.png`}></img></p>
            上記のように、Monster Effectを1ドローMonster Effect nextをメインフェイズ移動 Monster Effect Kindをドロー（１）Logに(/1/)は１ドローしたと入力してSaveします
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_wrapper_draw_phase.png`}></img></p>
            <p></p>
            <p>上記のようになれば成功です</p>
            <h3>Triggerの作成</h3>
            <p>Triggerの作成ですが、１から入力するのは大変なのでゲーム開始時処理からコピーしましょう</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_draw_phase.png`}></img></p>
            <p>管理画面からTriggerを選択し、ゲーム開始時処理をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_draw_replicate.png`}></img></p>
            <p>画面を一番下までスクロールしてSave as Newをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_draw_phase_input.png`}></img></p>
            <p>PhaseをドローフェイズにNext Effectをドローフェイズ1ドローにします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_draw_phase_input2.png`}></img></p>
            <p>Trigger Nameをドローフェイズ処理にTrigger Kindにドロー（１）を入力します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_draw_phase_input3.png`}></img></p>
            <p>全て入力終えたらSaveをクリックします</p>
            <h3>ドローフェイズが動く確認</h3>
            <p>もう一度ゲームに入りドローフェイズが動いているか試してみましょう</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_test_url.png`}></img></p>
            <p>http://127.0.0.1:8000/tcgcreator/choose/をgoogle chromeで開きます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_test_secret.png`}></img></p>
            <p>クロームをシークレットモードで開きhttp://127.0.0.1:8000/tcgcreator/choose/を開きます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_test_example2.png`}></img></p>
            <p>上記のようにカードが6枚手札にあれば成功です。一回降参しましょう</p>


        </div>
    )
}
