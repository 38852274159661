export const ChooseOpponent = () => {
    return (
        <div className="container">
            <h2>選択相手</h2>
           <h3>使い方</h3>
            <p>効果の相手がカードを選んで<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納します</p>
           <p><img src={`${process.env.PUBLIC_URL}/choose_opponent.png`}></img></p>
           <p>この効果では<a href="/tcgcreatoreternalbetaver2/dialogbox">ダイアログボックス</a>を使って入力していきます。(相手から見て）自分 のデッキ(①)から種族がドラゴン(②)1枚以上1以下(③)をkoka(④)という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納します</p>
        </div>
    )
}
