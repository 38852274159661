import React from 'react';
import './index.css';
import {FieldSizeElement} from './field_size_element';
import {FieldSizeExample} from './field_size_example';

export const FieldSize =  ()=>{
  return (
  <React.Fragment>
    <FieldSizeElement />
    <FieldSizeExample />
  </React.Fragment>
  )
};
