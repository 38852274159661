export const TutorialDeck =  ()=>{
    return (
        <div className="container">
            <h2>デッキの作成</h2>
            ここではゲームで使用するデッキの種類を作成していきます。
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_deck_choose.png`}></img></p>
            <p>上図のようにDeckを選択します </p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_deck_add.png`}></img></p>
            <p>上図のようにAdd Deckを選択します </p>
            <h3>デッキの作成</h3>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_deck_input.png`}></img></p>
            <p>上図の様に入力します。それぞれ以下のような意味があります</p>
            <ul>
                <li>①デッキが個々のプレイヤーに属するか共通のものかを示します。ここではそれぞれのプレイヤーにデッキが属するので特になしとしています </li>
                <li>②デッキの最小の枚数を表します。（例えば遊戯王では４０枚など）。</li>
                <li>③デッキの最大の枚数を表します。（例えば遊戯王では60枚など）。</li>
                <li>④デッキの名前を表します。これはデッキ構築画面で表示されるほかデュエル中でも表示されます </li>
                <li>⑤デッキ内容をプレイヤーに公開するかを選択します。表示しないを選ぶとそうほうのプレイヤーに表示されません。自分のみはデッキの持ち主のみが確認できます。両方では両方のプレイヤーでが確認できます。表のみは表のカードのみ両者が確認できます。自分または表のみはデッキの所有者が確認できるほか、所有者でないプレイヤーも表で見つけられます。全く表示しないにすると対戦画面で残り枚数も確認できなくなります</li>
                <li>⑥デッキ構築画面で編集可能かを決めます</li>
                <li>⑦デュエル中に表示される順番を決めます(大きいものほど上になります)</li>
                <li>⑧デッキから効果を発動可能かを決めます。今回はデッキから発動することはないのでチェックを外しています</li>
                <li>⑨デッキで永続効果を適用可能かを決めます。デッキから永続効果を適用することはないのでチェックを外しています</li>
                <li>⑩入力が終わったら保存します</li>
                </ul>
            <h3>EXデッキの作成</h3>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_deck_input2.png`}></img></p>
            <p>上図の様に入力します。注意点としてはEXデッキは自分は確認可能なのでShowを自分のみにすることです</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_deck_result.png`}></img></p>
            <p>上図のようになればOKです </p>
        </div>
    )
}
