export const MoveUnder =  ()=>{
    return (
        <div className="container">
            <h2>移動under  </h2>
           <p>カードの下にあるカードを移動します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/move_under_example.png`}></img></p>
           この例では自分フィールド(①)のx座標が１(②)y座標が１(③)のカードの下のカードを自分墓地(④)に送っています
           <p><img src={`${process.env.PUBLIC_URL}/move_under_card_variable_example.png`}></img></p>
           この例ではtrigger(①)でしめされる<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納されたカードの下のカードを自分墓地(②)に送っています
        </div>
    )
}
