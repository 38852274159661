export const MoveSimple =  ()=>{
    return (
        <div className="container">
            <h2>シンプル移動</h2>
           <p>カードを移動します（非推奨）</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/move_simple_example.png`}></img></p>
           この例では自分のデッキ(①)の一番上(②③)を自分の墓地(④)に送っています
        </div>
    )
}
