import ReactDOM from 'react-dom/client';
import React from 'react';
export const SpecialCardElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Special Card Name</th><td>特別なカードの名前です</td></tr>
                    <tr><th>Special Card</th><td>特別なカードを表します</td></tr>
                    <tr><th>Constraint</th><td>現在は使われておりません。無視してください</td></tr>
                    <tr><th>Deck</th><td>特別なカードでデッキサイズが変わるデッキです</td></tr>
                    <tr><th>Min Deck Size</th><td>特別なカードがある場合のデッキの最低サイズです</td></tr>
                    <tr><th>Max Deck Size</th><td>特別なカードがある場合のデッキの最大サイズです</td></tr>

                </tbody>
            </table>
        </div>
    )
}