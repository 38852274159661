export const NextTiming = () => {
    return (
        <div className="container">
            <h2>タイミング次移行</h2>
            <p>タイミングにしめされている次のタイミングに移行します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/next_timing.png`}></img></p>
           <p>現在のタイミングから３があった場合は３の次のタイミング、それがなく２があった場合は２の次のタイミング、それらがなければ１の次のタイミングに移行します

           </p>
        </div>
    )
}
