import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="Start">
      <h2>Tcg creator eternal beta</h2>
      tcgcreator eternal betaはプログラミングの知識がなくてもウェブtcgを作れるようにと私スモモンガー
      が作成したweb tcgを作るdjangoベースのツールです。是非使ってみてください。
      <ul className="list-unstyled">
        <li  style={{backgroundColor:"rgba(127,127,127,0.8)"}} className="mb-5">
          <h3>
        <a className="link-dark" href="/tcgcreatoreternalbetaver2/./download">ダウンロード</a>
        </h3>
        まずはtcgcreatoreternalbetaをダウンロード
        </li>
        <li style={{backgroundColor:"rgba(127,0,127,0.8)"}}  className="mb-5">
          <h3>
        <a className="link-dark" href="/tcgcreatoreternalbetaver2/./install">インストール</a>
        </h3>
        ダウンロードしたら開発環境を構築!
        </li>
        <li style={{backgroundColor:"rgba(255,0,0,0.8)"}}  className="mb-5">
          <h3>
        <a className="link-dark" href="/tcgcreatoreternalbetaver2/launch">起動</a>
        </h3>
        開発環境を構築したら起動してみよう
        </li>
        <li style={{backgroundColor:"rgba(0,255,0,0.8)"}}  className="mb-5">
          <h3>
        <a className="link-dark" href="/tcgcreatoreternalbetaver2/./tutorial">チュートリアル</a>
        </h3>
        環境構築をしたら、チュートリアルで実際に使ってみよう
        </li>
        <li  style={{backgroundColor:"rgba(0,255,255,0.8)"}} className="mb-5">
          <h3>
        <a className="link-dark" href="/tcgcreatoreternalbetaver2/./document">ドキュメント</a>
        </h3>
        上級者向けのドキュメントです
        </li>
        <li  style={{backgroundColor:"rgba(255,0,255,0.8)"}}  className="mb-5">
          <h3>
        <a className="link-dark" href="/tcgcreatoreternalbetaver2/./table">効果種類</a>
        </h3>
        Monster Effectで使われる効果の種類です
        </li>
        <li  style={{backgroundColor:"rgba(255,255,0,0.8)"}} className="mb-5">
          <h3>
        <a className="link-dark" href="https://www.youtube.com/@sumomonga6018">動画</a>
        </h3>
        Tcgcreatoreternalbetaで実際にwebtcgを作っている動画です
        </li>
        <li style={{backgroundColor:"rgba(0,0,255,0.8)"}} className="mb-5">
          <h3>
        <a  className="link-dark" href="/tcgcreatoreternalbetaver2/./game">tcgcreator eternal betaで作られたゲーム</a>
        </h3>
        Tcgcreatoreternalbetaで私スモモンガーがインディーズtcg作者様の許可を得て作成したgameです
        </li>
        </ul>

    </div>
  );
}

export default App;
