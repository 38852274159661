import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Header} from './header';
import {Footer} from './footer';
import {EternalWrapperElement} from './eternal_wrapper_element';
import {EternalWrapperExample} from './eternal_wrapper_example';
import reportWebVitals from './reportWebVitals';

export const EternalWrapper =  ()=>{
  return (
  <React.Fragment>
    <EternalWrapperElement />
    <EternalWrapperExample />
  </React.Fragment>
  )
};
