import ReactDOM from 'react-dom/client';
import React from 'react';

export const NavTable =  ()=>{
    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/tcgcreatoreternalbetaver2/table" id="navbarTableDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                メニュー画面のテーブル
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarTableDropdown">
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/background">Background</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/config">Config</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/constraint">Constraint</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/cost_wrapper">Cost Wrapper</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/cost">Cost</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/deck">Deck</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/default_deck_choice">Default Deck Choice</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/default_deck_group">Default Deck Group</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/default_deck">Default Deck</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/duel_deck">Duel Deck</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/duel_grave">Duel Grave</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/duel_hand">Duel Hand</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/duel">Duel</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/end_chain_effect">End Chain Effect</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/enemy_deck_choice">Enemy Deck Choice</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/enemy_deck_group">Enemy Deck Group</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/enemy_deck">Enemy Deck</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/eternal_effect">Eternal Effect</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/eternal_trigger">Eternal Trigger</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/eternal_wrapper">Eternal Wrapper</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/field_kind">Field Kind</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/field_size">Field Size</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/field">Field</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/fusion">Fusion</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/global_variable">Global Variable</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/grave">Grave</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/hand">Hand</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/lock">Lock</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/monster_effect_kind">Monster Effect Kind</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/monster_effect_wrapper">Monster Effect Wrapper</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/monster_effect">Monster Effect</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/monster_variable_kind">Monster Variable Kind</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/monster_variable">Monster Variable</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/monster">Monster</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/pac_cost_wrapper">Pac Cost Wrapper</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/pac_cost">Pac Cost</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/pac_wrapper">Pac Wrapper</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/pac">Pac</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/phase">Phase</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/special_card">Special Card</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/timing">Timing</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/trigger_timing_change_val">Trigger Timing Change Val</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/trigger_timing_monster_change_val">Trigger Timing Monster Change Val</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/trigger_timing_not_effected">Trigger Timing Not Effected</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/trigger_timing_relation">Trigger Timing Relation</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/trigger_timing">Trigger Timing</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/trigger">Trigger</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/under_direction">Under Direction</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/user_deck_choice">User Deck Choice</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/user_deck_group">User Deck Group</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/user_deck">User Deck</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/user_point">User Point</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/virtual_variable">Virtual Variable</a>
                </div>       </li>
    )
}
