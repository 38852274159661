import ReactDOM from 'react-dom/client';
import React from 'react';
export const MonsterEffectWrapperExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            MonsterEffect Wrapperは効果の実行をラップするテーブルです
            <p>
            <img src={`${process.env.PUBLIC_URL}/cost_wrapper_example.png`}></img>
            </p>
            この例では効果として１枚手札を捨てています。その際ログに捨てたプレイヤーが何を捨てたかを伝えています。また、次の処理として1ドローする処理につながっています
        </div>
    )
}
