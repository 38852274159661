export const ChangeGlobalVariableMultiple = () => {
    return (
        <div className="container">
            <h2>変数変動複数</h2>
           <p>複数のグローバル変数を変更します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/change_global_variable_by_monster.png`}></img></p>
           <p>この例では自分のライフを100上げて相手のライフを１００下げています。①で変数を変動する値を決めるdialogboxを表示して②で追加します</p>
        </div>
    )
}
