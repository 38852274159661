
export const Install =  ()=>{
    return (
        <div className="container">
            <h2>tcgcreator eternal betaのインストール</h2>
            <p>tcgcreatorをダウンロードします<a href="/tcgcreatoreternalbetaver2/download">tcgcreator_eternal_betaのダウンロード</a></p>
            <p><img src={`${process.env.PUBLIC_URL}/user_file_name.png`}></img></p>
            <p>downloadしたtcgcreatorinstallscript.zipをc:\Users:\{'{'}ユーザー名{'}'}のようにユーザー名のフォルダに移動します</p>
            <p><img src={`${process.env.PUBLIC_URL}/download_unzip.png`}></img></p>
            <p>上図のようにクリックして全て展開をクリックします</p>
            <h3>pythonのインストール</h3>
            <p><img src={`${process.env.PUBLIC_URL}/install_python.png`}></img></p>
            <p>上図のようにpython-3.11.4-amd64.exeを実行します</p>
            <p><img src={`${process.env.PUBLIC_URL}/install_python_gui.png`}></img></p>
    	    <p>Add python.exe to pathにチェックを入れInstall Nowを選択します</p>
    	    <p>このアプリがデバイスに変更を加えることを許可しますか？と出てくるので「はい」をクリックします</p>
            <h3>Maria DBのインストール</h3>
            <p><img src={`${process.env.PUBLIC_URL}/install_maria.png`}></img></p>
            <p>上図のようにmariadb-11.0.2-winx64.msiを実行します</p>
            <p><img src={`${process.env.PUBLIC_URL}/install_maria_gui.png`}></img></p>
            <p>上図のようにNextをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/install_maria_gui2.png`}></img></p>
            <p>上図のようにライセンスに同意しNextをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/install_maria_gui3.png`}></img></p>
            <p>上図のようNextをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/install_maria_gui4.png`}></img></p>
            <p>①と②はhogehogeと入力してください。（本番環境では別のパスワードにします）Nextをクリックしてください。</p>
            <p><img src={`${process.env.PUBLIC_URL}/install_maria_gui5.png`}></img></p>
            <p>Nextをクリックしてください。</p>
            <p><img src={`${process.env.PUBLIC_URL}/install_maria_gui6.png`}></img></p>
            <p>Installをクリックしてください。</p>
    	    <p>このアプリがデバイスに変更を加えることを許可しますか？と出てくるので「はい」をクリックします</p>
            <h3>power shellのインストール</h3>
            <p><img src={`${process.env.PUBLIC_URL}/install_bat.png`}></img></p>
    	    <p>上図のようにpowershell.batをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/install_bat2.png`}></img></p>
    	    <p>上図のようになりますのでYを入力します</p>
    	    <p>このアプリがデバイスに変更を加えることを許可しますか？と出てくるので「はい」をクリックします</p>
            <p>windowsを再起動してください</p>
            <h3>残りのインストール</h3>
            <p><img src={`${process.env.PUBLIC_URL}/mariapath.png`}></img></p>
    	    <p>上図のようにmariapath.batをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/install_other.png`}></img></p>
    	    <p>上図のようにinstall.batをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/install_superuser.png`}></img></p>
            <p>上図のように出るのでユーザー名とパスワードを入力します（emailは入力する必要はありません。パスワードは２回入力する必要があります)</p>
            <p>これでインストールは終わりです。お疲れさまでした。</p>
        </div>
    )
}
