export const TutorialDirectAttack =  ()=>{
    return (
        <div className="container">
            <h2>直接攻撃の作成</h2>
            <h3>効果の作成</h3>
            <h4>ダメージの作成</h4>
            <p>管理画面でMonsterEffectを選択してAdd Monster Effectをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_direct_attack_effect.png`}></img></p>
            <p>Monster Effect Valに変数変動を選択しMonster Effectの右の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_direct_attack_effect2.png`}></img></p>
            <p>ダイアログボックスに上記のように入力します。</p>
            <ul>
                <li>①直接攻撃では相手ライフにダメージが入ります</li>
                <li>②ダメージなのでライフは減ります</li>
                <li>③ここを選択すると数式エディタが立ち上がります</li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_direct_attack_effect3.png`}></img></p>
            <p>数式エディタに上記のように入力します。</p>
            <ul>
                <li>①②参照するのは攻撃するクリーチャーの攻撃力です</li>
                <li>③　①②を入力し終わったらクリックします</li>
                <li>④ ここをコピーしてします</li>
                </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_direct_attack_effect4.png`}></img></p>
            <p>上記の④をコピーしたものを上図のようにペーストして、追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_direct_attack_effect5.png`}></img></p>
            <p>Monster Effect Nameに相手ライフがtriggerの攻撃力分減ると入力して、Saveをクリックして保存します   </p>
            <h3>効果のラッパーの作成</h3>
            <h4>相手ライフ０以下判定のラッパー</h4>
            <p>管理画面からMonster Effect Wrapperを選択し、Add Monster Effect Wrapperをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_direct_attack_wrapper].png`}></img></p>
            <p>相手ライフが０以下の場合の処理はクリーチャーどうしの戦闘でも作りましたが、直接攻撃の時はクリーチャーどうしの処理を行わないため別に作ります</p>
            <ul>
                <li>
                    ①相手ライフが０以下の場合に処理②が行われることを表します
                </li>
                <li>
                   ②相手ライフが０以下なら勝利します
                </li>
                <li>
                   ③直接攻撃の時は相手ライフが０以上であればそれ以上処理は行いません
                </li>
                <li>
                  ④ラッパーの名前です
                </li>
                </ul>
            <h4>相手ライフにダメージを与えるラッパー</h4>
                <p>続けてダメージを受ける効果のラッパーを作ります。Add Monster Effectをクリックしてください。</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_direct_attack_wrapper2.png`}></img></p>
            <p>上図のように入力します</p>
            <ul>
                <li>①相手ライフが直接攻撃によって攻撃クリーチャーの攻撃力分減少することを表します</li>
                <li>②先ほど作った相手ライフが０以下の時の勝利判定です</li>
                <li>③ラッパーの名前です</li>
                <li>④ダメージを与えた際のログです</li>
            </ul>
            <h3>効果のtriggerの作成</h3>
            <p>管理画面化からTriggerを選択して、クリーチャーに攻撃を選択します</p>
            <p>画面をしたまでスクロールしてSave as Newをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_direct_attack_trigger.png`}></img></p>
            <p>Next Effectを直接攻撃ダメージにします。Trigger Conditionの追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_direct_attack_trigger2.png`}></img></p>
            <p>モンスターの数のminとmaxに０を入力します。直接攻撃は相手モンスターゾーン上にクリーチャーが存在しない場合に直接攻撃できるからです</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_direct_attack_trigger3.png`}></img></p>
            <p>Trigger Nameに直接攻撃と入力、画面下までスクロールしてSaveをクリックします</p>
            <p>直接攻撃をクリックし、画面をしたまでスクロールinsertをクリックします</p>
            <p>クリーチャーにチェックを入れてsubmitをクリックします</p>
            <p>以上で戦闘の作成はおしまいです。お疲れさまでした。</p>


            
            </div>

    )
}
