import ReactDOM from 'react-dom/client';
import React from 'react';
export const EternalWrapperExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
              EternalWrapperはEteranlTriggerで呼び出される処理です
            <p>
            <img src={`${process.env.PUBLIC_URL}/enemy_trigger_example.png`}></img>
            </p>
            <p>この例では１ドローをしています</p>
            
        </div>
    )
}
