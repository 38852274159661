export const PhaseExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            フェイズ情報を保存するテーブルです 
            <p>
            <img src={`${process.env.PUBLIC_URL}/phase_example.png`}></img>
            </p>
        <p>この例ではドローフェイズを作っています </p>
            
        </div>
    )
}
