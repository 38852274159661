export const HandElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Mine Or Other</th><td>手札がプレイヤーごとのものか共通のものかを表します。特になしがプレイ毎、共通が手札が共有されることを表します  </td></tr>
                    <tr><th>Show</th><td>手札がプレイヤーに対して表示されるかを表します</td></tr>
                    <tr><th>Hand Name</th><td>手札の名前です</td></tr>
                    <tr><th>Invoke</th><td>手札内でカードが効果を発動する場合があるかをしめします</td></tr>
                    <tr><th>Eternal</th><td>手札内で永続効果が発動するかを表します</td></tr>
                </tbody>
            </table>
        </div>
    )
}