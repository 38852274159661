import ReactDOM from 'react-dom/client';
import React from 'react';
export const CostWrapperElements =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Cost</th><td>このラッパー内で処理するコストです。</td></tr>
                    <tr><th>Cost Kind</th><td>コストの種類です。</td></tr>
                    <tr><th>Cost Kind rel</th><td>コストで関係が生じている場合そのカードに対して適用されるコストの種類です</td></tr>
                    <tr><th>Cost Next</th><td>次のコストです。（次のCost Wrapperを選択します)</td></tr>
                    <tr><th>Cost Next2</th><td>次のコストです。例えばCostで条件を選択した場合条件を満たさない場合こちらが処理されます。</td></tr>
                    <tr><th>Pac</th><td>次のまとめられたコストです。Cost Nextが選ばれていない場合こちらに移動します</td></tr>
                    <tr><th>Pac2</th><td>次のまとめられたコストです。条件等で条件を満たしておらずかつCost Next2が空の場合こちらが処理されます</td></tr>
                    <tr><th>Cost Name</th><td>このラッパーの名前です。記号は名前に使わないでください</td></tr>
                    <tr><th>Log</th><td>このコストが実行された場合に残されるLogです。<a href="/tcgcreatoreternalbetaver2/explian_log">こちらで詳しく解説します</a></td></tr>
                    <tr><th>Prompt</th><td>このコストが実行される場合にユーザーから入力を求める場合表示される文字列です</td></tr>
                    <tr><th>Sentence </th><td>このコストが実行される場合にユーザーが選択するボタンの文言です</td></tr>
                    <tr><th>Strategy </th><td>NPCがカードを選ぶ場合優先度を決めます</td></tr>
                    <tr><th>Strategy Up Or Down</th><td>Strategyに入れられた値の大きいほうを優先するか小さい方を優先するか決めます</td></tr>
                    <tr><th>If not 2</th><td>処理するコストでコストを選ぶ際に選ぶカードがない場合Cost Next2またはPac2に飛びます</td></tr>
                </tbody>
            </table>
        </div>
    )
}
