export const TriggerTimingRelationExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            カードが紐づけられたまたは紐づけが取れたときに呼び出されます
            <p>
            <img src={`${process.env.PUBLIC_URL}/trigger_timing_relation_example.png`}></img>
            </p>
        <p>この例では戦士がモンスター効果でequipという紐づけがなされたときに１ドローする効果を表しています</p>
            
        </div>
    )
}
