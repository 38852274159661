import ReactDOM from 'react-dom/client';
import React from 'react';
export const MonsterEffectExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            Monster Effectは効果の処理を書くためのテーブルです   
            <p>
            <img src={`${process.env.PUBLIC_URL}/monster_effect_example.png`}></img>
            </p>
            <p>効果で1枚手札を捨てる効果を書いています。Monster Effect部分についてはjsonというもので書かれていて人間が書くのは難しいので右の追加から<a href="/tcgcreatoreternalbetaver2/dialog_box">ダイアログボックス</a>を表示して入力します</p>
            
        </div>
    )
}
