import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Header} from './header';
import {Footer} from './footer';
import {DefaultDeckChoiceElement} from './default_deck_choice_element';
import {DefaultDeckChoiceExample} from './default_deck_choice_example';
import reportWebVitals from './reportWebVitals';

export const DefaultDeckChoice =  ()=>{
  return (
  <React.Fragment>
    <DefaultDeckChoiceElement />
    <DefaultDeckChoiceExample />
  </React.Fragment>
  )
};
