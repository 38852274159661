export const DoEternal = () => {
    return (
        <div className="container">
            <h2>永続リセット</h2>
            <p>処理の途中に永続効果をリセットします  </p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/do_eternal.png`}></img></p>
           <p>処理の途中で永続効果をリセットします。これによって例えばフィールドで永続効果を及ぼしていたカードが墓地に言っていた場合、その効果を無効にすることができます
           </p>
        </div>
    )
}
