import ReactDOM from 'react-dom/client';
import React from 'react';
export const GlobalVariableElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Variable Name</th><td>グローバルな変数の名前です</td></tr>
                    <tr><th>Priority</th><td>グローバルな変数の優先度です。画面に表示される場合優先度の高いものから表示されます</td></tr>
                    <tr><th>Show</th><td>グローバルな変数を画面に表示するかを決めます</td></tr>
                    <tr><th>Initial Value</th><td>グローバルな変数がゲーム開始時にとる値を決めます</td></tr>
                    <tr><th>Mine or Other</th><td>グローバルな変数が個々のプレイヤーのものか共通のものかを決めます</td></tr>
                    <tr><th>Center</th><td>チェックしないでください</td></tr>
                    <tr><th>Font Size</th><td>グローバル変数が表示される際のフォントの大きさを決めます</td></tr>


                </tbody>
            </table>
        </div>
    )
}