import React from 'react';

export const CardVariable =  ()=>{
  return (
    <div className="container">
        <h1>カード変数</h1>
        <p>ここではカード変数の説明を行います</p>
        <h3>予約語</h3>
        <p>予約語はカード変数としてあらかじめ使われているものをさします。</p>
        <div className="list-group list-group-numbered">
            <span className="list-group-item"><span className="h3">trigger</span> 効果を引き起こしたカード（効果処理中）を表します</span>
            <span className="list-group-item"><span className="h3">this</span> 効果の主体を表します</span>
            <span className="list-group-item"><span className="h3">trigger_exist</span> 効果のために存在していた対象を表します</span>
            <span className="list-group-item"><span className="h3">trigger_relate</span> 効果のために効果を引き落としたカードと紐づいていた対象を表します</span>
            <span className="list-group-item"><span className="h3">trigger_all</span> trigger trigger_exist trigger_relateのうち効果の主体と表します</span>
            <span className="list-group-item"><span className="h3">~tigger</span> triggerのコスト版です</span>
        </div>
        <h3>接頭語</h3>
        <p>カード変数は接頭語によって意味が変わってきます以下接頭語を示します</p>
        <div className="list-group list-group-numbered">
            <span className="list-group-item"><span className="h3">~</span>コストで使われることを示します（効果処理時にも参照は可能ですが、代入はできません）</span>
            <span className="list-group-item"><span className="h3">%</span>なんらかのタイミングが有効である場合保持される変数です。接頭語のつかないカード変数は効果処理が終わると解放されてしまうので戦闘など複数の効果にまたがる場合にはカード変数をタイミング変数に昇格させなくてはなりません。</span>
         </div>
         </div>

  )
};
