export const GlobalVariableChangeOpponent = () => {
    return (
        <div className="container">
            <h2>グローバル変数設定相手</h2>
            <p>global変数を相手が設定します</p>
            <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/global_variable_change_opponent.png`}></img></p>
            <p>この例では相手ライフ(①)を最小値が2000(②)最大値が5000(③)になるように相手ユーザーからの入力で設定します

            </p>
        </div>
    )
}
