import React from 'react';
import './index.css';
import {GraveElement} from './grave_element';
import {GraveExample} from './grave_example';

export const Grave =  ()=>{
  return (
  <React.Fragment>
    <GraveElement />
    <GraveExample />
  </React.Fragment>
  )
};
