export const RelationMultiple = () => {
    return (
        <div className="container">
            <h2>多対多</h2>
            <p>多対多のカード紐づけをプレイヤーが設定します</p>
            <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/relation_multiple1.png`}></img></p>
           <p><img src={`${process.env.PUBLIC_URL}/relation_multiple2.png`}></img></p>
            <p>この例では紐づけ元(①)でtrigger(⑥)に重ね掛けありで(⑤)でbattle(②)という名でモンスター効果(③)でリレーションを受ける(④)で紐づけ先(①)相手フィールド(②)と紐づけをプレイヤーが決めます
            </p>
        </div>
    )
}
