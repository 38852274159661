export const TriggerElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Mine Or Other</th><td>自分を選んでください</td></tr>
                    <tr><th>Priority </th><td>効果処理の優先度です。優先度が高いものほど先にチェーンを組まれます</td></tr>
                    <tr><th>Storategy Priority </th><td>priorityが同じ場合これが高いものほど先にチェーンを組まれます</td></tr>
                    <tr><th>Turn</th><td>効果を発動するターンを選びます</td></tr>
                    <tr><th>Phase</th><td>効果を発動するフェイズを選びます</td></tr>
                    <tr><th>Chain</th><td>効果を発動するチェーン数を選びます</td></tr>
                    <tr><th>Chain Kind</th><td>効果を発動するチェーン数が以下か以上かちょうどかを選びます</td></tr>
                    <tr><th>No Chain</th><td>発動する効果がチェーンしないことを表します</td></tr>
                    <tr><th>Force</th><td>発動する効果が強制効果であることを示します</td></tr>
                    <tr><th>Pac</th><td>効果処理をPac Wrapperから始めることを表します</td></tr>
                    <tr><th>Monster Effect</th><td>効果処理を始めるMonster Effect Wrapperを表します</td></tr>
                    <tr><th>Trigger Condition</th><td>効果処理の条件が書かれたjsonです。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Trigger Monster</th><td>効果処理の主体となるカードの条件が書かれたjsonです。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Trigger None Monster</th><td>効果処理が特定のカードのものでないことを表します</td></tr>
                    <tr><th>Trigger Name</th><td>効果の名前です</td></tr>
                    <tr><th>Trigger Sentence</th><td>効果を選ぶ際に表示します</td></tr>
                    <tr><th>Trigger Prompt</th><td>任意効果の場合効果を発動するかの質問文です</td></tr>
                    <tr><th>Trigger Cost</th><td>効果のコスト処理のはじめを表すCost Wrapperです</td></tr>
                    <tr><th>Trigger Cost Pac</th><td>効果のコスト処理のはじめを表すPac Cost Wrapperです</td></tr>
                    <tr><th>Trigger Kind</th><td>効果の効果種類を表します</td></tr>
                    <tr><th>Timing</th><td>効果が有効なタイミングを表します</td></tr>
                    <tr><th>Timing2</th><td>効果が有効なタイミングを表します</td></tr>
                    <tr><th>Timing3</th><td>効果が有効なタイミングを表します</td></tr>
                    <tr><th>Timing Trigger</th><td>効果がTrigger Timing等から呼び出されることを表します</td></tr>
                    <tr><th>None Timing</th><td>効果がタイミングに入っていなくても有効なことを表します</td></tr>
                    <tr><th>None Timing2</th><td>効果がタイミングに入っていなくても有効なことを表します</td></tr>
                    <tr><th>None Timing3</th><td>効果がタイミングに入っていなくても有効なことを表します</td></tr>
                    <tr><th>Log</th><td>効果が使用されたときのログを表します</td></tr>
                    <tr><th>Chain Mine Or Other</th><td>効果がチェーンできる効果のプレイヤーを表します</td></tr>
                    <tr><th>Can Chain Kind</th><td>効果がチェーンできる効果の種類を表します</td></tr>
                    <tr><th>Copy</th><td>効果がコピーされる場合を示します</td></tr>
                    <tr><th>Copy2</th><td>効果がコピーされる場合を示します</td></tr>
                    <tr><th>Copy3</th><td>効果が条件を無視してコピーされる場合を示します</td></tr>
                    <tr><th>Canbetwice</th><td>効果がチェーン中に二回存在できるかを示します</td></tr>
                    <tr><th>Monstercanbetwice</th><td>効果がカード毎にチェーン中に二回存在できるかを示します</td></tr>
                    <tr><th>Existcanbetwice</th><td>効果が存在するカード毎にチェーン中に二回存在できるかを示します</td></tr>
                    <tr><th>Canbe Chained</th><td>効果がチェーン中できるかを示します</td></tr>
                    <tr><th>None Exist</th><td>効果がカードが存在しなくても有効なことをしめします</td></tr>
                    <tr><th>Once Per Turn</th><td>効果が１ターンに一度であることを示します</td></tr>
                    <tr><th>Once Per Duel</th><td>効果がヂュエルに一度であることを示します</td></tr>
                    <tr><th>Once Per Turn Monster</th><td>効果がカード枚に１ターンに一度であることを示します</td></tr>
                    <tr><th>Once Per Turn Group</th><td>効果が同じ数値をもったグループで１ターンに一度であることを示します</td></tr>
                    <tr><th>Once Per Turn Monster Group</th><td>効果が同じ数値をもったグループでカード毎に１ターンに一度であることを示します</td></tr>
                    <tr><th>Once Per Turn Monster Group</th><td>効果が同じ数値をもったグループでカード毎に１ターンに一度であることを示します</td></tr>
                    <tr><th>Enemy</th><td>NPC対戦時に効果が有効かを示します</td></tr>
                    <tr><th>Enemy Own</th><td>NPC対戦時に効果がプレイヤーまたはNPCが発動できるかを示します</td></tr>
                    <tr><th>Strategy</th><td>NPCが優先するカードパラメータを示します</td></tr>
                    <tr><th>Strategy Up Or Down</th><td>NPCが優先するカードパラメータの優先が降順か昇順かを示します</td></tr>
                    <tr><th>Immediate Flag</th><td>無視してください</td></tr>
                    <tr><th>Chain Flag</th><td>効果がチェーン数を増やすかを表します</td></tr>
                    <tr><th>Fusion Monster</th><td>効果が融合を含んでいる場合に融合して現れるカードです</td></tr>
                    <tr><th>Instead Condition</th><td>効果で融合する場合特定の条件を満たしたときにカードを融合する条件を拡張することを示します</td></tr>
                    <tr><th>Fusion Flag</th><td>効果で融合するかどうかを示します</td></tr>
                    <tr><th>Fusion1</th><td>効果で融合する素材を示すjsonです。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Instead1</th><td>Instead Conditionが満たされている場合素材を示すjsonです。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Fusion2</th><td>効果で融合する素材を示すjsonです。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Instead2</th><td>Instead Conditionが満たされている場合素材を示すjsonです。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Fusion3</th><td>効果で融合する素材を示すjsonです。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Instead3</th><td>Instead Conditionが満たされている場合素材を示すjsonです。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>






                    


                    


                    
                </tbody>
            </table>
        </div>
    )
}
