export const DoCost = () => {
    return (
        <div className="container">
            <h2>コスト実行</h2>
            <p>コストの処理を行います   </p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/do_cost.png`}></img></p>
           <p>コストの処理の途中でコストを実行します。コストは通常全ての処理を一括で行いますが、これを使うことによって例えばコストによって捨てたカードを対象にすることができるようになります
           </p>
        </div>
    )
}
