export const TutorialBattle =  ()=>{
    return (
        <div className="container">
            <h2>クリーチャーどうしの戦闘の作成</h2>
            <p>いよいよ戦闘の作成に入っていきます。以下のようなシンプルな戦闘を作成します</p>
            <ul>
                <li>自分フィールド上のカードが相手フィールド上のカードを攻撃できる</li>
                <li>攻撃により相手カードのライフは攻撃カードの攻撃力分減る</li>
                <li>攻撃にライフが０以下になった場合は相手カードは破壊される</li>
                <li>攻撃カードの攻撃力が相手カードのライフを上回っていた場合はその分相手プレイヤーにダメージを与える</li>
                <li>攻撃は１つのカードにつき１ターンに一度まで</li>
                </ul>
            <h3>戦闘過程の作成</h3>
            <h4>攻撃するカードを選択</h4>
            <p>管理画面からMonster effectを選択し、Add Monster Effectをクリックします</p>
            <p>Monster Effect Valを選択自分にして、Monster Effectの右の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_choose.png`}></img></p>
            <p>ダイアログボックスに上図のように入力します。以下に意味を示します</p>
            <ul>
                <li>①攻撃するカードを相手モンスターゾーンから選択することを示します</li>
                <li>②攻撃するカードを１体のみ選ぶことを示します</li>
                <li>③opponentというカード変数に攻撃カードを保存することをしめします</li>
            </ul>
            <p>Monster Effect Nameに攻撃するカードを選択を入力し、Saveを入力してください</p>
            <h4>攻撃されるカードのライフが攻撃カードの攻撃力を下回っていた場合プレイヤーにダメージ</h4>
            <p>Add Monster Effectをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_life.png`}></img></p>
            <p>上図のようにMonster Effect Valに変数変更を選択しMonster Effectの右の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_life_change.png`}></img></p>
            <p>上図のように入力の値を入力します以下のような意味があります</p>
            <ul>
                <li>①相手のライフを変動させることをしめします</li>
                <li>②相手のライフを減らすことを表しています</li>
                <li>③減らすライフの量を入力します。入力方法については後述します</li>
            </ul>
            <p>③をfocusすると青いダイアログボックスが出てくると思いますがここで変動するライフの値を計算します。</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_eq_editor1.png`}></img></p>
            <ul><li>①計算する主体がopponentというカード変数に格納されたカードであることを示します</li>
            <li>②計算に使用される値がライフであることを示します</li>
            <li>③　①②を入力し終えたらクリックします。④に計算する値があらわれます </li>
            <li>④計算する値が表示されます。ここではopponentのライフ分の値が計算されることを示します</li>
            </ul>
            <p>④の値は後に使用します。どこかメモ帳にでも貼り付けておきましょう</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_monster_life_eq_editor.png`}></img></p>
            <p>次に上図のように入力します。以下のような意味があります</p>
            <ul><li>①計算する主体がtrigger（カード効果の主体、この場合は攻撃するカード）であることを示します</li>
            <li>②計算に使用される値が攻撃力であることを示します</li>
            <li>③　①②を入力し終えたらクリックします。④に計算する値があらわれます </li>
            <li>④計算する値が表示されます。ここではtriggerの攻撃力分の値が計算されることを示します</li>
            </ul>
            <p>④の値は後に使用します。どこかメモ帳にでも貼り付けておきましょう</p>
            <h5>ダメージの計算</h5>
            <p>今までの値をもとに以下の式を作ります({'{'}trigger:攻撃力::::::0:::0{'}'}${'{'}opponent:ライフ::::::0:::0{'}'}$-$0$MAX)</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_life_result.png`}></img></p>
            <p>上図の①に式を入れて②をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_life_result2.png`}></img></p>
            <p>上図のようになればOKです。戦闘超過ダメージとMonster Effect Nameに入れてSaveしましょう</p>
            <h4>相手プレイヤーのライフが０以下で勝利</h4>
            <p>Add Monster Effectをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_life_condition.png`}></img></p>
            <p>Monster Effect Valに条件を選択しMonster Conditionの右の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_life_condition2.png`}></img></p>
            <p>ダイアログボックスの上にある部分を上図のように入力します。以下のような意味になります</p>
            <ul>
                <li>①グローバル変数のうち相手ライフが条件の変数になっていることを表します</li>
                <li>②相手ライフが条件式（この場合は０）以下であることを示します </li>
                <li>③条件式を表します</li>
                </ul>
            <p>以上が入力出来たらダイアログボックスの下の確定をクリックし、Monster Effect Nameに相手ライフ０以下と入力してSaveをクリックしてください。</p>
            <p>Add Monster Effectをクリックします</p>
            <p>上図のようにMonster Effect Valに勝利を選択し、Monster Effect Namに勝利を入力し、Saveをクリックしてください。</p>
            <h4>攻撃されるカードのライフを変更</h4>
            <p>Add Monster Effectをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_monster_life.png`}></img></p>
            <p>上図のようにMonster Effect Valにモンスター変数変動を選択しMonster Effectの右の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_monster_life_dialog.png`}></img></p>
            <p>まずは上図のように入力します。以下に意味をしめします</p>
            <ul>
                <li>①カードパラメータ（ライフ）を変更するカードがopponentというカード変数に格納されたカードであることを示します</li>
                <li>②変更するカードパラメータがライフであることを示します</li>
                <li>③カードの変動がいつまで続くかを示します。ダメージがターンエンドまでの場合、(両方ターン開始時)1としてください。（１００００）は半永続的にカードパラメータが変更されるという意味です</li>
                <li>④カードパラメータを変更する値を入力する場所です後述します。</li>
            </ul>
            <p>④をfocusすると青いダイアログボックスが出てくると思いますがここで変動するライフの値を計算します。</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_monster_life_eq_editor.png`}></img></p>
            <p>上図のように入力します。以下のような意味があります</p>
            <ul><li>①計算する主体がtrigger（カード効果の主体、この場合は攻撃するカード）であることを示します</li>
            <li>②計算に使用される値が攻撃力であることを示します</li>
            <li>③　①②を入力し終えたらクリックします。④に計算する値があらわれます </li>
            <li>④計算する値が表示されます。ここではtriggerの攻撃力分の値が計算されることを示します</li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_monster_copy_paste.png`}></img></p>
            <p>青いダイアログボックスの入力を終えたら、上図の①から②にペーストした後、-を前につけてください(ライフは減少するため)。さらに③のようにminusにチェックをつけてください。
            </p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_monster_submit.png`}></img></p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_monster_final.png`}></img></p>
            <p>ダイアログボックスの確定をクリックした後、Monster Effect Nameにopponentのライフtriggerの攻撃力分減少と入力してSaveをクリックしてください。</p>
            <h4>ライフが０の以下のカードを墓地へ送る</h4>
            <p>この戦闘ではライフが０以下になるのはopponentに格納されたカードのみですが、ほかの処理にも使うのでモンスターフィールド上に存在するカードがライフが０以下になった場合に墓地に送られる処理を書きましょう</p>
            <p>Add Monster Effectをクリックします</p>
            <p>Monster Effect Valを移動にしてMonster Effectの右の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_monster_to_grave.png`}></img></p>
            <p>上記のようにダイアログボックスに入力します。以下のような意味です</p>
            <ul><li>①自分モンスターゾーンが対象になることを示します。今回は自分フィールドのクリーチャーがライフが０以下になることはありえませんが、一応いれときます</li>
            <li>②またはを選ぶと次のカードの位置を選べます。かつには市内でください</li>
            <li>③相手クリーチャーが対象となります。</li>
            <li>④⑤ライフが０以下のカードが対象となることを示します</li>
            <li>⑥ライフが０以下のクリーチャーの行先を示します。相手カードのコントロールを得ていた場合を考慮して元々の持ち主の墓地としておきます</li>
            </ul>
            <p>上記が入力出来たら確定をクリックし、Monster Effect Nameにライフ０以下のクリーチャー墓地へと入力してSaveをクリックします
            </p>
            <h3>戦闘過程のラッパーの作成</h3>
            <p>管理画面でMonster Effect Wrapperをクリックして、Add Monster EffectWrapperをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_wrapper.png`}></img></p>
            <p>上図のように、Monster Effectにクリーチャー０以下のクリーチャー墓地へ、Monster Effect Nameに戦闘クリーチャー墓地へ logに(@)は墓地に送られたと入力してSaveします</p>
            <p>入力しおえたら続けて作っていきます。Add Monster Effect Wrapperをクリックしてください</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_wrapper2.png`}></img></p>
            <p>上図のように、Monster Effectにopponentのライフ、triggerの攻撃力分減少、Monster Effect Nextに戦闘クリーチャー墓地へ、Monste Effect Nameに戦闘クリーチャーダメージ、Logに(@)は(%)と入力してください。入力出来たらSaveをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_wrapper3.png`}></img></p>
            <p>続けて、Monster Effectに勝利、Monster Effect Nameに勝利 logに(/1/)の勝利と入力してSaveしてください</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_wrapper4.png`}></img></p>
            <p>次に、ライフが０以下の判定を行う処理のラッパーを作ります。上図のように入力してください。以下に意味を表します</p>
            <ul>
                <li>①相手ライフが０以下かを判定する効果をラップすることを表します</li>
                <li>②相手ライフが０以下の場合に実行される効果を表します。この場合攻撃プレイヤ―が勝利します</li>
                <li>③相手ライフが０以下でない場合に実行される効果を表します。ライフが残っていた場合クリーチャー間のい戦闘に進みます</li>
                <li>④ラッパーの名前です
                </li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_wrapper5.png`}></img></p>
            <p>上図のように入力します。超過ダメージの効果のラッパーです。超過ダメージの次は相手ライフの判定に入るので上記のようになります</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_wrapper7.png`}></img></p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_player_wrapper8.png`}></img></p>
            <p>上図のように入力します。攻撃するカードを選択するラッパーです。戦闘開始の次は超過ダメージの計算になっています。またカードを選択する際に表示する文言をpromptに選択するカードに表示される文言をsentenceに入れます</p>
            <h3>戦闘過程のトリガーの作成</h3>
            <p>管理画面からTriggerを選択し、Add Triggerをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_trigger1png.png`}></img></p>
            <p>上記のように入力します。以下のような意味があります</p>
            <ul>
                <li>①ここは自分にしておいてください</li>
                <li>②攻撃は自分ターンにするものです</li>
                <li>③攻撃はバトルフェイズに行うものです</li>
                <li>④⑤⑥攻撃は何かの効果にチェーンするものではありません</li>
                <li>⑦クリーチャーに攻撃する場合は一番初めに攻撃するクリーチャーを選択します</li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_trigger2.png`}></img></p>
            <p>trigger conditionの右の追加をクリックして上記のように入力していきます</p>
            <ul>
                <li>①クリーチャーどうしの攻撃は相手モンスターゾーンにクリーチャーが存在している必要があります</li>
                <li>②③クリーチャーの数が１以上100以下であることを表します。</li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_trigger3.png`}></img></p>
            <p>trigger monsterの右の追加をクリックして上記のように入力していきます。これは攻撃するクリーチャーが自分モンスターゾーンにいる必要があることを示しています</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_trigger4.png`}></img></p>
            <p>続けて、上図のように入力します。Trigger Nameは単なるラベルですが、Trigger Sentenceは画面上で攻撃を行うクリーチャーを選択するときに表示されます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_trigger5.png`}></img></p>
            <p>スクロールしていき上図のようにOnce Per Turn Monster Groupに２を入力します。なぜgroupかというと、直接攻撃とクリーチャーどうしの攻撃でグループを構成する必要があるからです</p>
            <p>入力が終わったらスクロールしてSaveをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_trigger7.png`}></img></p>
            <p>クリーチャーに攻撃を選択して、下までスクロールしてinsertをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_trigger8.png`}></img></p>
            <p>上記のようにクリーチャーにチェックを入れてsumbitをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_trigger9.png`}></img></p>
            <p>ブラウザでバックして、diagramをクリックしてみます    </p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_battle_trigger10.png`}></img></p>
            <p>上図のようになれば成功です</p>









        </div>
    )
}
