import React from 'react';
import {TriggerTimingNotEffectedElement} from './trigger_timing_not_effected_element';
import {TriggerTimingNotEffectedExample} from './trigger_timing_not_effected_example';

export const TriggerTimingNotEffected =  ()=>{
  return (
  <React.Fragment>
    <TriggerTimingNotEffectedElement />
    <TriggerTimingNotEffectedExample />
  </React.Fragment>
  )
};
