export const ChooseMultipleOpponent = () => {
    return (
        <div className="container">
            <h2>分岐複数相手</h2>
            <p>効果の主体でないプレイヤーに選択を複数しめし、それにより次の効果処理を決めます</p>
            <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/choose_multiple_opponent.png`}></img></p>
            <p>この例ではプレイヤーに対してターンエンドするか(①)、１ドローするか(②)、手札を１枚捨てるか(③)を選ばせ、それにより次のMonsterEffectWrapperが選択されます

            </p>
        </div>
    )
}
