export const MoveToTop =  ()=>{
    return (
        <div className="container">
            <h2>上に重ねる </h2>
           <p>フィールドのカードの上にカードを重ねます</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/move_to_top_example.png`}></img></p>
           この例では自分のデッキ(①)の一番上(②③)をkoka(④)という<a href="/tcgcreatoreternalbetaver2/card_variable#">カード変数</a>にかさねています
           <p><img src={`${process.env.PUBLIC_URL}/move_to_top_card_variable_example.png`}></img></p>
           この例では自分のtrigger(①)という<a href="/tcgcreatoreternalbetaver2/card_variable#">カード変数</a>に自分フィールドのフィールドｘ位置２(②)y座標(①)に重ねています
        </div>
    )
}
