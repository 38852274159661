export const TutorialStartUp =  ()=>{
    return (
        <div className="container">
            <h2>ゲーム開始時の処理の作成</h2>
            ここからはいよいよゲームの処理を作っていきます。まずはゲーム開始時の手札を作ります
            <h3>カード効果種類（ドロー）の作成</h3>
            <p>まずはドローというカード効果種類を作っていきたいと思います。これはまだ必須でありませんが、例えば「相手がドローしたときに手札を捨てる」というような効果をもったカードを作る場合いずれ必要になるのでここで作ってしまいます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_kind_choose.png`}></img></p>
            <p>上図のようにMonster Effect Kindを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_kind_add.png`}></img></p>
            <p>上図のようにAdd Monster Effect Kindを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_kind_input.png`}></img></p>
            <p>上図のようにMonster Effect Nameにドローと入力しSaveします</p>
            <h3>5枚ドローする処理の作成</h3>
            <p>ドローする処理を作ります</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_choose.png`}></img></p>
            <p>上図のようにMonster Effectを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_add.png`}></img></p>
            <p>上図のようにAdd Monster Effectを選択します</p>
            <h4>ドローする処理の作り方</h4>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_move.png`}></img></p>
            <p>ドローという処理はデッキから手札へカードを移動させる処理なので、移動を選びます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_move_choose.png`}></img></p>
            <p>ドロー処理の内容を作成するために図のように追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_move_detail.png`}></img></p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_move_detail2.png`}></img></p>
            <p>今回の処理はデッキから手札へ５枚移動するので、場所を「自分デッキ」モンスターの数をmin,maxともに５枚、移動先を自分手札にします。入力が終わったら確定を押します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_move_detail3.png`}></img></p>
            <p>最後にMonster Effect Name(効果名です。処理には関係ありません)を5枚ドローと入力してSaveをクリックします</p>
            <p>これで自分がドローする処理はおしまいです。次に相手が５枚ドローする処理を作ります</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_add.png`}></img></p>
            <p>再びAdd Monster Effectを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_move.png`}></img></p>
            <p>ドローという処理はデッキから手札へカードを移動させる処理なので、移動を選びます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_move_choose.png`}></img></p>
            <p>ドロー処理の内容を作成するために図のように追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_move_detail4.png`}></img></p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_move_detail5.png`}></img></p>
            <p>今回の処理はデッキから手札へ５枚移動するので、場所を「相手デッキ」モンスターの数をmin,maxともに５枚、移動先を相手手札にします。入力が終わったら確定を押します</p>
            <h4>フェイズ移動の作り方</h4>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_choose.png`}></img></p>
            <p>上図のようにMonster Effectを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_add.png`}></img></p>
            <p>上図のようにAdd Monster Effectを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_phase.png`}></img></p>
            <p>フェイズを変えるので①でフェイズ移動を②で追加をおして、今回はドローフェイズに移動するので③でドローフェイズを終わったら④で確定します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_phase2.png`}></img></p>
            <p>Monster Effect　Nameをドローフェイズ移動にしてSaveをクリックします</p>
            <h3>処理のラッパーの作成</h3>
            <p>ここまででカード効果の内容はできました。次にラッパーを作っていきます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_wrapper_choose.png`}></img></p>
            <p>上図のようにMonster Effect Wrapperを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_wrapper_add.png`}></img></p>
            <p>上図のようにAdd Monster Effect Wrapperをクリックします</p>
            <p>ここからラッパーを作っていきますが最後の効果から作っていくのがコツです。なぜならMonster Effect Nextで次の処理を指定する必要があるからです</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_wrapper_phase.png`}></img></p>
            <p>まずはドローフェイズ移動のラッパーを作ります。この処理はゲーム開始時の処理で最後の処理なのでMonster Effect Nextは空になります</p>
            <p>①でMonster Effectで作ったドローフェイズ移動を選択します。Monster Effect Nameはドローフェイズ移動にしておきます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_wrapper_phase2.png`}></img></p>
            <p>他は何も入力せずにSaveをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_wrapper_move.png`}></img></p>
            <p>次にゲーム開始時に相手がドローする処理のラッパーを作ります。①で相手がドローする処理のラッパーであることを示します。②でこの処理の種類がドロー処理だということを表します。③で次の処理がドローフェイズ移動。④でこのラッパーの名前をつけます。⑤はこの処理が行われた際に表示されるログを示します。(/2/)は相手プレイヤーの名前を表します</p>
            <p>他は何も入力せずにSaveをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_effect_wrapper_move2.png`}></img></p>
            <p>次にゲーム開始時にドローする処理のラッパーを作ります。①でドローする処理のラッパーであることを示します。②でこの処理の種類がドロー処理だということを表します。③で次の処理が相手ドロー処理であることを示します。④でこのラッパーの名前をつけます。⑤はこの処理が行われた際に表示されるログを示します。(/1/)は自分プレイヤーの名前を表します</p>
            <p>他は何も入力せずにSaveをクリックします</p>
            <h3>カード処理を開始する</h3>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_choose.png`}></img></p>
            <p>上図のようにTriggerを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_add.png`}></img></p>
            <p>上図のようにAdd Triggerをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_input.png`}></img></p>
            <p>上図のように入力します。それぞれ以下のような意味が合います</p>
            <ul>
                <li>①は自分を選んでください
                </li>
                <li>②③は変更しないでください
                </li>
                <li>④Turnは自分にしてください。ゲーム開始時にはまだターンが決まっていないように思われるかもしれませんがすでに内部的には決まっています
                </li>
                <li>⑤Phaseはゲーム開始時の特別なphaseであるstart phaseを選択してください</li>
                <li>⑥⑦チェーンは０ちょうどにしてください。これはほかの処理に割り込む可能性がないからです。</li>
                <li>⑧No chainにもチェックを入れてください。これも割り込みをしないことを示します。チェーンを組まない効果にも割り込みしないことを表しています</li>
                <li>⑨Forceにもチェックを入れます。これは強制効果であることを示します。ゲーム開始時に必ず行う処理であることを示しています</li>
                <li>⑩Next Effectには５枚ドローを選択します。</li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_input2.png`}></img></p>
            <p>続けて入力していきます。以下のような意味があります</p>
            <ul>
                <li>①はカード処理の条件です。今回は無条件で行う処理のため空欄にします</li>
                <li>②は処理がなんらかのタイミングでのみ行われることをしまします。今回は特にそういうことはないのでチェックを入れません</li>
                <li>③カード処理の主体となるカードを表します。今回はカードが引き起こす効果ではないので空欄にします</li>
                <li>④処理がカードによって引き起こされるわけでないことを示します。今回の効果はカードが引き起こすわけでないのでチェックを入れます</li>
                <li>⑤処理の名前を入れます。わかりやすい名前を入れましょう</li>
                <li>⑥処理を選択する際に表示される文字列を示します。今回は特に必要ありません</li>
                <li>⑦処理が任意効果の時に効果を使用するかを尋ねる際に使われる文字列です。今回は強制効果なので空欄です</li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_input3.png`}></img></p>
            <p>次は今回入力する項目はありません。説明のみ示します</p>
            <ul>
                <li>①②効果のコストです。今回はコストを入力しないので空です</li>
                <li>③効果の種類です。今回は種類は入れません</li>
                <li>④効果が処理できるタイミングです。今回はまだタイミングを作っていないのと、また、特定のタイミングで処理する効果ではないので空欄です</li>
                </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_input4.png`}></img></p>
            <p>次も今回入力する項目はありません。説明のみ示します</p>
            <ul>
                <li>①効果が即時に使われることを示します。今回は必要ないので空欄です</li>
                <li>②タイミングに入っていないときに効果が処理されることを示します。今回の処理は特定のタイミングの時に行われるものではないので全てチェックを入れます</li>
                <li>③効果が使われたときに表示するログを示します。今回は空欄です</li>
                <li>④割り込みできる効果の主体プレイヤーを示します。今回の効果は割り込みしないので何を選んでもかまいません</li>
                <li>⑤割り込みできる効果の種類を表します。今回は割り込みできないので空欄にします</li>
                <li>⑥効果をコピーされる場合チェックを入れます。今回は空欄です</li>
                <li>⑦効果が同一チェーン上で二回使えるかを示します。今回は空欄です</li>
</ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_input5.png`}></img></p>
            <p>次も今回入力する項目はありません。説明のみ示します</p>
            <ul>
            <li>①効果がカード毎に同一チェーン上で二回使えるかを表します</li>
            <li>②TriggerTimingで定められたカードが存在する場合、同一チェーン上で二回使えるかを示します</li>
            <li>③この効果が割り込み可能かを示します</li>
            <li>④効果の主体のカードが存在しなくても処理を行うかをしめします。今回はカードの効果でないので空欄です</li>
            <li>⑤効果が１ターンに一度かを示します </li>
            <li>⑥効果が1デュエルに１回かを示します </li>
            <li>⑦効果がカード毎に１ターンに一度かを示します</li>
            <li>⑧0以外が指定されるとその数値の効果が１ターンに一度しか使えないことを示します</li>
            <li>⑨0以外が指定されるとその数値の効果がカード毎に１ターンに一度しか使えないことを示します</li>
            <li>⑩NPCとの戦いで使用されることを表します</li>
            <li>⑪NPCとの戦いで人間またはNPCどちらが使用可能かを示します</li>
            <li>⑫NPCが効果を選ぶ際に基準とするカードパラメータを表します</li>
            <li>⑬NPCが効果を選ぶ際に基準とするカードパラメータが大きい順か小さい順かを示します</li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_input6.png`}></img></p>
            <ul>
            <li>①効果がチェーンを組むことなく即座に適用されることを表します</li>
            <li>②効果がチェーンを組むことを表します</li>
            <li>③残りは融合召喚を表します。ここでは説明は省略します</li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_input7.png`}></img></p>
            <p>最後はSaveをクリックします</p>
            <h4>処理の確認</h4>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_choose2.png`}></img></p>
            <p>処理をセーブしたらもう一度編集画面に入ってみましょう</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_input8.png`}></img></p>
            <p>一番下までスクロールしてdiagramをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_trigger_diagram.png`}></img></p>
            <p>上図のようになれば成功です。カードの効果が適切に紐づけられていることがわかります</p>

            </div>

    )
}
