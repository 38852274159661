export const YesOrNo = () => {
    return (
        <div className="container">
            <h2>YesOrNo</h2>
            <p>効果の主体のプレイヤーに同意不同意を表示し、その結果に基づいて次の処理を決めます</p>
            <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/yes_or_no.png`}></img></p>
            <p>この効果を使うとプレイヤーに対して、MonsterEffectWrapperのpromptが表示され、その返答によりMonster Effect WrapperのMonster Effect Next、Monster Effect Next2に進むからが決まります

            </p>
        </div>
    )
}
