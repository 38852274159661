import React from 'react';
import {UserDeckElement} from './user_deck_element';
import {UserDeckExample} from './user_deck_example';

export const UserDeck =  ()=>{
  return (
  <React.Fragment>
    <UserDeckElement />
    <UserDeckExample />
  </React.Fragment>
  )
};
