import React from 'react';
import {MonsterEffectKindElement} from './monster_effect_kind_element';
import {MonsterEffectKindExample} from './monster_effect_kind_example';

export const MonsterEffectKind =  ()=>{
  return (
  <React.Fragment>
    <MonsterEffectKindElement />
    <MonsterEffectKindExample />
  </React.Fragment>
  )
};
