export const TriggerTimingNotEffectedExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            カードが効果を受けなかった場合の効果処理を保存するテーブルです 
            <p>
            <img src={`${process.env.PUBLIC_URL}/trigger_timing_not_effected_example.png`}></img>
            <img src={`${process.env.PUBLIC_URL}/trigger_timing_not_effected_example2.png`}></img>
            </p>
        <p>この例では戦士が効果を受けなかった場合１ドローする処理を示しています</p>
            
        </div>
    )
}
