import React from 'react';
import {UserDeckGroupElement} from './user_deck_group_element';
import {UserDeckGroupExample} from './user_deck_group_example';

export const UserDeckGroup =  ()=>{
  return (
  <React.Fragment>
    <UserDeckGroupElement />
    <UserDeckGroupExample />
  </React.Fragment>
  )
};
