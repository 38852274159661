export const MonsterEffectKindExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
                MonsterEffectKindはカード効果の種類を表します
            <p>
            <img src={`${process.env.PUBLIC_URL}/monster_effect_kind_example.png`}></img>
            <p>この例ではモンスター効果という名のカード効果を作っています</p>
            </p>
            
        </div>
    )
}
