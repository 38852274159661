import ReactDOM from 'react-dom/client';
import React from 'react';
export const BackgroundExamples =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            Backgroundはトップぺージやゲーム中の背景を登録するテーブルです。以下のように使います。
            <p>
            <img src={`${process.env.PUBLIC_URL}/background_example.png`}></img>
            </p>
            この例ではwarrior.pngという画像を背景にしています。注意点としては、画像の名前を半角英数字にするほうがよいということです。日本語や記号を入れるとうまく動かない場合があります。
            また、かならず、画像は/static/tcgcreator/img/background/に保存しましょう
        </div>
    )
}
