export const MoveToTimingVariable = () => {
    return (
        <div className="container">
            <h2>タイミング変数移動</h2>
            <p><a href="/tcreatoreternalbetaver2/card_variable">カード変数</a>をタイミングの<a href="/tcreatoreternalbetaver2/card_variable">カード変数</a>に移動します </p>
            <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/move_to_timing_variable.png`}></img></p>
            <p>この例ではtriggerという<a href="/tcreatoreternalbetaver2/card_variable">カード変数</a>を%attackerという<a href="/tcreatoreternalbetaver2/card_variable">カード変数</a>にコピーしています。triggerは効果処理が終わると消えてしまいますが%attackerはなんらかのタイミングに入っているかぎり保持され続けます

            </p>
        </div>
    )
}
