export const ChangeMonsterVariableRelation = () => {
    return (
        <div className="container">
            <h2>モンスター変数変動リレーション</h2>
           <p>カードに紐づけられたカードのカードパラメータを変更します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/change_monster_variable_relation.png`}></img></p>
           <p>この例ではtriggerという<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>にbattleという名で(②)モンスター効果で紐づかれたカードの攻撃力(③)を次の自分のターンの開始が１回(③、つまり相手ターンエンド時まで)までモンスター効果の効果種別(⑤)で3ダウン(⑥)することを表します</p>
        </div>
    )
}
