import ReactDOM from 'react-dom/client';
import React from 'react';
export const DuelDeckElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Room Number</th><td>行われているデュエルの部屋番号です</td></tr>
                    <tr><th>Mine Or Other</th><td>デッキの持ち主を表しています</td></tr>
                    <tr><th>Deck Id</th><td>行われているデュエルで割り振られているデッキのIDです</td></tr>
                    <tr><th>Deck Name</th><td>デッキの名前です</td></tr>
                    <tr><th>Deck Content</th><td>現在行われているデュエルのデッキの中身です</td></tr>
                </tbody>
            </table>
        </div>
    )
}