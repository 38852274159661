export const Random = () => {
    return (
        <div className="container">
            <h2>ランダム分岐</h2>
            <p>等確率で次の効果が決まります</p>
            <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/random.png`}></img></p>
            <p>この例では３分の１の確率でハンデス(①)、３分の１の確率で１ドロー(②) ３分の１の確率でターンエンド(③)に進みます。④で分岐を追加し、⑤で確定します

            </p>
        </div>
    )
}
