export const DownloadElement =  ()=>{
    return (
        <div className="container">
            <h2>ダウンロードしたファイルの中身</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>data.csv</th><td>チュートリアルで使うカードデータがsjisで入っています   </td></tr>
                    <tr><th>data.csv.utf8</th><td>チュートリアルで使うカードデータがutf8で入っています   </td></tr>
                    <tr><th>dump.bat</th><td>現在使用中のゲームデータのダンプが取れます</td></tr>
                    <tr><th>finished.sql</th><td>チュートリアルが終わった状態のデータです。import.shで使います</td></tr>
                    <tr><th>import.bat</th><td>ダンプデータをインポートできます</td></tr>
                    <tr><th>install.bat</th><td>初めてtcgcreatoreternalbetaを使うときに必要な設定をします</td></tr>
                    <tr><th>manage.py</th><td>djangoというtcgcreatoreternalbetaで使うウェブアプリケーションのフレームワークのファイルです</td></tr>
                    <tr><th>mysite</th><td>djangoというtcgcreatoreternalbetaで使うウェブアプリケーションの設定のフォルダです</td></tr>
                    <tr><th>path_add.ps1</th><td>初期設定に必要なファイルです</td></tr>

                    <tr><th>start.bat</th><td>tcgcreatoreternalbetaを起動します。起動後ブラウザでhttp://localhost:8000/admin/にアクセスすると設定画面が開きます</td></tr>
                    <tr><th>superuser.bat</th><td>スーパーユーザーを追加します</td></tr>
                    <tr><th>tcgcreator</th><td>tcgcreatoreternalbetaの本体でが入っているフォルダです</td></tr>
                    <tr><th>tcgcreator_initial.sql</th><td>tcgcreatoreternalbetaの初期データです。</td></tr>
                    <tr><th>templates</th><td>tcgcreatoreternalbetaのテンプレートデータが入っています</td></tr>
                    <tr><th>ver</th><td>tcgcreatoreternalbetaのバージョンです</td></tr>
                    <tr><th>mariadb-11.0.2-winx64.msi</th><td>tcgcreatoreternalbetaで使うデータベースです。</td></tr>
                    <tr><th>python-3.11.4-amd64.exe</th><td>tcgcreatoreternalbetaを動かすpythonという言語です。</td></tr>
                </tbody>
            </table>
        </div>
    )
}
