export const PhaseCondition =  ()=>{
    return (
        <div className="container">
            <h2>フェイズ及びタイミング条件</h2>
           <p>現在のフェイズ及びタイミングで真偽を決めます。真の場合はMonsterEffectWrapperのMonsterEffectNext偽の場合はMonsterEffectNext2に進みます </p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/phase_condition_input.png`}></img></p>
           <p>Monster Effectの右の追加をクリックします。上手の例ではドローフェイズ(①) 及び、攻撃戦時タイミング(②)に入っている場合に真であるということを表します
           </p>
           <p><img src={`${process.env.PUBLIC_URL}/phase_condition.png`}></img></p>
           <p>Monster Effectに図のように書かれれば成功です。
           </p>
        </div>
    )
}
