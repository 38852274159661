import ReactDOM from 'react-dom/client';
import React from 'react';
export const ConfigElements =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Cancel Name</th><td>キャンセルをする際の文言です。通常変えることはないと思います。</td></tr>
                    <tr><th>Sort</th><td>フィールドを出した順にソートするかを示します</td></tr>
                    <tr><th>Auto Max</th><td>自動選択の際の文言です。通常変えることはないです</td></tr>
                    <tr><th>Surrender Name</th><td>降参する際の文言です。通常変えることはないです</td></tr>
                    <tr><th>Turn Name</th><td>画面右のターンの文言です。通常変えることはないです</td></tr>
                    <tr><th>Time Name</th><td>残り時間の文言です。通常変えることはないです</td></tr>
                    <tr><th>Reload Name</th><td>更新する際の文言です。通常変えることはないです</td></tr>
                    <tr><th>Tomessage Name</th><td>対戦者動詞でチャットするときの文言です。通常変えることはないです</td></tr>
                    <tr><th>Log Name</th><td>デュエルの進行を確認する文言です。通常変えることはないです</td></tr>
                    <tr><th>Game Name</th><td>作成するゲームの名前です。自分の作りたいゲームの名前を入れてください</td></tr>
                    <tr><th>Hide Name</th><td>裏向きのカードをする際に使用する文言です。通常変える必要はないです</td></tr>
                    <tr><th>Limit Time</th><td>一回のプレイにかけられる時間です。テスト中は大きい値、デプロイ時は180ほどにしておくことがおすすめです</td></tr>
                    <tr><th>Room Time</th><td>一回のプレイにかけられる時間です。テスト中は大きい値、デプロイ時は180ほどにしておくことがおすすめです</td></tr>
                    <tr><th>Time Win</th><td>時間切れで表示する言葉です。</td></tr>
                    <tr><th>Beep</th><td>現在は使われておりません。</td></tr>
                    <tr><th>Deck Side</th><td>右サイドバーにデッキ状況を表示するか決めます。基本的にチェックしておいてください</td></tr>
                    <tr><th>Common Name</th><td>現在は使われておりません。</td></tr>
                    <tr><th>Common Name</th><td>現在は使われておりません。</td></tr>
                    <tr><th>Gray Out</th><td>グレーアウトする変数です。基本的にshowにしておいてください。</td></tr>
                    <tr><th>Default Sort</th><td>デッキ構築画面でのデフォルトのソートです</td></tr>
                    <tr><th>Field Free</th><td>チェックを入れるとフィールドの横幅の制限がなくなります（内部的には２０になっています)</td></tr>
                    <tr><th>Initial Turn Log</th><td>チェックを入れるとデバッグ用のログが作られます。デプロイではチェックを外してください。</td></tr>
                    <tr><th>Initial Turn Start Log</th><td>上とまぎわらしいですが、チェックを入れると１ターン目に先行のプレイヤーにログが出ます。基本的にチェックでいいと思いますが、自分でログを出す場合や先行が変化する場合はチェックを外してください</td></tr>
                    <tr><th>Detail Log</th><td>チェックを入れるとデバッグ用のログが詳細ログが作られます。非常に巨大なファイルになるので必要な場合のみチェックしてください</td></tr>
                    <tr><th>Cheat</th><td>チェックを入れるとデバッグ用のチートができます。</td></tr>
                    <tr><th>Choose Multiple Cancel</th><td>多対多を選ぶ際にキャンセルするときのボタンの文言です</td></tr>
                    <tr><th>Message Position</th><td>現在は使われておりません</td></tr>
                    <tr><th>Templates</th><td>並列２を選んでください。</td></tr>
                    <tr><th>Explain Height</th><td>現在は使われておりません</td></tr>
                    <tr><th>Chain String</th><td>チェーンいくつかを修飾する値です</td></tr>
                    <tr><th>Mine Color</th><td>自分フィールドの色を決めます</td></tr>
                    <tr><th>Other Color</th><td>相手フィールドの色を決めます</td></tr>
                    <tr><th>Background image</th><td>現在は使われておりません</td></tr>
                    <tr><th>Font color</th><td>デュエル中のフォントカラーを決定します</td></tr>
                    <tr><th>Link color</th><td>aタグ（リンク）のフォントカラーを決定します</td></tr>
                    <tr><th>Phase color</th><td>現在フェイズのフォントカラーを決定します</td></tr>
                    <tr><th>Log Background Color</th><td>ログの背景色を決定します</td></tr>
                    <tr><th>Turn Font Size</th><td>現在ターンを表す文字の大きさを決定します</td></tr>
                    <tr><th>Life Font Size</th><td>現在は使われておりません</td></tr>
                    <tr><th>Time Font Size</th><td>現在は使われておりません</td></tr>
                    <tr><th>Deck Font Size</th><td>現在は使われておりません</td></tr>
                    <tr><th>Phase Font Size</th><td>現在は使われておりません</td></tr>
                    <tr><th>Hand Name Show Flag</th><td>チェックを入れると手札の名前が表示されます</td></tr>
                    <tr><th>Ask Audio</th><td>入力を求められる時に出す音を決めます(static/tcgcreator/audio)に入れてください</td></tr>
                    <tr><th>Win Audio</th><td>勝った時に出す音を決めます(static/tcgcreator/audio)に入れてください</td></tr>
                    <tr><th>Lose Audio</th><td>負けた時に出す音を決めます(static/tcgcreator/audio)に入れてください</td></tr>
                    <tr><th>Chat Audio</th><td>相手がチャットをした際に出す音を決めます(static/tcgcreator/audio)に入れてください</td></tr>
                    <tr><th>Audio Volume</th><td>音の大きさを決めます</td></tr>
                    <tr><th>Card Width</th><td>ゲーム画面でのカードの幅を決めます</td></tr>
                    <tr><th>Card Height</th><td>ゲーム画面でのカードの長さを決めます</td></tr>
                    <tr><th>Card Href</th><td>カードをクリックした際に説明画面を出すかを決めます    </td></tr>
                    <tr><th>Show Wait Chain</th><td>カードを割り込みするタイミングを表示するかを決めます</td></tr>
                    <tr><th>Up Reverse</th><td>カードを重ねたときに下のカードを表示するかを決めます。チェックを入れないのを推奨します</td></tr>
                    <tr><th>From Left</th><td>チェックを入れるとカードを選ぶときに左から選択との表示が出ます。</td></tr>
                    <tr><th>Add Variable Show</th><td>カードのパラメータが変動しているときに表示するパラメータを選択します(パラメータ名をアンダーバー（＿）で区切ってください</td></tr>
                    <tr><th>Show Message</th><td>画面中央にログを出すか決めます</td></tr>
                    <tr><th>Ai</th><td>チェックした場合NPCとの対戦が有効化されます</td></tr>
                    <tr><th>Return Url</th><td>デュエル後戻るをクリックした際に帰るURLを指定します</td></tr>
                    <tr><th>Shori</th><td>チェーンを逆順処理にするかを決めます。逆方向を推奨します</td></tr>
                    <tr><th>Order</th><td>チェーンの組み方の選び方を決定します</td></tr>
                    <tr><th>Show Img</th><td>画像使用を選んでください。</td></tr>
                </tbody>
            </table>
        </div>
    )
}