export const CopyMonsterSpecify = () => {
    return (
        <div className="container">
            <h2>モンスターコピーモンスター指定</h2>
           <p>カードの効果をコピーするカードを指定してコピーします</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/copy_monster_specify.png`}></img></p>
           <p>この例ではtrigger(①)という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に戦士(①) のカード効果カード名(③)種族（⑤）攻撃力(⑥)をどちらかのターン(③)の開始一回目(④)（つまり自分ターンエンドまで）コピーします

           </p>
        </div>
    )
}
