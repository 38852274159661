import ReactDOM from 'react-dom/client';
import React from 'react';
export const DefaultDeckChoiceElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Default Deck</th><td>ゲストがログインした時に構築済みデッキのデフォルトのものを決めます</td></tr>
                </tbody>
            </table>
        </div>
    )
}