import ReactDOM from 'react-dom/client';
import React from 'react';
export const FieldElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>No Clear</th><td>基本的にチェックしないでください。</td></tr>
                    <tr><th>Box</th><td>フィールドにカードがないときに幅と高さをつけるかを表します</td></tr>
                    <tr><th>Color</th><td>フィールドを表示する際のカラーを表します</td></tr>
                    <tr><th>Kind</th><td>フィールドの種類を表します</td></tr>
                    <tr><th>Sentence</th><td>フィールドのに表示される文字列を表します</td></tr>
                    <tr><th>Mine Or Other</th><td>フィールドの持ち主を表します</td></tr>
                </tbody>
            </table>
        </div>
    )
}