export const TriggerTimingElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Trigger</th><td>カードが移動した場合に呼び出される効果を表します</td></tr>
                    <tr><th>Win Or Lose</th><td>カードが移動したとき勝つか負けるかを示します</td></tr>
                    <tr><th>Kinds</th><td>カードが移動した場合のカード効果の種類を表します</td></tr>
                    <tr><th>From Place Kind</th><td>カードの移動元を表します</td></tr>
                    <tr><th>From Deck</th><td>カードの移動元のデッキを表します</td></tr>
                    <tr><th>From Grave</th><td>カードの移動元の墓地を表します</td></tr>
                    <tr><th>From Hand</th><td>カードの移動元の手札を表します</td></tr>
                    <tr><th>From Field</th><td>カードの移動元のフィールドを表します</td></tr>
                    <tr><th>From Mine Or Other</th><td>カードの移動元がどちらのプレイヤーに属するかを表します</td></tr>
                    <tr><th>Exclude From Place Kind</th><td>カードの移動元の除外を表します</td></tr>
                    <tr><th>Exclude From Deck</th><td>カードの移動元の除外のデッキを表します</td></tr>
                    <tr><th>Exclude From Grave</th><td>カードの移動元の除外の墓地を表します</td></tr>
                    <tr><th>Exclude From Hand</th><td>カードの移動元の除外の手札を表します</td></tr>
                    <tr><th>Exclude From Field</th><td>カードの移動元の除外のフィールドを表します</td></tr>
                    <tr><th>Exclude From Mine Or Other</th><td>カードの除外の移動元がどちらのプレイヤーに属するかを表します</td></tr>
                    <tr><th>To Place Kind</th><td>カードの移動先を表します</td></tr>
                    <tr><th>To Deck</th><td>カードの移動先のデッキを表します</td></tr>
                    <tr><th>To Grave</th><td>カードの移動先の墓地を表します</td></tr>
                    <tr><th>To Hand</th><td>カードの移動先の手札を表します</td></tr>
                    <tr><th>To Field</th><td>カードの移動先のフィールドを表します</td></tr>
                    <tr><th>To Mine Or Other</th><td>カードの移動先がどちらのプレイヤーに属するかを表します</td></tr>
                    <tr><th>Exclude From Place Kind</th><td>カードの移動先の除外を表します</td></tr>
                    <tr><th>Exclude From Deck</th><td>カードの移動先の除外のデッキを表します</td></tr>
                    <tr><th>Exclude From Grave</th><td>カードの移動先の除外の墓地を表します</td></tr>
                    <tr><th>Exclude From Hand</th><td>カードの移動先の除外の手札を表します</td></tr>
                    <tr><th>Exclude From Field</th><td>カードの移動先の除外のフィールドを表します</td></tr>
                    <tr><th>Exclude From Mine Or Other</th><td>カードの除外の移動先がどちらのプレイヤーに属するかを表します</td></tr>
                    <tr><th>Monster</th><td>移動したカードを表します</td></tr>
                    <tr><th>Monster Specify Flag</th><td>移動したカードを特定するかを表します</td></tr>
                    <tr><th>Monster Relate</th><td>移動したカードと紐づいたカードを表します</td></tr>
                    <tr><th>Monster Relate Specify Flag</th><td>移動したカードと紐づいたカードを特定するかを表します</td></tr>
                    <tr><th>Monster Relate Except</th><td>移動したカードと紐づいたカードの例外を表します</td></tr>
                    <tr><th>Monster Relate Except Specify</th><td>移動したカードと紐づいたカードの例外があるかを表します</td></tr>
                    <tr><th>Monster Exist</th><td>カードが移動した際に、存在するカードを表します</td></tr>
                    <tr><th>Monster Exist Except Specify Flag</th><td>存在するカードを特定するかを表します</td></tr>
                    <tr><th>Exist Place Kind</th><td>存在するカードの場所を表します</td></tr>
                    <tr><th>Exist Deck</th><td>存在するカードがデッキの場合デッキの種類を表します</td></tr>
                    <tr><th>Exist Grave</th><td>存在するカードが墓地の場合墓地の種類を表します</td></tr>
                    <tr><th>Exist Hand</th><td>存在するカードが手札の場合手札の種類を表します</td></tr>
                    <tr><th>Exist Field</th><td>存在するカードがフィールドの場合のフィールドの種類を表しますカードの移動先のフィールドを表します</td></tr>
                    <tr><th>Exist Kinds</th><td></td></tr>
                    <tr><th>Exist Mine Or Other</th><td>存在するカードがどちらに属するかを示します</td></tr>
                    <tr><th>Who</th><td>効果の主体のプレイヤーを表します</td></tr>
                    <tr><th>Chain User</th><td>紐づいけを受けたカードのプレイヤーを表します</td></tr>
                    <tr><th>Cost Or Effect</th><td>紐づけがコストか効果かを示します</td></tr>
                    <tr><th>Trigger Timing Name</th><td>この効果の名前です</td></tr>
                    <tr><th>Relation</th><td>移動していたカードが紐づいていたかを表します</td></tr>
                    <tr><th>Relation2</th><td>無視してください</td></tr>
                    <tr><th>Relation Kind</th><td>紐づけの種類です</td></tr>
                    <tr><th>Relation Name</th><td>紐づけの名前です</td></tr>
                    <tr><th>Relation To</th><td>紐づけの向いている方向です</td></tr>
                    <tr><th>Org Flag</th><td>無視してください</td></tr>
                    <tr><th>Which Monster Effect</th><td>発動する効果の主体を示します</td></tr>
                    <tr><th>Once Per Turn Relate</th><td>発動する効果が紐づけられていた場合1ターンに一度かを示します</td></tr>
                    <tr><th>Once Per Turn Exist</th><td>発動する効果が存在するカードを指定する場合1ターンに一度かを示します</td></tr>
                    <tr><th>Enemy</th><td>NPC対戦時に効果が有効かを示します</td></tr>
                    <tr><th>Enemy Own</th><td>NPC対戦時に効果がプレイヤーまたはNPCが発動できるかを示します</td></tr>
                    <tr><th>Chain</th><td>発動するときの効果チェーン数を表します    </td></tr>
                    <tr><th>Chain Kind</th><td>発動するときの効果チェーン数が以上か、以下かそれともそのチェーンかを表します</td></tr>
                    


                    
                </tbody>
            </table>
        </div>
    )
}