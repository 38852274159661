import ReactDOM from 'react-dom/client';
import React from 'react';
export const FusionExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
                Fusionはカードを融合するときに使われるテーブルです
            <p>
            <img src={`${process.env.PUBLIC_URL}/fusion_example.png`}></img>
            <p>この例では戦士というカードの融合に必要なカードを作っています</p>
            </p>
            
        </div>
    )
}
