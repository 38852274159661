export const CopyMonster = () => {
    return (
        <div className="container">
            <h2>モンスターコピー</h2>
           <p>カードの効果を得ます</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/copy_monster.png`}></img></p>
           <p>この例ではtrigger(①)という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>にkoka（②)という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>で表されたカードのカード効果(③)カード名(④)種族（⑧）攻撃力(⑨)をどちらかのターン(⑥)の開始一回目(⑨)（つまりターンエンドまで）コピーします

           </p>
        </div>
    )
}
