export const AccValFlush = () => {
    return (
        <div className="container">
            <h2>変数変動flush</h2>
           <p>変数が変動する際、accumlate_flagでためていた場合一斉に変動します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/acc_val_flush.png`}></img></p>
           <p>変数の変動をいっぺんに行います</p>
        </div>
    )
}
