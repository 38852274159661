export const Shuffle = () => {
    return (
        <div className="container">
            <h2>シャッフル</h2>
            <p>カードをシャッフルします</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/shuffle.png`}></img></p>
           <p>シャッフルしたい場所を選びます(①)。この例では自分デッキがシャッフル対象です
           </p>
        </div>
    )
}
