export const Win = () => {
    return (
        <div className="container">
            <h2>勝利</h2>
            <p>効果処理のユーザーが勝利します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/win.png`}></img></p>
        </div>
    )
}
