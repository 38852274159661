import React from 'react';
import {MonsterEffectElement} from './monster_effect_element';
import {MonsterEffectExample} from './monster_effect_example';

export const MonsterEffect =  ()=>{
  return (
  <React.Fragment>
    <MonsterEffectElement />
    <MonsterEffectExample />
  </React.Fragment>
  )
};
