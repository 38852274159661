export const Launch =  ()=>{
    return (
        <div className="container">
            <h3>tcgcreator_eternal_betaの起動</h3>
            <p><img src={`${process.env.PUBLIC_URL}/start_bat.png`}></img></p>
            <p>上図のようにstart.batをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/chrome.png`}></img></p>
            <p>上記のようにgoogle chromeを起動して、アドレス欄に{'{'}127.0.0.1:8000/admin/{'}'}と入力します</p>
            <p><img src={`${process.env.PUBLIC_URL}/django.png`}></img></p>
            <p>上記のようになればインストールは完了です</p>

        </div>
    )
}