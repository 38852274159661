import React from 'react';

export const EquationEditor =  ()=>{
  return (
    <div className="container">
        <h1>数式エディタ</h1>
        <p>ここでは数式エディタの説明を行います
        数式エディタはカードの枚数の満たすべき条件などの数式を書くことができます
        </p>
        <img src={`${process.env.PUBLIC_URL}/equation_editor.png`}></img>
        <div className="list-group list-group-numbered">
            <a href="/tcgcreatoreternalbetaver2/equation_global_variable_detail" className="list-group-item">グローバル変数の値の指定の仕方</a>
            <a href="/tcgcreatoreternalbetaver2/equation_place_detail" className="list-group-item">カードを場所を指定して、変数の値を決定します</a>
            <a href="/tcgcreatoreternalbetaver2/equation_variable_detail" className="list-group-item">カード変数を指定して、変数の値を決定します</a>
            </div>
            <hr/>
        <div className="list-group list-group-numbered">
            <a href="/tcgcreatoreternalbetaver2/equation_global_variable" className="list-group-item">グローバル変数の値をしめします。セレクトボックスから選んで変数追加で追加します</a>
            <hr ></hr>
            <a href="/tcgcreatoreternalbetaver2/equation_place" className="list-group-item">変数の参照するカードの場所を指定します</a>
            <a href="/tcgcreatoreternalbetaver2/equation_flag" className="list-group-item">カードのflag条件を指定します</a>
            <a href="/tcgcreatoreternalbetaver2/equation_card_effect" className="list-group-item">カードの効果有効条件を指定します、基本的に非推奨です</a>
            <a href="/tcgcreatoreternalbetaver2/equation_card_variable" className="list-group-item">カウントするカードパラメータ―を指定します。指定しない場合、指定されたカードの総数になります</a>
            <a href="/tcgcreatoreternalbetaver2/equation_card_function" className="list-group-item">カウントするカードパラメータ―の関数を指定します。SUMを選ぶと指定された―カードパラメータの合計、MAXを選ぶと指定されたカードパラメータの最大値、MINを選ぶと指定されたカードパラメータの最小値をとります</a>
            <a href="/tcgcreatoreternalbetaver2/equation_card_variable_condition" className="list-group-item">カードの満たすべきカードパラメータの条件を指定します</a>
            <a href="/tcgcreatoreternalbetaver2/equation_card_kind" className="list-group-item">カードを特定します（非推奨です）</a>
            <hr ></hr>
            <a href="/tcgcreatoreternalbetaver2/equation_variable" className="list-group-item">参照するカード変数を指定します</a>
            <a href="/tcgcreatoreternalbetaver2/equation_card_variable2" className="list-group-item">カウントするカードパラメータ―を指定します。指定しない場合、指定されたカードの総数になります</a>
            <a href="/tcgcreatoreternalbetaver2/equation_flag2" className="list-group-item">参照するカードのflagを指定します</a>
            <a href="/tcgcreatoreternalbetaver2/equation_relation" className="list-group-item">参照するカードに紐づいたカードの紐づけの名前を指定します<span className="alert alert-primary">これを指定するとカードは参照されたものでなく紐づけされたものを取ることを気を付けてください</span></a>
            <a href="/tcgcreatoreternalbetaver2/equation_relation_kind" className="list-group-item">参照するカードに紐づいたカードの紐づけの種類を指定します<span className="alert alert-primary">これを指定するとカードは参照されたものでなく紐づけされたものを取ることを気を付けてください</span></a>
            <a href="/tcgcreatoreternalbetaver2/equation_relation_to" className="list-group-item">参照するカードに紐づいたカードの紐づけを受けているのかを指定します<span className="alert alert-primary">これを指定するとカードは参照されたものでなく紐づけされたものを取ることを気を付けてください</span></a>
            <a href="/tcgcreatoreternalbetaver2/equation_card_kind2" className="list-group-item">参照するカードを特定します（非推奨です）</a>
            <a href="/tcgcreatoreternalbetaver2/equation_relation_card" className="list-group-item">紐づけする参照するカードを特定します（非推奨です）</a>
            <a href="/tcgcreatoreternalbetaver2/equation_under" className="list-group-item">参照するカードの下のカードであることを特定します（非推奨です）</a>
            <a href="/tcgcreatoreternalbetaver2/equation_card_effect2" className="list-group-item">カードの効果有効条件を指定します、基本的に非推奨です</a>
      </div>
        <h1>数式の構成</h1>
        <p>数式は数式エディタで作った値、普通の数字、演算子を記入し、$で区切ります。また、数式は逆ボーランド方を採用しています。逆ボーランド方は演算対象をまず入力し、後に演算子を適用する方法です。例えば5足す３は 5$3$+ となります。演算子は以下があります</p>
        <ul className="list-group list-group-numbered">
          <li className="list-group-item">+ 普通の足し算です。例えばtrigger(効果の主体)の攻撃力と防御力を加算する場合 {'{'}trigger:攻撃力::::::0:::0{'}'}${'{'}trigger:防御力::::::0:::0{'}'}$+ と入力します</li>
          <li className="list-group-item">- 普通の引き算です。例えば相手ライフから自分ライフを引くのは ^ライフ:2^$^ライフ:1^$- と入力します</li>
          <li className="list-group-item">* 普通のかけ算ですです。例えば攻撃力の３倍は {'{'}trigger:攻撃力::::::0:::0{'}'}$3$* と入力します</li>
          <li className="list-group-item">/ 普通の割り算ですです。例えば攻撃力の２分の１は {'{'}trigger:攻撃力::::::0:::0{'}'}$2$* と入力します</li>
          <li className="list-group-item">MAX 二つの値のうちの大きい方を表します。例えば攻撃力と防御力の大きいほうは {'{'}trigger:攻撃力::::::0:::0{'}'}${'{'}trigger:防御力::::::0:::0{'}'}$MAX と入力します</li>
          <li className="list-group-item">MIN 二つの値のうちの小さい方を表します。例えば攻撃力と防御力の小さいほうは {'{'}trigger:攻撃力::::::0:::0{'}'}${'{'}trigger:防御力::::::0:::0{'}'}$MIN と入力します</li>
          </ul>
    </div>
  )
};
