export const ChangeGlobalVariable =  ()=>{
    return (
        <div className="container">
            <h2>変数変動</h2>
           <p>グローバル変数を変更します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/change_global_variable_example.png`}></img></p>
           この例では自分のライフ(①)を１０００(②)にしています(③)
        </div>
    )
}
