export const TurnEnd = () => {
    return (
        <div className="container">
            <h2>ターンエンド</h2>
            <p>ターンを終了します   </p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/turn_end.png`}></img></p>
           <p>ターンを終了します。monster effectには何も書かないでください

           </p>
        </div>
    )
}
