import ReactDOM from 'react-dom/client';
import React from 'react';
export const EndChainExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            End Chain Effectは効果処理後に実行される処理の起点となるするテーブルです
            <p>
            <img src={`${process.env.PUBLIC_URL}/end_chain_effect_example.png`}></img>
            </p>
            この例では効果処理後にドローします。次の処理として手札を一枚墓地にすてる処理が入っています
        </div>
    )
}
