import ReactDOM from 'react-dom/client';
import React from 'react';
export const PhaseElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Priority</th><td>フェイズが画面に表示される場合の順番をあらわします（大きいほうが上）</td></tr>
                    <tr><th>Phase Name</th><td>フェイズの名前です</td></tr>
                    <tr><th>Show</th><td>フェイズを画面に表示するかを示します</td></tr>
                    <tr><th>Phase Whether Show</th><td>フェイズの割り込みを可能にするかを画面に表示するかを表します</td></tr>
                    <tr><th>Pri</th><td>優先権放棄を表示するかを表します</td></tr>
                </tbody>
            </table>
        </div>
    )
}