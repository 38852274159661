import ReactDOM from 'react-dom/client';
import React from 'react';
export const EternalEffectElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Effect EffectVal</th><td>永続効果の種類です。以下があります。</td></tr>
                    <tr><td>「無効化効果を受けない」</td><td>効果を無効化したり特定の効果を受けないようにします。Eternal Effect Val2で細かい事項を決定します</td></tr>
                    <tr><td>「数値変動」</td><td>効果で特定のパラメータが変動します</td></tr>
                    <tr><td>「選択できない」</td><td>効果で選択できなくなります</td></tr>
                    <tr><td>「発動できない」</td><td>効果が発動できなくなります</td></tr>
                    <tr><td>「行先変更」</td><td>効果で移動する場合の行先を変更します</td></tr>
                    <tr><td>「逆転」</td><td>特定のパラメーターを交換します</td></tr>
                    <tr><td>「逆転元々」</td><td>特定のパラメーターの元々の値を交換します</td></tr>
                    <tr><td>「条件無視」</td><td>効果を発動する際に一部の条件を無視します</td></tr>
                    <tr><td>「代わりに受ける」</td><td>効果でライフ等のグローバルパラメータが変動する場合代わりにカードのパラメータが変動します</td></tr>
                    <tr><td>「名前変更」</td><td>カード名を変更します</td></tr>
                    <tr><td>「名前追加」</td><td>カード名を追加します</td></tr>
                    <tr><th>Effect EffectVal2</th><td>無効化効果を受けない効果をさらに細かく分類します</td></tr>
                    <tr><td>「永続無効化」</td><td>永続効果を無効化します</td></tr>
                    <tr><td>「発動効果無効化」</td><td>発動した効果を無効化します</td></tr>
                    <tr><td>「無効化」</td><td>すべての効果を無効化します</td></tr>
                    <tr><td>「効果を受けない」</td><td>効果をうけなくなります</td></tr>
                    <tr><td>「無効化　発動できない」</td><td>効果を無効にしさらに発動できなくなります</td></tr>
                    <tr><th>Priority</th><td>永続効果の優先度です。競合する効果がある場合優先度の高いものが先に処理されます。</td></tr>
                    <tr><th>Turn</th><td>永続効果が自分ターンに有効か、相手ターンに有効か、それとも両方で有効かを決めます。</td></tr>
                    <tr><th>Chain</th><td>永続効果が効果処理の何番に必要かを決めます。</td></tr>
                    <tr><th>Chain Kind</th><td>永続効果が効果処理のChainで与えられたもの以上か、それとも以下かを決めます。</td></tr>
                    <tr><th>Phase</th><td>永続効果が効果処理がどのフェイズで有効かを決めます選択しなかった場合全てのフェイズで有効となります。</td></tr>
                    <tr><th>Mine Or Other</th><td>永続効果がどのプレイヤーのものかを決めます</td></tr>
                    <tr><th>Mine Or Other Effect</th><td>効果が効果処理中のどのプレイヤーによって作られたものに対して有効かを判断します</td></tr>
                    <tr><th>Etenral Effect Condition</th><td>永続効果が有効になる条件を表しますjsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Etenral Monster</th><td>永続効果の主体となるカードの条件を表します。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Etenral Global Variable</th><td>永続効果の条件となるglobal variableをしてします。以下が書式となります(global_variableのid_1自分、２、相手、３、共通)</td></tr>
                    <tr><th>Etenral Global Variable Val</th><td>永続効果の条件となるglobal variableの値です</td></tr>
                    <tr><th>Etenral Variable</th><td>永続効果の条件となるカードの変数です</td></tr>
                    <tr><th>Etenral Variable Val</th><td>永続効果の条件となるカードの変数の値です</td></tr>
                    <tr><th>Etenral Variable Equation</th><td>永続効果の条件となるカードの変数の値のとる式です</td></tr>
                    <tr><th>Etenral Name</th><td>永続効果の名前です</td></tr>
                    <tr><th>Etenral Kind</th><td>永続効果の効果種類です</td></tr>
                    <tr><th>Invalid Etenral Kind</th><td>永続効果の効果を受ける効果の種類です</td></tr>
                    <tr><th>Invalid Monster</th><td>永続効果の効果を受ける効果のカードの情報です。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Timing</th><td>永続効果が有効なタイミングです</td></tr>
                    <tr><th>None Timing</th><td>永続効果がタイミングの中に入っていない場合有効を表します</td></tr>
                    <tr><th>Timing2</th><td>永続効果が有効なタイミングです</td></tr>
                    <tr><th>None Timing2</th><td>永続効果がタイミングの中に入っていない場合有効を表します</td></tr>
                    <tr><th>Timing3</th><td>永続効果が有効なタイミングです</td></tr>
                    <tr><th>None Timing3</th><td>永続効果がタイミングの中に入っていない場合有効を表します</td></tr>
                    <tr><th>None Monster</th><td>永続効果が特定のカードに紐づけられていないことを表します</td></tr>
                    <tr><th>Invalid None Monster</th><td>永続効果が特定のカードに対しての効果ではないことを表します</td></tr>
                    <tr><th>Persist</th><td>無視してください</td></tr>
                    <tr><th>Relation</th><td>永続効果がカードに紐づけられたものに対してのものかを表します</td></tr>
                    <tr><th>Relation Kind</th><td>永続効果がカードに紐づけられている場合その紐づけの種類を表します</td></tr>
                    <tr><th>Relation To</th><td>永続効果の紐づけが受けているか及ぼしているかを判断します</td></tr>
                    <tr><th>Tmp Val</th><td>無視してください</td></tr>
                    <tr><th>Val Name</th><td>永続効果が数値変動の場合変動する変数を示します</td></tr>
                    <tr><th>Value</th><td>永続効果が数値変動の場合変動する数値を示します</td></tr>
                    <tr><th>Cost Or Effect</th><td>永続効果がコストに及ぼすものか効果に及ぼすものかを決めます</td></tr>
                    <tr><th>Ignore</th><td>永続効果で条件無視を選んだ場合無視する条件を選択します</td></tr>
                    <tr><th>Ignore Phase</th><td>条件で無視するフェイズを選択します</td></tr>
                    <tr><th>Ignore Effect Kind</th><td>無視してください</td></tr>
                    <tr><th>Ignore Timing</th><td>条件で無視するタイミングを選択します</td></tr>
                    <tr><th>Ignore None Timing</th><td>条件でタイミングに入っていない場合無視するかを決めます</td></tr>
                    <tr><th>Ignore Variable</th><td>条件でカードのパラメータを無視するものを決めます</td></tr>







                     

                </tbody>
            </table>
        </div>
    )
}
