import React from 'react';
import {GlobalVariableElement} from './global_variable_element';
import {GlobalVariableExample} from './global_variable_example';

export const GlobalVariable =  ()=>{
  return (
  <React.Fragment>
    <GlobalVariableElement />
    <GlobalVariableExample />
  </React.Fragment>
  )
};
