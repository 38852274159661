import ReactDOM from 'react-dom/client';
import React from 'react';
export const PacElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Pac Next</th><td>このPacでひとまとめにされるPac Wrapperの一番はじめの効果を示します</td></tr>
                    <tr><th>Pac Name</th><td>このひとまとめにした効果処理の名前です</td></tr>
                </tbody>
            </table>
        </div>
    )
}