export const UserDeckChoiceElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>User</th><td>デッキの所有者のユーザーです</td></tr>
                    <tr><th>User Deck</th><td>ユーザーがデフォルトで選んでいるデッキ群です</td></tr>
                </tbody>
            </table>
        </div>
    )
}