import ReactDOM from 'react-dom/client';
import React from 'react';
import {NavHome} from './navhome'
import {NavTable} from './navtable'
import {NavDocument} from './navdocument'
import {NavTutorial} from './navtutorial'
import {NavInstall} from './navinstall'
import {NavGame} from './navgame'
import {NavChannel} from './navchannel'
export const NavMenu =  ()=>{
    return (
        <div className="collapse navbar-collapse" id="navbar-content">
            <ul className="navbar-nav me-auto">
                <NavHome></NavHome>
                <NavInstall></NavInstall>
                <NavTable></NavTable>
                <NavDocument></NavDocument>
                <NavTutorial></NavTutorial>
                <NavChannel></NavChannel>
                <NavGame></NavGame>
            </ul>
        </div>
    )
}