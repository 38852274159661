import ReactDOM from 'react-dom/client';
import React from 'react';
export const DefaultDeckGroupElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Deck Name</th><td>グループ化されたデッキ群（例デッキ、サイドデッキ）の名前を表します</td></tr>
                    <tr><th>Default deck id</th><td>デッキのIDです基本的に変更することはないでしょう</td></tr>
                </tbody>
            </table>
        </div>
    )
}