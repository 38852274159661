import ReactDOM from 'react-dom/client';
import React from 'react';
export const CostExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            Costはコストの処理を書くためのテーブルです   
            <p>
            <img src={`${process.env.PUBLIC_URL}/cost_example.png`}></img>
            </p>
            <p>コストで１ドローする処理を書いています。Cost部分についてはjsonというもので書かれていて人間が書くのは難しいので右の追加から<a href="/tcgcreatoreternalbetaver2/dialog_box">ダイアログボックス</a>を表示して入力します</p>
            
        </div>
    )
}
