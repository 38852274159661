import ReactDOM from 'react-dom/client';
import React from 'react';
export const EnemyDeckChoiceExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
              EnemyDeckChoiceはNPCが使うデフォルトのデッキです
            <p>
            <img src={`${process.env.PUBLIC_URL}/enemy_deck_choice_example.png`}></img>
            </p>
            <p>enemy deck groupに名前はないのですが、数字で判断することになります</p>
            
        </div>
    )
}
