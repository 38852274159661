import React from 'react';
import {UnderDirectionElement} from './under_direction_element';
import {UnderDirectionExample} from './under_direction_example';

export const UnderDirection =  ()=>{
  return (
  <React.Fragment>
    <UnderDirectionElement />
    <UnderDirectionExample />
  </React.Fragment>
  )
};
