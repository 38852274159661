export const Move =  ()=>{
    return (
        <div className="container">
            <h2>移動</h2>
           <p>カードを移動します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/move_place_example.png`}></img></p>
           <p>上の例では自分のデッキ(①)の上から(②)からカードを１枚(③)手札に加える処理を表しています。詳しくは<a href="/tcgcreatoreternalbetaver2/dialogbox">dialogbox</a>を参照してください</p>
           <p><img src={`${process.env.PUBLIC_URL}/move_card_variable_example.png`}></img></p>
           <p>上の例ではtrigger(①)という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納されたカードが自分フィールド(②)に移動する処理を表しています</p>
        </div>
    )
}
