export const FusionUniqueEffect =  ()=>{
    return (
        <div className="container">
            <h2>融合固有効果    </h2>
           <p>融合召喚時に固有効果を使います</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/fusion_unique_effect.png`}></img></p>
           <p><img src={`${process.env.PUBLIC_URL}/fusion_unique_effect_example.png`}></img></p>
            MonsterEffectValに融合固有効果を選択すると次の効果として上図にあるようにFusionのUnique Effectが選ばれます
           <p></p>
        </div>
    )
}
