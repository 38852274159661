export const ChangeMonsterRelationAllClear = () => {
    return (
        <div className="container">
            <h2>モンスターリレーション全部クリア</h2>
           <p>カードの紐づけを指定する場所から全て取り消します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/change_monster_relation_all_clear.png`}></img></p>
           <p>この例では自分フィールドにbattleという名でモンスター効果でリレーションを受けるの紐づけを外しています</p>
        </div>
    )
}
