export const ChangeMonsterRelationClear = () => {
    return (
        <div className="container">
            <h2>モンスターリレーションクリア</h2>
           <p>カードの紐づけを取り消します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/change_monster_relation_clear.png`}></img></p>
           <p>この例ではtrigger(①) という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>にbattle(②)という名でモンスター効果で(③)リレーションを受ける(④)の紐づけを取っています</p>
        </div>
    )
}
