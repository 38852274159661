export const CardNumElement =  ()=>{
    return (
        <div className="container">
            カードの枚数を表します。条件の時は条件にあったカードがmin以上max以下のときに真になります。選択の場合はmin以上max以下を選択します
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_card_num.png`}></img></p>
            カードのパラメータの合計で判断することも可能です。下記例では攻撃力の合計が１以上、自分ライフ未満（<a href="/tcgcreatoreternalbetaver2/equation_editor">数式エディタはこちら</a>)を表します
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_card_num2.png`}></img></p>

        </div>
    )
}
