export const Each = () => {
    return (
        <div className="container">
            <h2>Each</h2>
            <p><a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に入っているカードを一つずつ取り出しそれぞれに処理を施します  </p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/each.png`}></img></p>
           <p>この例ではsummoned(①)に入っているカードを一つずつsummon(②)に受け渡し、処理を行います。その際ループが１０回（③）をこすと強制的に処理が終了になります。また一回のループの毎にtmp(④)という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>がリセットされます
           </p>
        </div>
    )
}
