import React from 'react';
import {TimingElement} from './timing_element';
import {TimingExample} from './timing_example';

export const Timing =  ()=>{
  return (
  <React.Fragment>
    <TimingElement />
    <TimingExample />
  </React.Fragment>
  )
};
