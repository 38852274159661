import ReactDOM from 'react-dom/client';
import React from 'react';
export const CostWrapperExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            Cost Wrapperはコストの実行をラップするテーブルです
            <p>
            <img src={`${process.env.PUBLIC_URL}/cost_wrapper_example.png`}></img>
            </p>
            この例ではコストとして１ドローします。その際ログにドローしたプレイヤーが１枚ドローした旨が伝えられます。また、次の処理としてデッキがなくなった場合の処理につながっています
        </div>
    )
}
