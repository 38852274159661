import ReactDOM from 'react-dom/client';
import React from 'react';
export const GlobalVariableExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
                Global Variableはライフなどのゲームで使う変数で表します
            <p>
            <img src={`${process.env.PUBLIC_URL}/global_variable_example.png`}></img>
            <p>この例ではライフを初期値８０００で作っています</p>
            </p>
            
        </div>
    )
}
