export const ChainVariableOpponent = () => {
    return (
        <div className="container">
            <h2>チェーン変数設定相手</h2>
            <p>効果処理中のみに有効なglobal変数を相手が設定します</p>
            <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/chain_variable.png`}></img></p>
            <p>この例ではopponent_variable(①)という変数名で最小値が0(②)最大値が50(③)になるようにチェーン変数を相手ユーザーからの入力で設定します

            </p>
        </div>
    )
}
