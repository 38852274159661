import ReactDOM from 'react-dom/client';
import React from 'react';
export const DefaultDeckGroupExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
              DefaultDeckGroupはいくつかの構築済みデッキをまとめたものです
            <p>
            <img src={`${process.env.PUBLIC_URL}/default_deck_group_example.png`}></img>
            </p>
            <p>デッキという名のデッキ群です。基本的にこの画面で編集することはありません。</p>
            
        </div>
    )
}
