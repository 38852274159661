export const TriggerTimingChangeValExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            グローバル変数が変動した時の効果処理を保存するテーブルです 
            <p>
            <img src={`${process.env.PUBLIC_URL}/trigger_timing_change_val_example.png`}></img>
            </p>
        <p>この例では戦士が１以上ダメージを与えた時１ドローする処理を作っています </p>
            
        </div>
    )
}
