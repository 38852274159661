import ReactDOM from 'react-dom/client';
import React from 'react';

export const NavHome =  ()=>{
    return (
        <li className="nav-item ">
            <a className="nav-link" href="/tcgcreatoreternalbetaver2/index" >
                ホーム
            </a>
        </li>
    )
}
