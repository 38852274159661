import React from 'react';
import {UserPointElement} from './user_point_element';
import {UserPointExample} from './user_point_example';

export const UserPoint =  ()=>{
  return (
  <React.Fragment>
    <UserPointElement />
    <UserPointExample />
  </React.Fragment>
  )
};
