export const EquationEditorPlace =  ()=>{
    return (
    <div className="container">
        <p>ここでは数式エディタの場所によるカードの指定の仕方を示します</p>
        <img src={`${process.env.PUBLIC_URL}/equation_editor_place.png`}></img>
        <p>まず、 <a href="/tcgcreatoreternalbetaver2/equation_place" >①のように</a>カードの存在する場所を指定します</p>
        <p>次に<a href="/tcgcreatoreternalbetaver2/equation_flag" >②のように</a>①で指定した場所のうちフラグが立っているものを指定します（任意ですフラグを指定しないことも可能です)</p>
        <p><a href="/tcgcreatoreternalbetaver2/equation_card_effect" >③</a>で有効なカード効果を指定できますが不推奨です</p>
        <p><a href="/tcgcreatoreternalbetaver2/equation_card_x_y" >④</a>でフィールドの場合x座標とy座標を指定することが可能です</p>
        <p><a href="/tcgcreatoreternalbetaver2/equation_card_variable" >⑤</a>で計算するカードパラメータを指定します。図では攻撃力を指定しています</p>
        <p><a href="/tcgcreatoreternalbetaver2/equation_card_function" >⑥</a>でカードパラメータの計算方法を指定します。SUMは合計、MAXは最大値MINは最小値です</p>
        <p><a href="/tcgcreatoreternalbetaver2/equation_card_variable_condition" >⑦</a>でカードパラメータの満たす条件をしていします。この例ではレベルが4以上８以下を指定しています</p>
        <p><a href="/tcgcreatoreternalbetaver2/equation_card_kind" >⑧</a>でカードを直接指定できます。非推奨です</p>
        <hr></hr>
        <p>以上で場所指定を押すと「|1:1:攻撃力:1:1:SUM:レベル@4@&rt;=@and^レベル@8@&lt;=@or::::::::0|」と下のテキストボックスに入るはずです</p>
        <p>この例では自分フィールド場のフラグが１、モンスター効果が有効で、レベルが４以上、８以下、のカードの攻撃力の合計を表します</p>
    </div>

    )
}
