import React from 'react';
import './index.css';
import {DefaultDeckExample} from './default_deck_example';

export const DefaultDeck =  ()=>{
  return (
  <React.Fragment>
    <DefaultDeckExample />
  </React.Fragment>
  )
};
