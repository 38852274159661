export const DeckExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
             Deckはデッキの情報を表すテーブルです  
            <p>
            <img src={`${process.env.PUBLIC_URL}/deck_example.png`}></img>
            </p>
            <p>この例では両方のプレイヤーがそれぞれのデッキ内容はプレイヤーには公開されない、表示順序の優先度は１００、デッキ内で効果が発動したり、永続効果があることがありえるということになります</p>
            
        </div>
    )
}
