export const PacExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            Pacは効果処理をひとまとめにしたものテーブルです  
            <p>
            <img src={`${process.env.PUBLIC_URL}/pac_example.png`}></img>
            </p>
        <p>この例では1ドローする処理をひとまとめにしています </p>
            
        </div>
    )
}
