export const UnderDirectionExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            カードが重ねられた場合、上のカードが移動したときに重ねられたカードがどこへ行くかを示します 
            <p>
            <img src={`${process.env.PUBLIC_URL}/under_direction_example.png`}></img>
            </p>
        <p>この例ではカードが墓地に行った際、それの下のカードも墓地に行くことを示しています</p>
            
        </div>
    )
}
