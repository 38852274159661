export const GraveElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Mine Or Other</th><td>墓地がプレイヤーごとのものか共通のものかを表します。特になしがプレイ毎、共通が墓地が共有されることを表します  </td></tr>
                    <tr><th>Show</th><td>墓地がプレイヤーに対して表示されるかを表します</td></tr>
                    <tr><th>Grave Name</th><td>墓地の名前です</td></tr>
                    <tr><th>Priority</th><td>デュエル画面で表示される順番を決まます（大きい値のものほど上に表示されます)</td></tr>
                    <tr><th>Invoke</th><td>墓地内でカードが効果を発動する場合があるかをしめします</td></tr>
                    <tr><th>Eternal</th><td>墓地内で永続効果が発動するかを表します</td></tr>
                </tbody>
            </table>
        </div>
    )
}