import ReactDOM from 'react-dom/client';
import React from 'react';
export const PacWrapperElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Monster Effect Next</th><td>次の効果です。（Pacの中のMonster Effect Wrapperの次の効果がない場合このMonster Effect Wrapperが選ばれます)</td></tr>
                    <tr><th>Monster Effect Next2</th><td>次の効果です。(Pacの中のMonster Effect WrapperのMonster Effect Next2がない場合このMonster Effect Wrapperが選ばれます</td></tr>
                    <tr><th>Pac Next</th><td>次のまとめられた効果です。Monster Effect Nextが選ばれていない場合こちらに移動します</td></tr>
                    <tr><th>Pac Next2</th><td>次のまとめられた効果です。条件等で条件を満たしておらずかつMonster Effect Next2が空の場合こちらが処理されます</td></tr>
                    <tr><th>Monster Effect Kind</th><td>指定された場合Pacないの効果の効果は全てのこの効果として上書きされます</td></tr>
                    <tr><th>Monster Effect Kind Rel</th><td>指定された場合Pacないの効果の紐づけ効果は全てのこの効果として上書きされます</td></tr>
                    <tr><th>Pac</th><td>このPac WrapperでラップされるPac Monster Effect(いくつかのMonster Effect Wrapperをひとまとめにしたもの)をしていします</td></tr>
                    <tr><th>Pac Name</th><td>このラッパーの名前です。記号は名前に使わないでください</td></tr>
                    <tr><th>Log</th><td>この効果が実行された場合に残されるLogです。<a href="/tcgcreatoreternalbetaver2/explian_log">こちらで詳しく解説します</a></td></tr>
                </tbody>
            </table>
        </div>
    )
}
