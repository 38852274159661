export const ChangeTiming = () => {
    return (
        <div className="container">
            <h2>タイミング移行</h2>
            <p>タイミングを移行します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/change_timing.png`}></img></p>
           <p>①でしめしているように攻撃戦時のタイミングに移行しています。②ではtimingを２で移行しています（timingは１～３あり３から大きいほうから順に処理されます)

           </p>
        </div>
    )
}
