export const ChangeAppointment = () => {
    return (
        <div className="container">
            <h2>優先権移行</h2>
            <p>優先権を移行します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/change_appointment.png`}></img></p>
           <p>優先権を移行します

           </p>
        </div>
    )
}
