import React from 'react';
import {CostElement} from './cost_element';
import {CostExample} from './cost_example';

export const Cost =  ()=>{
  return (
  <React.Fragment>
    <CostElement />
    <CostExample />
  </React.Fragment>
  )
};
