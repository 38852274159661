export const GlobalVariableElement =  ()=>{
    return (
        <div className="container">
           グローバル変数条件はグローバル変数が取る値についての条件を表します。
           下記の例ではライフが3000以上であることを表しています。<br/>  
           <p>
           <img src={`${process.env.PUBLIC_URL}/dialogbox_global_variable1.png`}></img>
           </p>
            また追加を押すことで複数の条件をあつかうことができます。
            下記の例ではライフが自分フィールドのカードの数*1000以下(<a href="/tcgcreatoreternalbetaver2/equation_editor">数式エディタについてはこちら</a>)、でターンカウントが1以上（初めのターン出ない)
            ことを表しています。
           <p>
           <img src={`${process.env.PUBLIC_URL}/dialogbox_global_variable2.png`}></img>
           </p>
        </div>
    )
}
