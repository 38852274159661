import React from 'react';
import {TriggerTimingChangeValElement} from './trigger_timing_change_val_element';
import {TriggerTimingChangeValExample} from './trigger_timing_change_val_example';

export const TriggerTimingChangeVal =  ()=>{
  return (
  <React.Fragment>
    <TriggerTimingChangeValElement />
    <TriggerTimingChangeValExample />
  </React.Fragment>
  )
};
