export const ChangeMonsterRelationChange = () => {
    return (
        <div className="container">
            <h2>モンスターリレーション付け替え</h2>
           <p>カードの紐づけを別のカードに紐づけかえます</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/change_monster_relation_change.png`}></img></p>
           <p>この例ではtrigger(①) という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>にbattleという名で(②)koka(③)という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>にモンスター効果で(④)リレーションを受ける(⑤)ように紐づけています。元々battle(②）という名で紐づけられていたカードの紐づけは外れます</p>
        </div>
    )
}
