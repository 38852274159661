export const TutorialEndPhase =  ()=>{
    return (
        <div className="container">
            <h2>エンドフェイズの処理を作る</h2>
            エンドフェイズは特に何もしません。相手ターンのドローフェイズに移動するだけです
            <h3>ターンエンドの作成</h3>
            <p>管理画面からMonster effectを選択し、Add Monster Effectをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_end_phase.png`}></img></p>
            <p>上図のようにMonster　Effect Valにターンエンドを選択し、Monster Effect Nameにターンエンドを入力してSaveします</p>
            <h3>エンドフェイズのラッパーの作成</h3>
            <p>管理画面からMonster effect Wrapperを選択し、Add Monster Effect Wrapperをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_end_phase_draw.png`}></img></p>
            <p>上図のようにMonster　Effectにドローフェイズ移動　Monster Effect Nameにエンドフェイズ時ドローフェイズに移動を入力してSaveします</p>
            <p>再び、Add Monster Effect Wrapperをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_end_phase_turn_end.png`}></img></p>
            <p>上図のようにMonster Effectにターンエンド　Monster Effect Nextにエンドフェイズ時ドローフェイズ移行、Monster Effect Nameにエンドフェイズ時ターンエンド、Logに(/2/)のターンと入力してSaveします
            </p>
            <h3>エンドフェイズのトリガーの作成</h3>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_end_phase_trigger_choose.png`}></img></p>
            <p>管理画面からTriggerを選択し上図のようにドローフェイズ移動を選択します</p>
            <p>スクロールしてSave as New をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_end_phase_trigger.png`}></img></p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_end_phase_trigger2.png`}></img></p>
            <p>続けて上図のようにphaseをエンドフェイズ、Next Effectをエンドフェイズ時ターンエンド Trigger Nameをエンドフェイズ処理にしてSaveします</p>
            </div>
    )
}
