export const VariableElement =  ()=>{
    return (
        <div className="container">
            <p>フラグ立て、変数変動ではモンスター変数変動や移動でカードにフラグを立てたり、カードパラメータを変更したりすることができます</p>
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_variable.png`}></img></p>
            <p>フラグは指定増やす減らす立てる消すがありますが、指定でカードにフラグを立てるといいと思います。その他のオプションはビット演算の知識がひつようになるので非推奨です</p>
            <p>変数変更名は変更するカードパラメータの名前を表します。Monster Variableに登録されたものであればその変数が変動します。上記例では攻撃力を変更しています。登録されていないものはカスタムモンスター変数条件で使われます</p>
            <p>寿命はカードパラメータの変動がいつまで続くかを表します。上の例では両方のターン開始時に変動が終わることをしめします。永続的に変える場合は10000等大きな数字を指定してください</p>
            <p>種別はカードパラメータの変動の効果種別を表します。非推奨です</p>
            <p>値では具体的に変動する値を示します。元々の値も選べますが、現時点では値を選んでください。加えるを選ぶとカードパラメータを増減させます、にするを選ぶとカードパラメータを指定した値にします。その右の値が変動させる値です</p>
        </div>
    )
}
