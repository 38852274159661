import ReactDOM from 'react-dom/client';
import React from 'react';
export const DeckElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Mine Or Other</th><td>デッキがプレイヤーごとのものか共通のものかを表します。特になしがプレイ毎、共通がデッキが共有されることを表します  </td></tr>
                    <tr><th>Min Deck Size</th><td>デッキ枚数の下限です</td></tr>
                    <tr><th>Max Deck Size</th><td>デッキ枚数の上限です</td></tr>
                    <tr><th>Deck Name</th><td>デッキの名前です</td></tr>
                    <tr><th>Show</th><td>デッキがプレイヤーに対して表示されるかを表します</td></tr>
                    <tr><th>Makedeckshow</th><td>デッキ構築画面で表示されるかを気めます</td></tr>
                    <tr><th>Priority</th><td>デュエル画面で表示される順番を決まます（大きい値のものほど上に表示されます)</td></tr>
                    <tr><th>Invoke</th><td>デッキ内でカードが効果を発動する場合があるかをしめします</td></tr>
                    <tr><th>Eternal</th><td>デッキ内で永続効果が発動するかを表します</td></tr>
                </tbody>
            </table>
        </div>
    )
}