import React from 'react';
import './index.css';
import {EnemyDeckGroupElement} from './enemy_deck_group_element';
import {EnemyDeckGroupExample} from './enemy_deck_group_example';

export const EnemyDeckGroup =  ()=>{
  return (
  <React.Fragment>
    <EnemyDeckGroupElement />
    <EnemyDeckGroupExample />
  </React.Fragment>
  )
};
