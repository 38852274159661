export const Sound = () => {
    return (
        <div className="container">
            <h2>効果音鳴らす</h2>
            <p>効果音を鳴らします</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/sound.png`}></img></p>
           <p>monster effectに書いた効果音を鳴らします。効果音はstatic/tcgcreator/musicに格納してください。効果音は一度だけ流れます</p>
        </div>
    )
}
