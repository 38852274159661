export const Choose = () => {
    return (
        <div className="container">
            <h2>選択自分</h2>
           <h3>使い方</h3>
            <p>カードを選んで<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納します</p>
           <p><img src={`${process.env.PUBLIC_URL}/choose.png`}></img></p>
           <p>この効果では<a href="/tcgcreatoreternalbetaver2/dialogbox">ダイアログボックス</a>を使って入力していきます。自分フィールド(①)から攻撃力が100以上(②)power_counterが(③)以上、1枚以上2枚以下(④)をkoka(⑤)という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納します</p>
        </div>
    )
}
