export const ChangeGlobalVariableShow = () => {
    return (
        <div className="container">
            <h2>変数表示変更</h2>
           <p>グローバル変数を画面に表示するかを変更します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/change_global_variable_show.png`}></img></p>
           <p>この例では自分のライフ(①)を非表示にする処理を書いています。dialogboxの増やす(②)が非表示減らすが表示です</p>
        </div>
    )
}
