export const DropDownEquationEditor =  ()=>{
    return (
                <li className="dropdown">
            <a href="/tcgcreatoreternalbetaver2/equation_editor" className="dropdown-item">数式エディタ</a>
            <ul className="dropdown-submenu dropdown-menu">
            <li><a href="/tcgcreatoreternalbetaver2/equation_global_variable_detail" className="dropdown-item">グローバル変数の値の指定の仕方</a></li>
            <li><a href="/tcgcreatoreternalbetaver2/equation_place_detail" className="dropdown-item">カードを場所を指定して、変数の値を決定</a></li>
            <li><a href="/tcgcreatoreternalbetaver2/equation_variable_detail" className="dropdown-item">カード変数を指定して、変数の値を決定</a></li>
                <li><hr className="dropdown-divider"></hr></li>
                <li><a href="/tcgcreatoreternalbetaver2/equation_global_variable" className="dropdown-item">グローバル変数</a></li>
                <li><hr className="dropdown-divider"></hr></li>
            <li> <a href="/tcgcreatoreternalbetaver2/equation_place" className="dropdown-item">カードの場所</a></li>
            <li> <a href="/tcgcreatoreternalbetaver2/equation_flag" className="dropdown-item">flag条件</a></li>
            <li> <a href="/tcgcreatoreternalbetaver2/equation_card_effect" className="dropdown-item">カードの効果有効条件</a></li>
            <li> <a href="/tcgcreatoreternalbetaver2/equation_card_variable" className="dropdown-item">カウントするカードパラメータ―</a></li>
            <li> <a href="/tcgcreatoreternalbetaver2/equation_card_function" className="dropdown-item">カウントするカードパラメータ―の関数</a></li>
            <li> <a href="/tcgcreatoreternalbetaver2/equation_card_variable_condition" className="dropdown-item">カードの満たすべきカードパラメータの条件を指定します</a></li>
            <li> <a href="/tcgcreatoreternalbetaver2/equation_card_kind" className="dropdown-item">カードを特定します</a></li>
                <li><hr className="dropdown-divider"></hr></li>
            <li><a href="/tcgcreatoreternalbetaver2/equation_variable" className="dropdown-item">参照するカード変数</a></li>
            <li><a href="/tcgcreatoreternalbetaver2/equation_card_variable2" className="dropdown-item">カウントするカードパラメータ―</a></li>
            <li><a href="/tcgcreatoreternalbetaver2/equation_flag2" className="dropdown-item">参照するカードのflag</a></li>
            <li><a href="/tcgcreatoreternalbetaver2/equation_relation" className="dropdown-item">参照するカードに紐づいたカードの紐づけの名前</a></li>
            <li><a href="/tcgcreatoreternalbetaver2/equation_relation_kind" className="dropdown-item">参照するカードに紐づいたカードの紐づけの種類</a></li>
            <li><a href="/tcgcreatoreternalbetaver2/equation_relation_to" className="dropdown-item">参照するカードに紐づいたカードの紐づけを受けているのか</a></li>
            <li><a href="/tcgcreatoreternalbetaver2/equation_card_kind2" className="dropdown-item">参照するカードを特定</a></li>
            <li><a href="/tcgcreatoreternalbetaver2/equation_relation_card" className="dropdown-item">紐づけする参照するカードを特定</a></li>
            <li><a href="/tcgcreatoreternalbetaver2/equation_under" className="dropdown-item">参照するカードの下のカード</a></li>
            <li><a href="/tcgcreatoreternalbetaver2/equation_card_effect2" className="dropdown-item">カードの効果有効条件</a></li>
            </ul>
            </li>

    )
}
