import React from 'react';
import {UserDeckChoiceElement} from './user_deck_choice_element';
import {UserDeckChoiceExample} from './user_deck_choice_example';

export const UserDeckChoice =  ()=>{
  return (
  <React.Fragment>
    <UserDeckChoiceElement />
    <UserDeckChoiceExample />
  </React.Fragment>
  )
};
