export const UserDeckElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>User</th><td>デッキの所有者のユーザーです</td></tr>
                    <tr><th>Deck Type</th><td>デッキの種類です</td></tr>
                    <tr><th>Deck Group</th><td>デッキがどのデッキ群に属しているかを表します</td></tr>
                    <tr><th>Deck</th><td>デッキの中身です</td></tr>
                </tbody>
            </table>
        </div>
    )
}