export const MonsterVariableElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Monster Variable Name</th><td>カードの変数の名前を表しています</td></tr>
                    <tr><th>Monster Variable Label</th><td>カード変数の名前と同じにしてください</td></tr>
                    <tr><th>Monster Variable Kind Id</th><td>カードの変数の種類を表しています</td></tr>
                    <tr><th>Monster Variable minus</th><td>カードの変数がマイナスの値を取りうるかを表します</td></tr>
                    <tr><th>Monster Variable Show</th><td>デッキ構築でカードの変数が表示されるかを示します</td></tr>
                    <tr><th>Monster Variable Show2</th><td>画像モードでないときに表示される変数です。現在は画像モードしかサポートしていないため無視してください</td></tr>
                    <tr><th>Monster Variable Battle</th><td>無視してください</td></tr>
                    <tr><th>Priority</th><td>大きなものから表示されます</td></tr>
                    <tr><th>Default Value</th><td>新しく変数を作った場合の規定値です</td></tr>
                </tbody>
            </table>
        </div>
    )
}