import React from 'react';
import {VirtualVariableElement} from './virtual_variable_element';
import {VirtualVariableExample} from './virtual_variable_example';

export const VirtualVariable =  ()=>{
  return (
  <React.Fragment>
    <VirtualVariableElement />
    <VirtualVariableExample />
  </React.Fragment>
  )
};
