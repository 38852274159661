export const CopyEffectCost = () => {
    return (
        <div className="container">
            <h2>効果コピーコスト</h2>
           <h3>使い方</h3>
            <p>効果をコピーします（Costで指定しMonsterEffectはそれで選択された効果が使用されます） </p>
           <p><img src={`${process.env.PUBLIC_URL}/copy_effect_cost.png`}></img></p>
           <p>この例ではkoka(①)という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納されたカードのコストも含めてコピー(②)して、モンスター効果を(③)を強制的に(④)コピーします。copy2(⑤)にチェックを入れているとこの時triggerのcopy2にチェックを入れたものがコピーされます。</p>
        </div>
    )
}
