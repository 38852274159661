import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Header} from './header';
import {Footer} from './footer';
import {EndChainElement} from './end_chain_element';
import {EndChainExample} from './end_chain_example';
import reportWebVitals from './reportWebVitals';

export const EndChainEffect =  ()=>{
  return (
  <React.Fragment>
    <EndChainElement />
    <EndChainExample />
  </React.Fragment>
  )
};
