import React from 'react';
import {PacCostElement} from './pac_cost_element';
import {PacCostExample} from './pac_cost_example';

export const PacCost =  ()=>{
  return (
  <React.Fragment>
    <PacCostElement />
    <PacCostExample />
  </React.Fragment>
  )
};
