import ReactDOM from 'react-dom/client';
import React from 'react';
import {NavBrand} from './navbrand';
import {NavMenu} from './navmenu';
import {NavButton} from './navbutton';
export const Navbar =  ()=>{
    return (
        <nav className="navbar navbar-dark bg-dark ">
            <div className="container-fluid">
                <NavBrand></NavBrand>
                <NavButton></NavButton>
                <NavMenu></NavMenu>

            </div>
        </nav>

    )
}
