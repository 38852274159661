import ReactDOM from 'react-dom/client';
import React from 'react';
export const EnemyDeckGroupExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
              EnemyDeckGroupはいくつかのNPC用デッキをまとめたものです
            <p>
            <img src={`${process.env.PUBLIC_URL}/enemy_deck_group_example.png`}></img>
            </p>
            <p>「Aiデッキ」という名のデッキ群です。基本的にこの画面で編集することはありません。</p>
            
        </div>
    )
}
