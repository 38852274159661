export const EquationEditorGlobalVariable =  ()=>{
    return (
    <div className="container">
        <p>ここでは数式エディタのグローバル変数の指定の仕方を示します</p> 
        <img src={`${process.env.PUBLIC_URL}/equation_editor_global_variable_detail.png`}></img>
        <p>図のようにセレクトボックスで指定するグローバル変数を指定して変数追加を押します</p>
        <img src={`${process.env.PUBLIC_URL}/equation_editor_global_variable_detail2.png`}></img>
        <hr></hr>
        <p>すると「^ライフ:1^」のように使いたい変数が下のテキストボックスに現れます。今回の場合は自分のライフの値を表します</p>
    </div>

    )
}
