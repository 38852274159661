import React from 'react';
import {TriggerElement} from './trigger_element';
import {TriggerExample} from './trigger_example';

export const Trigger =  ()=>{
  return (
  <React.Fragment>
    <TriggerElement />
    <TriggerExample />
  </React.Fragment>
  )
};
