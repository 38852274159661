import ReactDOM from 'react-dom/client';
import React from 'react';
export const TriggerTimingNotEffectedElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Trigger</th><td>カードが効果を受けなかった場合に呼び出される効果を表します</td></tr>
                    <tr><th>Kinds</th><td>この効果のタイミングが有効なカード効果の種類を表します</td></tr>
                    <tr><th>Monster</th><td>効果を受けなかったカードを表します</td></tr>
                    <tr><th>Monster Specify Flag</th><td>効果を受けなかったカードを特定するかを表します</td></tr>
                    <tr><th>Monster Relate</th><td>効果を受けなかったと紐づいたカードを表します</td></tr>
                    <tr><th>Monster Relate Specify Flag</th><td>効果を受けなかったカードと紐づいたカードを特定するかを表します</td></tr>
                    <tr><th>Who</th><td>効果の主体のプレイヤーを表します</td></tr>
                    <tr><th>Chain User</th><td>効果を受けなかったプレイヤーを表します</td></tr>
                    <tr><th>Cost Or Effect</th><td>効果を受けなかったのいがコストか効果かを示します</td></tr>
                    <tr><th>Trigger Timing Name</th><td>この効果の名前です</td></tr>
                    <tr><th>Change Val</th><td>無視してください</td></tr>
                    <tr><th>Change Val Operator</th><td>無視してください</td></tr>
                    <tr><th>Org</th><td>チェックしておいてください</td></tr>
                    <tr><th>Relation</th><td>効果を受けなかったカードに紐づいたカードの効果かを示します</td></tr>
                    <tr><th>Relation Kind</th><td>紐づけの種類です</td></tr>
                    <tr><th>Relation Name</th><td>紐づけの名前です</td></tr>
                    <tr><th>Relation To</th><td>紐づけの向いている方向です</td></tr>
                    <tr><th>Relation2</th><td>無視してください。</td></tr>
                    <tr><th>Relation Kind2</th><td>無視してください。</td></tr>
                    <tr><th>Relation Name2</th><td>無視してください。</td></tr>
                    <tr><th>Relation To2</th><td>無視してください</td></tr>
                    <tr><th>Org Flag</th><td>無視してください</td></tr>
                    <tr><th>Monster Exist</th><td>カードのパラメーターが変更された際にカードが存在する条件を示します</td></tr>
                    <tr><th>Monster Exist Specify Flag</th><td>カードのパラメーターが変更された際にカードが存在する条件を適用するかをしめします</td></tr>
                    <tr><th>Exist Place Kind</th><td>カードが存在する場所を示します</td></tr>
                    <tr><th>Exist Deck</th><td>Exist Place Kindがデッキの時に効果が有効なデッキを示します</td></tr>
                    <tr><th>Exist Grave</th><td>Exist Place Kindが墓地の時に効果が有効な墓地を示します</td></tr>
                    <tr><th>Exist Hand</th><td>Exist Place Kindが手札の時に効果が有効な手札を示します</td></tr>
                    <tr><th>Exist Field</th><td>Exist Place Kindがフィールドの時に効果が有効なフィールドの種類を示します</td></tr>
                    <tr><th>Exist Kind</th><td>存在するカードの効果の種類を示します</td></tr>
                    <tr><th>Exist Mine Or Other</th><td>存在するカードの所有者を示します</td></tr>
                    <tr><th>Which Monster Effect</th><td>発動する効果の主体を示します</td></tr>
                    <tr><th>Variable Name</th><td>無視してください</td></tr>
                    <tr><th>Once Per Turn Relate</th><td>発動する効果が紐づけられていた場合1ターンに一度かを示します</td></tr>
                    <tr><th>Once Per Turn Exist</th><td>発動する効果が存在するカードを指定する場合1ターンに一度かを示します</td></tr>
                    <tr><th>Enemy</th><td>NPC対戦時に効果が有効かを示します</td></tr>
                    <tr><th>Enemy Own</th><td>NPC対戦時に効果がプレイヤーまたはNPCが発動できるかを示します</td></tr>
                    <tr><th>Chain</th><td>発動するときの効果チェーン数を表します    </td></tr>
                    <tr><th>Chain Kind</th><td>発動するときの効果チェーン数が以上か、以下かそれともそのチェーンかを表します</td></tr>
                    


                    
                </tbody>
            </table>
        </div>
    )
}