import React from 'react';
import './index.css';
import {FieldElement} from './field_element';
import {FieldExample} from './field_example';

export const Field =  ()=>{
  return (
  <React.Fragment>
    <FieldElement />
    <FieldExample />
  </React.Fragment>
  )
};
