export const Clear = () => {
    return (
        <div className="container">
            <h2>フラグクリア</h2>
            <p>カードについているフラグをクリアします</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/clear.png`}></img></p>
           <p>この例では自分手札のフラグをクリアする処理を使っています

           </p>
        </div>
    )
}
