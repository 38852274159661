export const CreateToken =  ()=>{
    return (
        <div className="container">
            <h2>カード生成</h2>
           <p>フィールドにカードを生成します（トークン等に有効です  ）</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/create_token.png`}></img></p>
           この例では自分フィールド(②)に戦士(①)を生成しています
        </div>
    )
}
