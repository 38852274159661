export const TriggerTimingExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            カードが移動した際に呼び出されます
            <p>
            <img src={`${process.env.PUBLIC_URL}/trigger_timing_example1.png`}></img>
            <img src={`${process.env.PUBLIC_URL}/trigger_timing_example2.png`}></img>
            <img src={`${process.env.PUBLIC_URL}/trigger_timing_example3.png`}></img>
            <img src={`${process.env.PUBLIC_URL}/trigger_timing_example4.png`}></img>
            </p>
        <p>この例では戦士がモンスター効果でデッキから墓地へ送られたときに１ドローする効果が書かれています</p>
            
        </div>
    )
}
