import ReactDOM from 'react-dom/client';
import React from 'react';
import {Logo} from './logo';
import {Navbar} from './navbar';
export const Header = () =>{
        return(
            <React.Fragment>
                <Logo></Logo>
                <Navbar></Navbar> 
            </React.Fragment>
        )
}