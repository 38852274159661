import ReactDOM from 'react-dom/client';
import React from 'react';
export const BackgroundElements =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Background name</th><td>背景の名前です。アドミン画面でのみ使うため適当な名前でかまいません。</td></tr>
                    <tr><th>File name</th><td>背景のファイル名です。static/tcgcreator/img/backgroundに入っているファイル名を指定します。</td></tr>
                    <tr><th>Font Color</th><td>現在は使われていません。無視してかまいません。</td></tr>
                </tbody>
            </table>
        </div>
    )
}