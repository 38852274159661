import ReactDOM from 'react-dom/client';
import React from 'react';

export const NavGame =  ()=>{
    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="https://tcgcreator.xyz" id="navbarGameDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                tcgcreator eternal betaで実際に作られたゲーム
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarGameDropdown">
                <a className="dropdown-item" href="https://www.tcgcreator.xyz/spimate/index">新スピマテオンライン</a>
                <a className="dropdown-item" href="https://www.tcgcreator.xyz/cradle/index">Cradle</a>
                <a className="dropdown-item" href="https://www.tcgcreator.xyz/waruen/index">ワルエン</a>
                <a className="dropdown-item" href="https://www.tcgcreator.xyz/tcgcreator/index">カードル♪LIVE ON WEB</a>
                </div>
        </li>
    )
}
