export const FieldPlaceElement =  ()=>{
    return (
        <div className="container">
            カードのフィールドの位置をｘ座標とy座標で表します。以下の例ではｘが１yが2を表します
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_field_place.png`}></img></p>
            また、ここも数式を入れることが可能です。下記例ではカード効果の主体であるtrigger(カード変数については<a href="/tcgcreatoreternalbetaver2/card_variable">こちら</a>)のx座標(数式については<a href="/tcgcreatoreternalbetaver2/equation_editor">こちら</a>)と同じx座標を示しています
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_field_place2.png`}></img></p>

        </div>
    )
}
