
export const TutorialHand =  ()=>{
    return (
        <div className="container">
            <h2>手札の作成</h2>
            ここではゲームで使用する手札の種類を作成していきます。
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_hand_choose.png`}></img></p>
            <p>上図のようにHandを選択します </p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_hand_add.png`}></img></p>
            <p>上図のようにAdd Handを選択します </p>
            <h3>手札の作成</h3>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_hand_input.png`}></img></p>
            <p>上図の様に入力します。それぞれ以下のような意味があります</p>
            <ul>
                <li>①手札が個々のプレイヤーに属するか共通のものかを示します。ここではそれぞれのプレイヤーに手札が属するので特になしとしています </li>
                <li>②手札がプレイヤーに表示されるかを示します。ここでは自分のカード及び相手は表のカードを見れるようにしたいため「自分または表」のみを選択します</li>
                <li>③手札の名前を表します。</li>
                <li>④手札で発動する効果があるかを決めます。今回は手札で発動するかも作りたいのでチェックを入れます</li>
                <li>⑤手札で適用する永続効果があるかを決めます。今回は手札では永続効果を発揮することはないのでチェックを外します</li>
                <li>⑥入力が終わったら保存します</li>
                </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_hand_result.png`}></img></p>
            <p>上図のようになればOKです </p>
            </div>
    )
}
