import React from 'react';
import {MonsterVariableKindElement} from './monster_variable_kind_element';
import {MonsterVariableKindExample} from './monster_variable_kind_example';

export const MonsterVariableKind =  ()=>{
  return (
  <React.Fragment>
    <MonsterVariableKindElement />
    <MonsterVariableKindExample />
  </React.Fragment>
  )
};
