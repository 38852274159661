export const CardNameElement =  ()=>{
    return (
        <div className="container">
            <p>カード名の条件を表します。以下の例では「戦士」を含むカード名であることを示します </p>
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_card_name.png`}></img></p>
            <p>追加を押すとカードの条件を増やすことができます。以下の例では「戦士」または「魔導師」をカード名に含むカードを表しています </p>
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_card_name2.png`}></img></p>
        </div>
    )
}
