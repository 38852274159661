import ReactDOM from 'react-dom/client';
import React from 'react';
export const FusionElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Fusion Name</th><td>融合の名前です</td></tr>
                    <tr><th>Fusion Sentence</th><td>無視してください</td></tr>
                    <tr><th>Fusion1</th><td>融合に必要な素材を書いたjsonです。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Fusion2</th><td>融合に必要な素材を書いたjsonです。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Fusion3</th><td>融合に必要な素材を書いたjsonです。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Monster</th><td>融合で作られるカードです</td></tr>
                    <tr><th>Unique Effect</th><td>融合をしたときの固有効果です</td></tr>
                </tbody>
            </table>
        </div>
    )
}
