export const TutorialGrave =  ()=>{
    return (
        <div className="container">
            <h2>墓地の作成</h2>
            ここではゲームで使用する墓地の種類を作成していきます。
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_grave_choose.png`}></img></p>
            <p>上図のようにGraveを選択します </p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_grave_add.png`}></img></p>
            <p>上図のようにAdd Graveを選択します </p>
            <h3>墓地の作成</h3>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_grave_input.png`}></img></p>
            <p>上図の様に入力します。それぞれ以下のような意味があります</p>
            <ul>
                <li>①墓地が個々のプレイヤーに属するか共通のものかを示します。ここではそれぞれのプレイヤーに墓地が属するので特になしとしています </li>
                <li>②墓地がプレイヤーに表示されるかを示します。ここでは両方のプレイヤーに表示したので両方を選びます </li>
                <li>③墓地の名前を表します。</li>
                <li>④墓地のデュエル画面での表示順を決めます(大きいものほど上になります)</li>
                <li>⑤墓地で発動する効果があるかを決めます。今回は墓地で発動するかも作りたいのでチェックを入れます</li>
                <li>⑥墓地で適用する永続効果があるかを決めます。今回は墓地で適用する永続効果を作りたいのでチェックを入れます</li>
                <li>⑦入力が終わったら保存します</li>
                </ul>
            <h3>除外の作成</h3>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_grave_input2.png`}></img></p>
            <p>除外も作成します。墓地と違い裏向きのカードを相手に見せたくないのでshowに「自分または表のみ」を選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_grave_result.png`}></img></p>
            <p>上図のようになればOKです </p>
        </div>
    )
}
