import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Header} from './header';
import {Footer} from './footer';
import {CostWrapperElements} from './cost_wrapper_element';
import {CostWrapperExample} from './cost_wrapper_example';
import reportWebVitals from './reportWebVitals';

export const CostWrapper =  ()=>{
  return (
  <React.Fragment>
    <CostWrapperElements />
    <CostWrapperExample />
  </React.Fragment>
  )
};
