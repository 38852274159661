import ReactDOM from 'react-dom/client';
import React from 'react';
export const MonsterVariableKindExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            Monster Variable Kindはカードの変数の種類のためのテーブルです   
            <p>
            <img src={`${process.env.PUBLIC_URL}/monster_variable_kind_example.png`}></img>
            </p>
            <p>この例では「ドラゴン」、「マジシャン」、「コモン」という値を取りうる「種族」という変数の種類を作っています</p>
            
        </div>
    )
}
