export const MonsterElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Monster Name</th><td>カードの名前です。</td></tr>
                    <tr><th>Monster Sentence</th><td>カードテキストです。</td></tr>
                    <tr><th>Monster Limit</th><td>デッキに入れられるカードの最大数です。</td></tr>
                    <tr><th>Monster Deck</th><td>カードを入れることができるデッキの種類です。</td></tr>
                    <tr><th>Trigger</th><td>カードに紐づいた効果です</td></tr>
                    <tr><th>Eternal Effect</th><td>カードに紐づいた永続効果です</td></tr>
                    <tr><th>Img</th><td>カードの画像です。static/tcgcreator/imgに保存します</td></tr>
                    <tr><th>Instead Img</th><td>空に指定ください</td></tr>
                    <tr><th>Token Flag</th><td>カードがトークンであることを示します。トークンはデッキに入れられずフィールドを離れると消滅します</td></tr>
                    <tr><th>Monster Item</th><td>カードの変数を示します</td></tr>
                    <tr><th>Monster Variable Id</th><td>カードの変数の種類を表します</td></tr>
                    <tr><th>Monster Variable Text</th><td>カードの変数の値を表します</td></tr>
                </tbody>
            </table>
        </div>
    )
}