import ReactDOM from 'react-dom/client';
import React from 'react';
export const EternalTriggerElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Priority</th><td>効果が処理される順番です、大きいほど先に処理されます</td></tr>
                    <tr><th>Turn</th><td>効果が処理されるターンを示します</td></tr>
                    <tr><th>Chain</th><td>効果が処理される効果処理数を示します</td></tr>
                    <tr><th>Chain Kind</th><td>効果が処理される効果処理数の式を示します</td></tr>
                    <tr><th>Phase</th><td>効果が処理されるフェイズを示します</td></tr>
                    <tr><th>Timing</th><td>効果が処理されるタイミングを示します</td></tr>
                    <tr><th>Timing2</th><td>効果が処理されるタイミングを示します</td></tr>
                    <tr><th>Timing3</th><td>効果が処理されるタイミングを示します</td></tr>
                    <tr><th>None Timing</th><td>効果が処理されるタイミングに入っていない場合に処理されるかを示します</td></tr>
                    <tr><th>None Timing2</th><td>効果が処理されるタイミングに入っていない場合に処理されるかを示します</td></tr>
                    <tr><th>None Timing3</th><td>効果が処理されるタイミングに入っていない場合に処理されるかを示します</td></tr>
                    <tr><th>Eternal Effect Next</th><td>効果をの実際の処理をおこなう効果を示します</td></tr>
                    <tr><th>Eternal Name</th><td>効果の名前です</td></tr>
                    <tr><th>Enemy</th><td>Aiモードの時に適用されるかを示します</td></tr>
                    <tr><th>Enemy Own</th><td>Aiモードの時Aiによって使われるか人間によって使われるかを示します</td></tr>


                </tbody>
            </table>
        </div>
    )
}