import React from 'react';
import ReactDOM from 'react-dom/client';
import {
BrowserRouter as Router,
Routes,

Route,
} from "react-router-dom";
import './index.css';
import "bootstrap"
import App from './App';
import {MonsterEffectPage} from './MonsterEffectPage'
import {Header} from './header';
import {Background} from './Background';
import {Config} from './Config';
import {Constraint} from './Constraint';
import {CostWrapper} from './CostWrapper';
import {Cost} from './Cost';
import {Deck} from './Deck';
import {DefaultDeckChoice} from './DefaultDeckChoice';
import {DefaultDeckGroup} from './DefaultDeckGroup';
import {DefaultDeck} from './DefaultDeck';
import {DuelDeck} from './DuelDeck';
import {DuelGrave} from './DuelGrave';
import {DuelHand} from './DuelHand';
import {Duel} from './Duel';
import {EndChainEffect} from './EndChainEffect';
import {EnemyDeckChoice} from './EnemyDeckChoice';
import {EnemyDeckGroup} from './EnemyDeckGroup';
import {EnemyDeck} from './EnemyDeck';
import {EternalEffect} from './EternalEffect';
import {EternalTrigger} from './EternalTrigger';
import {EternalWrapper} from './EternalWrapper';
import {FieldKind} from './FieldKind';
import {FieldSize} from './FieldSize';
import {Field} from './Field';
import {Fusion} from './Fusion';
import {GlobalVariable} from './GlobalVariable';
import {Grave} from './Grave';
import {Hand} from './Hand';
import {Lock} from './Lock';
import {MonsterEffectKind} from './MonsterEffectKind';
import {MonsterEffectWrapper} from './MonsterEffectWrapper';
import {MonsterEffect} from './MonsterEffect';
import {MonsterVariableKind} from './MonsterVariableKind';
import {MonsterVariable} from './MonsterVariable';
import {Monster} from './Monster';
import {PacCostWrapper} from './PacCostWrapper';
import {PacCost} from './PacCost';
import {PacWrapper} from './PacWrapper';
import {Pac} from './Pac';
import {Phase} from './Phase';
import {SpecialCard} from './SpecialCard';
import {Timing} from './Timing';
import {TriggerTimingChangeVal} from './TriggerTimingChangeVal';
import {TriggerTimingMonsterChangeVal} from './TriggerTimingMonsterChangeVal';
import {TriggerTimingNotEffected} from './TriggerTimingNotEffected';
import {TriggerTimingRelation} from './TriggerTimingRelation';
import {TriggerTiming} from './TriggerTiming';
import {Trigger} from './Trigger';
import {UnderDirection} from './UnderDirection';
import {UserDeckChoice} from './UserDeckChoice';
import {UserDeckGroup} from './UserDeckGroup';
import {UserDeck} from './UserDeck';
import {UserPoint} from './UserPoint';
import {VirtualVariable} from './VirtualVariable';
import {DialogBox} from './DialogBox';
import {Footer} from './footer';
import reportWebVitals from './reportWebVitals';
import {AddRelationElement} from './dialogbox_add_relation';
import {AsElement} from './dialogbox_as';
import {AsMonsterToElement} from './dialogbox_as_monster_to';
import {CardElement} from './dialogbox_card';
import {CardCustomVariableElement} from './dialogbox_card_custom_variable';
import {CardFromElement} from './dialogbox_card_from';
import {CardNameElement} from './dialogbox_card_name';
import {CardNumElement} from './dialogbox_card_num';
import {CardVariableElement} from './dialogbox_card_variable';
import {ChooseElement} from './dialogbox_choose';
import {ExcludeElement} from './dialogbox_exclude';
import {FieldPlaceElement} from './dialogbox_field_place';
import {FlagElement} from './dialogbox_flag';
import {GlobalVariableElement} from './dialogbox_global_variable';
import {Game} from './game';
import {Debug} from './debug';
import {GroupElement} from './dialogbox_group';
import {MonsterEffectValidElement} from './dialogbox_monster_effect_valid';
import {MonsterExistElement} from './dialogbox_monster_exist';
import {OtherElement} from './dialogbox_other';
import {PlaceElement} from './dialogbox_place';
import {RelationElement} from './dialogbox_relation';
import {ToElement} from './dialogbox_to';
import {TurnCountElement} from './dialogbox_turn_count';
import {UnderElement} from './dialogbox_under';
import {VariableElement} from './dialogbox_variable';
import { EquationCardEffect } from './equation_card_effect';
import { EquationCardEffect2 } from './equation_card_effect2';
import { EquationCardFlag } from './equation_card_flag';
import { EquationCardFlag2 } from './equation_card_flag2';
import { EquationCardFunction } from './equation_card_function';
import { EquationCardKind } from './equation_card_kind';
import { EquationCardKind2 } from './equation_card_kind2';
import { EquationCardVariable } from './equation_card_variable';
import { EquationCardVariable2 } from './equation_card_variable2';
import { EquationCardVariableCondition } from './equation_card_variable_condition';
import { EquationEditor } from './EquationEditor';
import { EquationEditorGlobalVariable } from './EquationEditorGlobalVariable';
import { EquationEditorPlace } from './EquationEditorPlace';
import { EquationEditorVariable } from './EquationEditorVariable';
import { EquationFlag } from './equation_flag';
import { EquationGlobalVariable } from './equation_global_variable';
import { EquationPlace } from './equation_place';
import { EquationRelation } from './equation_relation';
import { EquationRelationCard } from './equation_relation_card';
import { EquationRelationKind } from './equation_relation_kind';
import { EquationRelationTo } from './equation_relation_to';
import { EquationUnder } from './equation_under';
import { EquationVariable } from './equation_variable';
import { CardVariable } from './CardVariable';
import { MonsterEffectCondition } from './monster_effect_condition';
import { Comment} from './comment';
import { DoEternal } from './do_eternal';
import { DoCost } from './do_cost';
import { Each } from './each';
import { Draw } from './draw';
import { Lose } from './lose';
import { Win } from './win';
import { Cancel } from './cancel';
import { Sound } from './sound';
import { Music } from './music';
import { Force } from './force';
import { Mute } from './mute';
import { ChangeAppoint } from './change_appoint';
import { RelationMultipleOpponent } from './relation_multiple_opponent';
import { RelationMultiple } from './relation_multiple';
import { GlobalVariableChangeOpponent } from './global_variable_change_opponent';
import { GlobalVariableChange } from './global_variable_change';
import { ChainVariableOpponent } from './chain_variable_opponent';
import { ChainVariable} from './chain_variable';
import { MoveToTimingVariable} from './move_to_timing_variable';
import { ChooseMultipleOpponent} from './choose_mulltiple_opponent';
import { ChooseMultiple} from './choose_mulltiple';
import { YesOrNoOpponent } from './yes_or_no_opponent';
import { YesOrNo } from './yes_or_no';
import { Random } from './random';
import { Coin } from './coin';
import { RaiseEtenral } from './raise_eternal';
import { NextTiming } from './next_timing';
import { ChangeAppointment } from './change_appointment';
import { ChangeTiming } from './change_timing';
import { Raise } from './raise';
import { Clear } from './clear';
import { Shuffle } from './shuffle';
import { TurnEnd } from './turn_end';
import { PhaseMove } from './phase_choose';
import { ChooseReload } from './choose_reload';
import { ChooseReload2} from './choose_reload2';
import { ChooseClear} from './choose_clear';
import { ChooseOpponent} from './choose_opponent';
import { ChooseAsRewrite} from './choose_as_rewrite';
import { ChooseAsUnder} from './choose_as_under';
import { ChooseAs} from './choose_as';
import { Choose} from './choose';
import {CopyEffectCost} from './copy_effect_cost'
import {CopyEffect2} from './copy_effect2'
import {CopyEffect} from './copy_effect'
import {CopyMonsterSpecify} from './copy_monster_specify';
import {CopyMonster} from './copy_monster';
import { ChangeMonsterRelationAllClear } from './change_monster_relation_all_clear';
import { ChangeMonsterRelationClear } from './change_monster_relation_clear';
import { ChangeMonsterRelationChange } from './change_monster_relation_change';
import { ChangeMonsterRelation } from './change_monster_relation';
import { ChangeMonsterVariableRelation } from './change_monster_variable_relation';
import { ChangeMonsterVariable } from './change_monster_variable';
import { ChangeGlobalVariableShow } from './change_global_variable_show';
import { ChangeGlobalVariableByMonster } from './change_global_variable_by_monster';
import { ChangeGlobalVariableMultiple } from './change_global_variable_multiple';
import { AccValFlush } from './acc_val_flush';
import { AltValChange } from './alt_val_change';
import { ChangeGlobalVariableOrder2 } from './change_global_variable_order.2tsx';
import { ChangeGlobalVariableOrder } from './change_global_variable_order';
import { ChangeGlobalVariable } from './change_global_variable';
import { DeleteCard } from './delete_card';
import { CreateToken } from './create_token';
import { MoveSimple } from './move_simple';
import { MoveToTop } from './move_to_top';
import { MoveToUnder } from './move_to_under';
import { MoveRelationxCard } from './move_relation_card';
import { MoveUnder } from './move_under';
import { FusionUniqueEffect } from './fusion_unique_effect';
import { FusionMaterial } from './fusion_material';
import { FusionChoose } from './fusion_choose';
import { Move } from './move';
import { Invalid } from './invalid';
import { PhaseCondition } from './phase_condition';
import { ChainCondition } from './chain_condition';
import { AltVal } from './alt_val';
import { Tutorial } from './tutorial';
import { TutorialField } from './tutorial_field';
import { TutorialPhase } from './tutorial_phase';
import { MonsterVariableElement } from './monster_variable_element';
import { TutorialMonsterVariable } from './tutorial_monster_variable';
import { TutorialGlobalVariable } from './tutorial_global_variable';
import { TutorialDeck } from './tutorial_deck';
import { TutorialGrave } from './tutorial_grave';
import { TutorialHand } from './tutorial_hand';
import { TutorialMonster } from './tutorial_monster';
import { TutorialStartUp } from './tutorial_start_up';
import { TutorialToPhase } from './tutorial_to_phase';
import { TutorialDefaultDeck } from './tutorial_default_deck';
import { TutorialTest } from './tutorial_test';
import { TutorialDrawPhase } from './tutorial_draw_phase';
import { TutorialSummon } from './tutorial_summon';
import { TutorialBattle } from './tutorial_battle';
import { TutorialDirectAttack } from './tutorial_direct_attack';
import { TutorialEndPhase } from './tutorial_end_phase';
import { Document } from './document';
import { Log } from './Log';
import { Install} from './install';
import { Launch} from './launch';
import { DownLoad} from './download';
import { DownloadElement} from './download_element';
import { Table} from './table';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.Fragment>
    <Header />
    <Router basename="/tcgcreatoreternalbetaver2">
      <Routes>
<Route path="/" element={<App />} />
<Route path="/background" element={<Background/>} />
<Route path="/config" element={<Config/>} />
<Route path="/constraint" element={<Constraint/>} />
<Route path="/cost_wrapper" element={<CostWrapper/>} />
<Route path="/cost" element={<Cost/>} />
<Route path="/deck" element={<Deck/>} />
<Route path="/default_deck_choice" element={<DefaultDeckChoice/>} />
<Route path="/default_deck_group" element={<DefaultDeckGroup/>} />
<Route path="/default_deck" element={<DefaultDeck/>} />
<Route path="/duel_deck" element={<DuelDeck/>} />
<Route path="/duel_grave" element={<DuelGrave/>} />
<Route path="/duel_hand" element={<DuelHand/>} />
<Route path="/duel" element={<Duel/>} />
<Route path="/end_chain_effect" element={<EndChainEffect/>} />
<Route path="/enemy_deck_choice" element={<EnemyDeckChoice/>} />
<Route path="/enemy_deck_group" element={<EnemyDeckGroup/>} />
<Route path="/enemy_deck" element={<EnemyDeck/>} />
<Route path="/eternal_effect" element={<EternalEffect/>} />
<Route path="/eternal_trigger" element={<EternalTrigger/>} />
<Route path="/eternal_wrapper" element={<EternalWrapper/>} />
<Route path="/field_kind" element={<FieldKind/>} />
<Route path="/field_size" element={<FieldSize/>} />
<Route path="/field" element={<Field/>} />
<Route path="/fusion" element={<Fusion/>} />
<Route path="/global_variable" element={<GlobalVariable/>} />
<Route path="/grave" element={<Grave/>} />
<Route path="/hand" element={<Hand/>} />
<Route path="/lock" element={<Lock/>} />
<Route path="/monster_effect_kind" element={<MonsterEffectKind/>} />
<Route path="/monster_effect_wrapper" element={<MonsterEffectWrapper/>} />
<Route path="/monster_effect" element={<MonsterEffect/>} />
<Route path="/monster_variable_kind" element={<MonsterVariableKind/>} />
<Route path="/monster_variable" element={<MonsterVariable/>} />
<Route path="/monster" element={<Monster/>} />
<Route path="/pac_cost_wrapper" element={<PacCostWrapper/>} />
<Route path="/pac_cost" element={<PacCost/>} />
<Route path="/pac_wrapper" element={<PacWrapper/>} />
<Route path="/pac" element={<Pac/>} />
<Route path="/phase" element={<Phase/>} />
<Route path="/special_card" element={<SpecialCard/>} />
<Route path="/timing" element={<Timing/>} />
<Route path="/trigger_timing_change_val" element={<TriggerTimingChangeVal/>} />
<Route path="/trigger_timing_monster_change_val" element={<TriggerTimingMonsterChangeVal/>} />
<Route path="/trigger_timing_not_effected" element={<TriggerTimingNotEffected/>} />
<Route path="/trigger_timing_relation" element={<TriggerTimingRelation/>} />
<Route path="/trigger_timing" element={<TriggerTiming/>} />
<Route path="/trigger" element={<Trigger/>} />
<Route path="/under_direction" element={<UnderDirection/>} />
<Route path="/user_deck_choice" element={<UserDeckChoice/>} />
<Route path="/user_deck_group" element={<UserDeckGroup/>} />
<Route path="/user_deck" element={<UserDeck/>} />
<Route path="/user_point" element={<UserPoint/>} />
<Route path="/virtual_variable" element={<VirtualVariable/>} />
<Route path="/dialogbox" element={<DialogBox/>} />
<Route path="/dialogbox_add_relation" element={<AddRelationElement/>} />
<Route path="/dialogbox_as" element={<AsElement/>} />
<Route path="/dialogbox_as_monster_to" element={<AsMonsterToElement/>} />
<Route path="/dialogbox_card" element={<CardElement/>} />
<Route path="/dialogbox_card_custom_variable" element={<CardCustomVariableElement/>} />
<Route path="/dialogbox_card_from" element={<CardFromElement/>} />
<Route path="/dialogbox_card_name" element={<CardNameElement/>} />
<Route path="/dialogbox_card_num" element={<CardNumElement/>} />
<Route path="/dialogbox_card_variable" element={<CardVariableElement/>} />
<Route path="/dialogbox_choose" element={<ChooseElement/>} />
<Route path="/dialogbox_exclude" element={<ExcludeElement/>} />
<Route path="/dialogbox_field_place" element={<FieldPlaceElement/>} />
<Route path="/dialogbox_flag" element={<FlagElement/>} />
<Route path="/dialogbox_global_variable" element={<GlobalVariableElement/>} />
<Route path="/dialogbox_group" element={<GroupElement/>} />
<Route path="/dialogbox_monster_effect_valid" element={<MonsterEffectValidElement/>} />
<Route path="/dialogbox_monster_exist" element={<MonsterExistElement/>} />
<Route path="/dialogbox_other" element={<OtherElement/>} />
<Route path="/dialogbox_place" element={<PlaceElement/>} />
<Route path="/dialogbox_relation" element={<RelationElement/>} />
<Route path="/dialogbox_to" element={<ToElement/>} />
<Route path="/dialogbox_turn_count" element={<TurnCountElement/>} />
<Route path="/dialogbox_under" element={<UnderElement/>} />
<Route path="/dialogbox_variable" element={<VariableElement/>} />
<Route path="/equation_card_effect" element={<EquationCardEffect/> }/>
<Route path="/equation_card_effect2" element={<EquationCardEffect2/> }/>  
<Route path="/equation_card_flag" element={<EquationCardFlag/> }/>  
<Route path="/equation_flag2" element={<EquationCardFlag2/> }/>  
<Route path="/equation_card_function" element={<EquationCardFunction/> }/>  
<Route path="/equation_card_kind" element={<EquationCardKind/> }/>  
<Route path="/equation_card_kind2" element={<EquationCardKind2/> } />
<Route path="/equation_card_variable" element={<EquationCardVariable/> }/>  
<Route path="/equation_card_variable2" element={<EquationCardVariable2/> }/>  
<Route path="/equation_card_variable_condition" element={<EquationCardVariableCondition/> }/>  
<Route path="/equation_editor" element={<EquationEditor/> }  />
<Route path="/equation_global_variable_detail" element={<EquationEditorGlobalVariable/> }/>
<Route path="/equation_place_detail" element={<EquationEditorPlace/> } /> 
<Route path="/equation_variable_detail" element={<EquationEditorVariable/> } /> 
<Route path="/equation_flag" element={<EquationFlag/> }  />
<Route path="/equation_flag2" element={<EquationCardFlag2/> }  />
<Route path="/equation_global_variable" element={<EquationGlobalVariable/> }/>  
<Route path="/equation_place" element={<EquationPlace/> } /> 
<Route path="/equation_relation" element={<EquationRelation/> }/>  
<Route path="/equation_relation_card" element={<EquationRelationCard/> }/>  
<Route path="/equation_relation_kind" element={<EquationRelationKind/> } /> 
<Route path="/equation_relation_to" element={<EquationRelationTo/> } /> 
<Route path="/equation_under" element={<EquationUnder/> }/>
<Route path="/equation_variable" element={<EquationVariable/> }/>  
<Route path="/card_variable" element={<CardVariable/> }/>  
<Route path="/monster_effect_condition" element={<MonsterEffectCondition/> }/>  
<Route path="/comment" element={<Comment/> }/>  
<Route path="/do_eternal" element={<DoEternal/> }/>  
<Route path="/do_cost" element={<DoCost/> }/>  
<Route path="/each" element={<Each/> }/>  
<Route path="/draw" element={<Draw/> }/>  
<Route path="/lose" element={<Lose/> }/>  
<Route path="/win" element={<Win/> }/>  
<Route path="/cancel" element={<Cancel/> }/>  
<Route path="/sound" element={<Sound/> }/>  
<Route path="/music" element={<Music/> }/>  
<Route path="/force" element={<Force/> }/>  
<Route path="/mute" element={<Mute/> }/>  
<Route path="/change_appoint" element={<ChangeAppoint/> }/>  
<Route path="/relation_multiple_opponent" element={<RelationMultipleOpponent/> }/>  
<Route path="/relation_multiple" element={<RelationMultiple/> }/>  
<Route path="/global_variable_change_opponent" element={<GlobalVariableChangeOpponent/> }/>  
<Route path="/global_variable_change" element={<GlobalVariableChange/> }/>  
<Route path="/chain_variable_opponent" element={<ChainVariableOpponent/> }/>  
<Route path="/chain_variable" element={<ChainVariable/> }/>  
<Route path="/move_to_timing_variable" element={<MoveToTimingVariable/> }/>  
<Route path="/choose_multiple_opponent" element={<ChooseMultipleOpponent/> }/>  
<Route path="/choose_multiple" element={<ChooseMultiple/> }/>  
<Route path="/yes_or_no_opponent" element={<YesOrNoOpponent/> }/>  
<Route path="/yes_or_no" element={<YesOrNo/> }/>  
<Route path="/random" element={<Random/> }/>  
<Route path="/coin" element={<Coin/> }/>  
<Route path="/raise_eternal" element={<RaiseEtenral/> }/>  
<Route path="/next_timing" element={<NextTiming/> }/>  
<Route path="/change_timing" element={<ChangeTiming/> }/>  
<Route path="/change_appointment" element={<ChangeAppointment/> }/>  
<Route path="/raise" element={<Raise/> }/>  
<Route path="/clear" element={<Clear/> }/>  
<Route path="/shuffle" element={<Shuffle/> }/>  
<Route path="/turn_end" element={<TurnEnd/> }/>  
<Route path="/phase_choose" element={<PhaseMove/> }/>  
<Route path="/choose_reload" element={<ChooseReload/> }/>  
<Route path="/choose_reload2" element={<ChooseReload2/> }/>  
<Route path="/choose_clear" element={<ChooseClear/> }/>  
<Route path="/choose_opponent" element={<ChooseOpponent/> }/>  
<Route path="/choose_as_rewrite" element={<ChooseAsRewrite/> }/>  
<Route path="/choose_as_under" element={<ChooseAsUnder/> }/>  
<Route path="/choose_as" element={<ChooseAs/> }/>  
<Route path="/choose" element={<Choose/> }/>  
<Route path="/copy_effect_cost" element={<CopyEffectCost/> }/>  
<Route path="/copy_effect2" element={<CopyEffect2/> }/>  
<Route path="/copy_effect" element={<CopyEffect/> }/>  
<Route path="/copy_monster_specify" element={<CopyMonsterSpecify/> }/>  
<Route path="/copy_monster" element={<CopyMonster/> }/>  
<Route path="/change_monster_relation_all_clear" element={<ChangeMonsterRelationAllClear/> }/>  
<Route path="/change_monster_relation_clear" element={<ChangeMonsterRelationClear/> }/>  
<Route path="/change_monster_relation_change" element={<ChangeMonsterRelationChange/> }/>  
<Route path="/change_monster_relation" element={<ChangeMonsterRelation/> }/>  
<Route path="/change_monster_variable_relation" element={<ChangeMonsterVariableRelation/> }/>  
<Route path="/change_monster_variable" element={<ChangeMonsterVariable/> }/>  
<Route path="/change_global_variable_show" element={<ChangeGlobalVariableShow/> }/>  
<Route path="/change_global_variable_multiple" element={<ChangeGlobalVariableMultiple/> }/>  
<Route path="/change_global_variable_by_monster" element={<ChangeGlobalVariableByMonster/> }/>  
<Route path="/acc_val_flush" element={<AccValFlush/> }/>  
<Route path="/alt_val_change" element={<AltValChange/> }/>  
<Route path="/change_global_variable_order2" element={<ChangeGlobalVariableOrder2/> }/>  
<Route path="/change_global_variable_order" element={<ChangeGlobalVariableOrder/> }/>  
<Route path="/change_global_variable" element={<ChangeGlobalVariable/> }/>  
<Route path="/delete_card" element={<DeleteCard/> }/>  
<Route path="/create_token" element={<CreateToken/> }/>  
<Route path="/move_simple" element={<MoveSimple/> }/>  
<Route path="/move_to_top" element={<MoveToTop/> }/>  
<Route path="/move_to_under" element={<MoveToUnder/> }/>  
<Route path="/move_relation_card" element={<MoveRelationxCard/>} /> 
<Route path="/move_under" element={<MoveUnder/> }/>  
<Route path="/fusion_unique_effect" element={<FusionUniqueEffect/> }/>  
<Route path="/fusion_material" element={<FusionMaterial/> }/>  
<Route path="/fusion_choose" element={<FusionChoose/> }/>  
<Route path="/move" element={<Move/> }/>  
<Route path="/invalid" element={<Invalid/> }/>  
<Route path="/phase_condition" element={<PhaseCondition/> }/>  
<Route path="/chain_condition" element={<ChainCondition/> }/>  
<Route path="/alt_val" element={<AltVal/> }/>  
<Route path="/monster_effect_page" element={<MonsterEffectPage/> }/>  
<Route path="/tutorial" element={<Tutorial/> }/>  
<Route path="/tutorial_field" element={<TutorialField/> }/>  
<Route path="/tutorial_phase" element={<TutorialPhase/> }/>  
<Route path="/tutorial_monster_variable" element={<TutorialMonsterVariable/> }/>  
<Route path="/tutorial_global_variable" element={<TutorialGlobalVariable/> }/>  
<Route path="/tutorial_deck" element={<TutorialDeck/> }/>  
<Route path="/tutorial_grave" element={<TutorialGrave/> }/>  
<Route path="/tutorial_hand" element={<TutorialHand/> }/>  
<Route path="/tutorial_monster" element={<TutorialMonster/> }/>  
<Route path="/tutorial_start_up" element={<TutorialStartUp/> }/>  
<Route path="/tutorial_to_phase" element={<TutorialToPhase/> }/>  
<Route path="/tutorial_default_deck" element={<TutorialDefaultDeck/> }/>  
<Route path="/tutorial_test" element={<TutorialTest/> }/>  
<Route path="/tutorial_draw_phase" element={<TutorialDrawPhase/> }/>  
<Route path="/tutorial_summon" element={<TutorialSummon/> }/>  
<Route path="/tutorial_battle" element={<TutorialBattle/> }/>  
<Route path="/tutorial_direct_attack" element={<TutorialDirectAttack/> }/>  
<Route path="/tutorial_end_phase" element={<TutorialEndPhase/> }/>  
<Route path="/document" element={<Document/> }/>  
<Route path="/log" element={<Log/> }/>  
<Route path="/install" element={<Install/> }/>  
<Route path="/launch" element={<Launch/> }/>  
<Route path="/table" element={<Table/> }/>  
<Route path="/download" element={<DownLoad/> }/>  
<Route path="/download_element" element={<DownloadElement/> }/>  
<Route path="/game" element={<Game/> }/>  
<Route path="/debug" element={<Debug/> }/>  

</Routes>




</Router>
    <Footer />
  </React.Fragment>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
