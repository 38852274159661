import React from 'react';

export const DialogBox =  ()=>{
  return (
    <div>
        <h1>ダイアログボックス</h1>
        <p>ここではダイアログボックスの説明を行います
        ダイアログボックスは図のように条件や移動するカードを指定するカードとなります
        </p>
        <img src={`${process.env.PUBLIC_URL}/dialogbox.png`}></img>
        <div className="list-group list-group-numbered">
            <a href="/tcgcreatoreternalbetaver2/dialogbox_global_variable" className="list-group-item">グローバル変数の条件を表します（疑似グローバル変数も使えます。）条件を指定するときのみに使えます</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_group" className="list-group-item">条件を複数指定するときに使います。条件を指定するときのみに使えます</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_place" className="list-group-item">カードが存在する場所を示します。あらゆるシチュエーションで使えます</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_flag" className="list-group-item">カードにフラグが立っているかをあらわします。あらゆるシチュエーションで使えます</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_card_name" className="list-group-item">カードの名前の条件を示します。あらゆるシチュエーションで使えます</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_card" className="list-group-item">カードの種類を表します。基本的に非推奨です</a>
            <a href="/tcgcreatoreternalbetaver2/#" className="list-group-item">使われていません。無視してください</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_turn_count" className="list-group-item">カードのターンを表します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_card_from" className="list-group-item">カードが移動してきた場合移動元を表します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_card_variable" className="list-group-item">カードのパラメーター条件のうちMonsterVariableに登録されているものを表します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_card_custom_variable" className="list-group-item">カードのパラメーター条件のうちMonsterVariableに登録されていないものを表します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_under" className="list-group-item">カードの下のカードについての条件を表します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_relation" className="list-group-item">カードの紐づけの条件を表します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_monster_effect_valid" className="list-group-item">カードの効果が有効かを表します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_monster_exist" className="list-group-item">カード有無は選択の時にカードが存在するかを表します。そのほかは無視してください</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_field_place" className="list-group-item">カードの存在する場所を指定します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_exclude" className="list-group-item">選択や移動から除外するカードの<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>を表します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_card_num" className="list-group-item">選択や移動の枚数を表します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_as" className="list-group-item">選択の場合は選択の結果入る<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>を表します。条件の場合は<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>が満たすべき条件を表します。移動の場合は移動するカードの<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>を表します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_variable" className="list-group-item">移動やモンスター変数変動でカードパラメーターを変更する際に使用します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_add_relation" className="list-group-item">移動やモンスターrelation時にカードの紐づけを追加します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_as_monster_to" className="list-group-item">移動の際に移動後、<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_to" className="list-group-item">移動の際に移動先の場所を指定します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_other" className="list-group-item">同名カード不可は選択の際に同名カードを不可に、全部選択表時は選択時にすべて選択を表示する。自動選択表示は無視してください。左から選択はカード選択時に左から選択を表示します</a>
            <a href="/tcgcreatoreternalbetaver2/dialogbox_choose" className="list-group-item">条件の際に効果を受けるカードをカウントするかを表します</a>
</div>
    </div>
  )
};
