export const TutorialToPhase =  ()=>{
    return (
        <div className="container">
            <h2>バトルフェイズ移行及びエンドフェイズ移行の処理を作る</h2>
            <p>メインフェイズからバトルフェイズ移行及びエンドフェイズ移行を作っていきます</p>
            <h3>バトルフェイズ移行の作成</h3>
            <p>管理画面からMonster effectを選択し、ドローフェイズ移動をクリックします</p>
            <p>画面をスクロールして、Save as Newをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_move_to_battle_phase.png`}></img></p>
            <p>Monster Effectの右(①)をクリックして、バトルフェイズを選択します(②)。Monster Effec Nameをバトルフェイズに移動に変更します。</p>
            <p>入力が終わったらSaveをクリックします</p>
            <p>管理画面からMonster effect Wrapperを選択し、ドローフェイズ移動をクリックします</p>
            <p>画面をスクロールして、Save as Newをクリックします</p>
            <p>Monster effectをバトルフェイズへ移動に、Monster Effect Nameをバトルフェイズ移動にしてSaveします</p>
            <p>次にTriggerを作っていきます、管理画面でTriggerを選択し、Add Triggerをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_to_battle_trigger.png`}></img></p>
            <p>上図のように、Mine Or Otherを自分、Turnも自分、Phaseはメインフェイズ、Chainを0、Chain Kindをちょうど、no chainをチェックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_to_battle_trigger2.png`}></img></p>
            <p>Next Effectをバトルフェイズ移動に、Trigger none monsterにチェックを入れTrigger NameとTrigger Sentenceをバトルフェイズ移動にします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_to_battle_trigger3.png`}></img></p>
            <p><img src={`${process.env.PUBLIC_URL}tutorial_phase_to_battl_trigger4.png`}></img></p>
            <p>バトルフェイズへの移行が１ターンに一度なのでonce per turnのみにチェックを入れます</p>
            <p><img src={`${process.env.PUBLIC_URL}tutorial_phase_to_battle_trigger5.png`}></img></p>
            <p>下にスクロールして、Saveをクリックします</p>
            <h3>エンドフェイズ移行の作成</h3>
            <p>管理画面からMonster effectを選択し、バトルフェイズへ移動をクリックします</p>
            <p>画面をスクロールして、Save as Newをクリックします</p>
            <p>Monster Effectの右をクリックして、エンドフェイズを選択します(②)。Monster Effec Nameをエンドフェイズに移動に変更します。</p>
            <p>入力が終わったらSaveをクリックします</p>
            <p>管理画面からMonster effect Wrapperを選択し、バトルフェイズ移動をクリックします</p>
            <p>画面をスクロールして、Save as Newをクリックします</p>
            <p>Monster effectをエンドフェイズへ移動に、Monster Effect Nameをエンドフェイズ移動にしてSaveします</p>
            <p>Triggerを作っていきます、管理画面でTriggerを選択し、Add Triggerをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_to_end_choose.png`}></img></p>
            <p>バトルフェイズへ移動を選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_to_end_choose2.png`}></img></p>
            <p>画面したまでスクロールしてSave as newをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_to_end_trigger.png`}></img></p>
            <p>Next Effectをエンドフェイズ移動で、Trigger Name、TriggerSentenceをエンドフェイズ移動にします</p>
            <p>以上で画面したまでスクロールしてSaveをクリックします</p>
            <h3>バトルフェイズからメインフェイズ移行の作成</h3>
            <p>Triggerを作っていきます、管理画面でTriggerを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_to_end_choose.png`}></img></p>
            <p>バトルフェイズへ移動を選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_to_end_choose2.png`}></img></p>
            <p>画面したまでスクロールしてSave as newをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_to_main2.png`}></img></p>
            <p>Phaseをバトルフェイズへ、Next Effectをメインフェイズ移動で、Trigger Name、TriggerSentenceをメインフェイズ移動にします</p>
            <p>以上で画面したまでスクロールしてSaveをクリックします</p>
            </div>

    )
}
