export const EquationEditorVariable =  ()=>{
    return (
    <div className="container">
        <p>ここでは数式エディタの<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>によるの指定の仕方を示します</p>
        <img src={`${process.env.PUBLIC_URL}/equation_editor_variable.png`}></img>
        <p>まず、 <a href="/tcgcreatoreternalbetaver2/equation_variable" >①のように</a>カード変数を指定します</p>
        <p><a href="/tcgcreatoreternalbetaver2/equation_card_variable2" >②</a>で計算するカードパラメータを指定します</p>
        <p><a href="/tcgcreatoreternalbetaver2/equation_card_flag2" >③</a>で指定したカード変数のうちフラグを満たすものを指定します（指定しないこともできます）</p>
        <p><a href="/tcgcreatoreternalbetaver2/equation_relation" >④</a>で紐づけたカードを指定することも可能ですが今回は指定しません</p>
        <p><a href="/tcgcreatoreternalbetaver2/equation_card_kind2" >⑤</a>でカードを直接指定できます（非推奨です）</p>
        <p><a href="/tcgcreatoreternalbetaver2/equation_relation_card" >⑥</a>で紐づけたカードを指定することも可能ですが今回は指定しません(非推奨です)</p>
        <p><a href="/tcgcreatoreternalbetaver2/equation_under" >⑦</a>カードの下であることを表します(非推奨です)</p>
        <p><a href="/tcgcreatoreternalbetaver2/equation_card_effect2" >⑧</a>カードの効果が有効であることを示します。この例ではモンスター効果が有効であることを示します(非推奨です)</p>
        <hr></hr>
        <p>以上で指定追加を押すと「{'{'}trigger:攻撃力:1:::::0::1:0\{'}'}」と下のテキストボックスに入るはずです</p>
        <p>この例ではtriggerというカード変数に保存しているカードの、フラグが１である、ものの攻撃力を表します</p>
    </div>

    )
}
