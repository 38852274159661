export const GroupElement =  ()=>{
    return (
        <div className="container">
            この１と書かれているものは条件のグループを表します
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_group1.png`}></img></p>
            追加を押すと図のように複数の条件を作ることができます
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_group2.png`}></img></p>
        </div>
    )
}
