import React from 'react';
import {PacCostWrapperElement} from './pac_cost_wrapper_element';
import {PacCostWrapperExample} from './pac_cost_wrapper_example';

export const PacCostWrapper =  ()=>{
  return (
  <React.Fragment>
    <PacCostWrapperElement />
    <PacCostWrapperExample />
  </React.Fragment>
  )
};
