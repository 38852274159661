export const TutorialTest =  ()=>{
    return (
        <div className="container">
            <h2>初めのテスト</h2>
            <p>今までで一応カードゲームの骨格はできたので正しく動くかチェックしてみましょう</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_test_url.png`}></img></p>
            <p>http://127.0.0.1:8000/tcgcreator/choose/をgoogle chromeで開きます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_test_secret.png`}></img></p>
            <p>クロームをシークレットモードで開きhttp://127.0.0.1:8000/tcgcreator/choose/を開きます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_test_example.png`}></img></p>
            <p>上記のようにカードが５枚手札にあれば成功です。一回降参しましょう</p>
        </div>
    )
}
