import ReactDOM from 'react-dom/client';
import React from 'react';
export const FieldExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
                Fieldはフィールドの情報を保持するテーブルです
            <p>
            <img src={`${process.env.PUBLIC_URL}/field_example.png`}></img>
            <p>フィールドの編集をクリックして、各フィールド要素を確認します</p>
            <img src={`${process.env.PUBLIC_URL}/field_det_example.png`}></img>
            <p>この例では「自分フィールド」を作っています</p>
            </p>
            
        </div>
    )
}
