export const TutorialSummon =  ()=>{
    return (
        <div className="container">
            <h2>クリーチャーカードの召喚</h2>
            <h3>効果処理の作成</h3>
            <p>ようやくトレーディングカードゲームらしい処理を作っていきます。召喚は１ターンに一度レベル４以下ノーコストレベル４以上レベル６以下は手札コスト１枚レベル７以上は手札コスト２枚とします</p>
            <p>まずはMonster Effectを管理画面で選択しAdd Monster Effectをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_monster_effect.png`}></img></p>
            <p>Monster Effect Valは移動を選択し、Monster Effectの右の追加をクリックします。ダイアログボックスに上図の様に入力します。以下のような意味があります</p>
            <ul>
                <li>triggerというカード変数です。効果の主体になっているカードを表します。効果の主体のカードが移動することを表します</li>
                <li>カードの移動先です。今回は自分フィールド上に効果の主体となっているカードを移動します</li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_monster_effect2.png`}></img></p>
            <p>Monster Effect Nameにtriggerを自分フィールド上へと入力してSaveをクリックします</p>
            <h3>効果種類の作成</h3>
            <p>効果処理ラッパーの作成といきたいところですが、召喚という効果の種類を作りましょう</p>
            <p>管理画面からMonster Effect Kindをクリックして、Add Monster Effect Kindをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_monster_effect_kind.png`}></img></p>
            <p>Monster Effect Nameに召喚と入力し、Saveをクリックします</p>
            <h3>効果処理ラッパーの作成</h3>
            <p>管理画面からMonster Effect Wrapperをクリックして、Add Monster Effect Wrapperをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_monster_effect_wrapper.png`}></img></p>
            <p>Monster EffectにTriggerを自分フィールドへ、Monster Effect Kindを召喚（２）、Monster Effect Nameを召喚 logを(@)を召喚したとします</p>
            <p>以上が入力できたらSaveをクリックします</p>
            <h3>レベル４以下の効果処理Triggerの作成</h3>
            <p>管理画面からTriggerをクリックして、Add Triggerをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input.png`}></img></p>
            <p>まずは上記のように入力します</p>
            <ul>
                <li>①自分を入力してください</li>
                <li>②自分ターンに行う処理なので自分を入力してください</li>
                <li>③メインフェイズに行う処理なのでメインフェイズを選択します</li>
                <li>④⑤⑥何かの処理にチェーンして行う処理ではないのでこのように入力します</li>
                <li>⑦今回は強制効果でありませんのでチェックはしません</li>
                <li>⑧召喚の効果を選択します」</li>


            </ul>
            <h4>効果処理発動条件の設定</h4>
            <p>召喚は自分モンスターゾーンが空いてる必要があるためモンスターゾーンのカードの枚数が４枚以下である必要があります。</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input2.png`}></img></p>
            <p>Trigger Conditionの右の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input3.png`}></img></p>
            <p>ダイアログボックスに上図のように入力します。意味としては以下のようになります</p>
            <ul>
                <li>①条件が参照する場所を指定します。自分フィールド上の状況によって左右されるので自分フィールドを選択します</li>
                <li>②条件が参照する場所のカード枚数を指定します。自分モンスターゾーンは５枚なので０から４枚なら空きスペースがあり召喚できます</li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input4.png`}></img></p>
            <p>入力が終わったら確定をクリックしてください</p>
            <h4>効果処理発動主体カード条件の設定</h4>
            <p>効果を発動する主体のカードは手札に存在する必要があるのでそのように入力します。またコストを必要としない召喚はレベル４以下である必要があるためそのようにも入力します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input6.png`}></img></p>
            <p>まずはTrigger Monsterの横の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger7.png`}></img></p>
            <p>上図のように入力します。以下のような意味があります</p>
            <ul>
                <li>①効果の主体となるカードが手札にある必要があることを示します
                    </li>
                    <li>②効果の主体のカードのレベルが４以下である必要があることをしめします</li>
                    </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input8.png`}></img></p>
            <p>入力が終わったら確定をクリックしてください</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input9.png`}></img></p>
            <p>入力を続けます。上図のように入力してください。</p>
            <ul>
                <li>①召喚はカードに紐づける効果のためチェックをいれません。</li>
                <li>②効果を識別する名前です</li>
                <li>③画面上で効果を選択する際に表示される文字列です</li>
                <li>④効果の種類を表します。2(召喚)を選択します</li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger10.png`}></img></p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input11.png`}></img></p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input12.png`}></img></p>
            <p>入力を続けます。特にコピー等はせず同一チェーン上に二回出現することはないのでそれらにはチェックを入れません。以下要点のみを解説します</p>
            <ul>
                <li>
                ①召喚を無効にするカード等を追加する可能性があるためチェックを入れておきます</li>
                <li>
                ②召喚は１ターンに一度しかできません。召喚をするTriggerは複数作る予定ですので、Once per turn groupに１を入れます。これを利用するとOnce per turn groupに１が入力されたTriggerは全体で１ターンに一度しか発動できないようになります</li>
                <li>③遊戯王にならって召喚はチェーンブロックを作らないことにします</li>
                </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input13.png`}></img></p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger14.png`}></img></p>
            <p>全て入力し終えたら１回Saveをクリックします。そしてもう一度召喚をクリックします</p>
            <h4>召喚のクリーチャーへの紐づけ</h4>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger15.png`}></img></p>
            <p>画面を下までスクロールしてinsertをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger16.png`}></img></p>
            <p>今回紐づけるのはクリーチャーです。レベルも指定しますがそれはTrigger Monsterのほうで指定したので特に設定せずにsubmitをクリックします</p>
            <h3>レベル5以上レベル６以下のクリーチャー召喚の作成</h3>
            <p>レベル５以上のクリーチャーの召喚には手札コストを必要とするのでコストを作成していきます</p>
            <h4>コストの指定</h4>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost.png`}></img></p>
            <p>管理画面でCostを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_add.png`}></img></p>
            <p>Add Costをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_input.png`}></img></p>
            <p>コストにする手札コストを選びます。まずCost　Valを選択自分を選択します。（効果主体のプレイヤーがコストを選択することを示します）</p>
            <p>Costの右の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_input2.png`}></img></p>
            <p>上図のように入力します。以下のような意味があります</p>
            <ul>
                <li>
                ①コストを自分手札から選択します
                </li>
                <li>
                ②コストの指定から除外するものを表します。今回は効果の主体にするカードは選択できないため~triggerを指定します（~はコストで指定することを表すカード変数です）
                </li>
                <li>③コストの指定を格納するカード変数を指定します。コストなので~を前につけて指定します</li>
                </ul>
            
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_input3.png`}></img></p>
                <p>入力できたらCost Nameに~trigger以外の手札を~costに格納を入力してSaveを押します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_input4.png`}></img></p>
            <p>次にAdd Costをクリックし上図のようにCost Valを移動を選択し、Costの右の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_input5.png`}></img></p>
            <p>上図のように入力します。以下のような意味があります</p>
            <ul>
                <li>①~costに格納されたカードが移動することを示します</li>
                <li>②カードがそのカードの元々の持ち主の墓地へ移動することを示します。相手カードが自分の手札に混ざる可能性を考えています。</li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_input6.png`}></img></p>
                <p>入力できたらCost Nameに~costを持ち主の墓地へ移動を入力してSaveを押します</p>
            <h4>コストのラッパーを作成する</h4>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_wrapper.png`}></img></p>
            <p>管理画面からCost Wrapperを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_wrapper_add.png`}></img></p>
            <p>Add Cost Wrapperをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_wrapper_input.png`}></img></p>
            <p>Cost Wrapperを上図のように入力します。以下のような意味があります</p>
            <ul>
                <li>①ラッパーで実行されるコストを表します</li>
                <li>②ラッパーの処理の種類を表します。ここでは２（召喚）を指定しています</li>
                <li>③コストの名前を表します</li>
                <li>④コストが実行されたときのログを示します(@)は移動するカードの名前を表します</li>
            </ul>
            <p>続けてwrapperをもう一つ作ります。Add Cost Wrapperをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_wrapper_input2.png`}></img></p>
            <p>上図のように入力します。召喚するコストを選択する効果を作ります。CostNextは選択したのちに選択したコストを先ほどしたWrapperで墓地へ送ることを示します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_wrapper_input3.png`}></img></p>
            <p>さらにスクロールして、上図のように入力します。Promptはカード選択するときに表示される文字列をsentenceはそれぞれのカードに表示される文字列を示します。入力が終わったらSaveをクリックします
            </p>
            <p>以上でレベル５，６の召喚のコストは作成できました。次はTriggerを作成します</p>
            <h4>Triggerの作成</h4>
            <p>管理画面でTriggerを選択し、召喚を選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger17.png`}></img></p>
            <p>画面をスクロールして、Save as Newをクリックします。そのままTriggerを変更していきます </p>
            <p>レベル５，６の召喚はレベル４以下の召喚と基本的な処理は同じですが違うところがあるのでそれを変更していきます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger18.png`}></img></p>
            <p>まずTrigger Conditionの右の追加をクリックし、１の右の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger19.png`}></img></p>
            <p>続けて上図のように入力します。下図のような意味があります</p>
            <ul>
                <li>①もともとの召喚にあった自分フィールド上にカードが４枚以下の条件も必要なのでかつを選択します </li>
                <li>②手札コストが必要なので手札枚数を参照する必要があります</li>
                <li>③④手札は効果を使うカードと手札コストの２枚以上必要なのでminに２maxを大きな値にします。</li>
                <li>⑤入力が終わったら確定をクリックします</li>
                </ul>
            <p>Trigger Monsterも変更します。追加をクリックします    </p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger20.png`}></img></p>
            <p>レベルの指定を５，６にする必要があるため上図のように入力します</p>
            <ul>
                <li>①は変更しません</li>
                <li>⑦の追加をクリックして条件を増やします</li>
                <li>②③レベルが５以上であることをしめします</li>
                <li>⑤⑥でレベルが６以下であることをしめします </li>
                <li>②③と⑤⑥を両方満たしている必要があるためかつを選択します</li>
            </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger20.png`}></img></p>
            <p>最後にTrigger Nameをレベル５，６召喚　Trigger Costに召喚コストを１枚選択を選択します</p>
            <p>あとは下までスクロールしてSaveをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger22.png`}></img></p>
            <p>その後、レベル５，６を召喚をクリックします。そして下までスクロールしてinsertをクリックします</p>
            <p>もう一度クリーチャーを選択し、submitをクリックします</p>
            <h3>レベル7以上のクリーチャー召喚の作成</h3>
            <p>レベル7以上のクリーチャーの召喚には手札コストが２枚必要です。あまりレベル５，６と変わらないので今までのコストをコピーして作っていきます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_edit.png`}></img></p>
            <p>管理画面でCostを選択し、~trigger以外の手札を~costに格納を選択します  </p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_input7.png`}></img></p>
            <p>管理画面でスクロールして、Save as Newを選択します     </p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_input8.png`}></img></p>
            <p>Costの右の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_input9.png`}></img></p>
            <p>レベル７以上ではコストが２枚になるのでモンスターの数のminとmaxに２を指定します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_input10.png`}></img></p>
            <p>Cost Nameに~trigger以外の手札2枚を~costに格納を入力してSaveをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_wrapper_edit.png`}></img></p>
            <p>次にCost Wrapperです。管理画面でCost Wrapperを選択して、召喚コスト１枚選択を選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_input4.png`}></img></p>
            <p>画面をスクロールしてSave as newをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_cost_input5.png`}></img></p>
            <p>Costを~trigger以外の手札２枚を選択にし、Cost Nameを召喚コストを２枚選択にし、Saveします</p>
            <h4>Triggerの作成</h4>
            <p>Triggerもほとんど同じです。レベル５，６召喚をコピーしてしまいましょう</p>
            <p>管理画面でTriggerを選択レベル５，６召喚をクリックして画面下部にスクロールしてSave as newをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input23.png`}></img></p>
            <p>そのままスクロールしてTrigger Nameをレベル７以上を召喚にそしてTrigger Conditionの右の追加をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input24.png`}></img></p>
            <p>上図のように２をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input25.png`}></img></p>
            <p>上図のようにモンスターの数のminを３に変更し、確定をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_summon_trigger_input26.png`}></img></p>
            <p>Trigger Costを召喚コストを２枚選択を選択します。</p>
            <p>画面を下にスクロールし、Saveをクリックし、レベル７以上召喚を再度選択し、insertをクリックし、クリーチャーを選択しsubmitをクリックします</p>
            <p>以上で召喚の処理は作成終了です。お疲れさまでした。</p>






            </div>
            )
}
