export const AsElement =  ()=>{
    return (
        <div className="container">
            <a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>を表します。下記はカード変数にkokaを選択しています
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_as.png`}></img></p>
            <p>カード変数は効果の種類により大きく意味が異なります。条件ではカード変数の中で条件をみたすものがモンスターの数で設定された値に収まるかを表します</p>
            <p>選択の場合はカードを選んだ際に保存される<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>を表します</p>
            <p>移動やモンスター変数変動の場合は対象の<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>を表します</p>
        </div>
    )
}
