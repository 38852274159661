import React from 'react';
import './index.css';
import {DeckElement} from './deck_element';
import {DeckExample} from './deck_example';

export const Deck =  ()=>{
  return (
  <React.Fragment>
    <DeckElement />
    <DeckExample />
  </React.Fragment>
  )
};
