import React from 'react';
import './index.css';
import {DefaultDeckGroupElement} from './default_deck_group_element';
import {DefaultDeckGroupExample} from './default_deck_group_example';

export const DefaultDeckGroup =  ()=>{
  return (
  <React.Fragment>
    <DefaultDeckGroupElement />
    <DefaultDeckGroupExample />
  </React.Fragment>
  )
};
