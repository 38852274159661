export const MonsterVariableExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            Monster Variableはカードの変数のためのテーブルです   
            <p>
            <img src={`${process.env.PUBLIC_URL}/monster_variable_example.png`}></img>
            </p>
            <p>この例ではカードの変数として攻撃力を、その値が数値をとることを表しています</p>
            
        </div>
    )
}
