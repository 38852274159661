export const ChainCondition =  ()=>{
    return (
        <div className="container">
            <h2>チェーン条件</h2>
           <p>効果処理の前のtriggerの種類によって次の効果処理を決めます</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/chain_condition_input.png`}></img></p>
           <p>Monster Effectの右の追加をクリックします。上手のよう①が前の処理の効果のtrigger②が次の効果、③が次のパックです。②と③が両方入っていた場合は③が優先されます
           </p>
           <p><img src={`${process.env.PUBLIC_URL}/chain_condition.png`}></img></p>
           <p>Monster Effectに図のように書かれれば成功です。この例では前の効果が1によって引き起こされていた場合1に進みます
           </p>
        </div>
    )
}
