
export const Table =  ()=>{
    return (
        <div className="container">
            <ul className="list-group list-group-numbered">
                <li><a href="/tcgcreatoreternalbetaver2/background">Background</a>ゲームで使う背景画像を指定します</li>
                <li><a href="/tcgcreatoreternalbetaver2/config">Config</a>ゲームの設定項目を編集します</li>
                <li><a href="/tcgcreatoreternalbetaver2/constraint">Constraint</a>デッキの構築に制限をかけます</li>
                <li><a href="/tcgcreatoreternalbetaver2/cost_wrapper">Cost Wrapper</a>コストの処理をラップするテーブルです</li>
                <li><a href="/tcgcreatoreternalbetaver2/cost">Cost</a>コストの処理を書くテーブルです</li>
                <li><a href="/tcgcreatoreternalbetaver2/deck">Deck</a>デッキの種類を作ります  </li>
                <li><a href="/tcgcreatoreternalbetaver2/default_deck_choice">Default Deck Choice</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/default_deck_group">Default Deck Group</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/default_deck">Default Deck</a>構築済みデッキを構築します</li>
                <li><a href="/tcgcreatoreternalbetaver2/duel_deck">Duel Deck</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/duel_grave">Duel Grave</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/duel_hand">Duel Hand</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/duel">Duel</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/end_chain_effect">End Chain Effect</a>全ての処理が終わった後に発動する処理を示します</li>
                <li><a href="/tcgcreatoreternalbetaver2/enemy_deck_choice">Enemy Deck Choice</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/enemy_deck_group">Enemy Deck Group</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/enemy_deck">Enemy Deck</a>NPCのデッキを作成します</li>
                <li><a href="/tcgcreatoreternalbetaver2/eternal_effect">Eternal Effect</a>永続操作を作ります</li>
                <li><a href="/tcgcreatoreternalbetaver2/eternal_trigger">Eternal Trigger</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/eternal_wrapper">Eternal Wrapper</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/field_kind">Field Kind</a>フィールドの種類を作成します</li>
                <li><a href="/tcgcreatoreternalbetaver2/field_size">Field Size</a>フィールドの大きさを作ります</li>
                <li><a href="/tcgcreatoreternalbetaver2/field">Field</a>フィールドを作成します</li>
                <li><a href="/tcgcreatoreternalbetaver2/fusion">Fusion</a>カードを合体させる組み合わせを作ります</li>
                <li><a href="/tcgcreatoreternalbetaver2/global_variable">Global Variable</a>グローバル変数を作成します</li>
                <li><a href="/tcgcreatoreternalbetaver2/grave">Grave</a>墓地の種類を作成します</li>
                <li><a href="/tcgcreatoreternalbetaver2/hand">Hand</a>手札の種類を作成します</li>
                <li><a href="/tcgcreatoreternalbetaver2/lock">Lock</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/monster_effect_kind">Monster Effect Kind</a>カード効果の種類を作成します</li>
                <li><a href="/tcgcreatoreternalbetaver2/monster_effect_wrapper">Monster Effect Wrapper</a>カード効果のラッパーを作成します</li>
                <li><a href="/tcgcreatoreternalbetaver2/monster_effect">Monster Effect</a>カード効果処理を作成します</li>
                <li><a href="/tcgcreatoreternalbetaver2/monster_variable_kind">Monster Variable Kind</a>カードパラメータの種類を作ります</li>
                <li><a href="/tcgcreatoreternalbetaver2/monster_variable">Monster Variable</a>カードパラメータを作成します</li>
                <li><a href="/tcgcreatoreternalbetaver2/monster">Monster</a>カードを作成します</li>
                <li><a href="/tcgcreatoreternalbetaver2/pac_cost_wrapper">Pac Cost Wrapper</a>コストをひとまとめにしたPac Costのラッパーを作ります</li>
                <li><a href="/tcgcreatoreternalbetaver2/pac_cost">Pac Cost</a>コスト処理をひとまとめにしたものです</li>
                <li><a href="/tcgcreatoreternalbetaver2/pac_wrapper">Pac Wrapper</a>効果処理ひとまとめにしたPacのラッパーです</li>
                <li><a href="/tcgcreatoreternalbetaver2/pac">Pac</a>カード処理をひとまとめにしたものです</li>
                <li><a href="/tcgcreatoreternalbetaver2/phase">Phase</a>フェイズを作ります</li>
                <li><a href="/tcgcreatoreternalbetaver2/special_card">Special Card</a>デッキ構築の際に構築の制限をなくす特殊なカードを決めます</li>
                <li><a href="/tcgcreatoreternalbetaver2/timing">Timing</a>攻撃宣言時や召喚時などのタイミングを作成します</li>
                <li><a href="/tcgcreatoreternalbetaver2/trigger_timing_change_val">Trigger Timing Change Val</a>グローバル変数が変動した時に発動する効果を作ります</li>
                <li><a href="/tcgcreatoreternalbetaver2/trigger_timing_monster_change_val">Trigger Timing Monster Change Val</a>カードパラメータが変動した時の効果を作ります</li>
                <li><a href="/tcgcreatoreternalbetaver2/trigger_timing_not_effected">Trigger Timing Not Effected</a>効果を受けなかった時に発動する効果を作ります</li>
                <li><a href="/tcgcreatoreternalbetaver2/trigger_timing_relation">Trigger Timing Relation</a>カードが紐づけられた時または紐づけが取れたときの効果を作ります</li>
                <li><a href="/tcgcreatoreternalbetaver2/trigger_timing">Trigger Timing</a>カードが移動したときの効果を作ります</li>
                <li><a href="/tcgcreatoreternalbetaver2/trigger">Trigger</a>効果のコストや効果処理、発動条件など様々な情報を作ります</li>
                <li><a href="/tcgcreatoreternalbetaver2/under_direction">Under Direction</a>カードがフィールドから離れたとき下のカードの行く先を作ります</li>
                <li><a href="/tcgcreatoreternalbetaver2/user_deck_choice">User Deck Choice</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/user_deck_group">User Deck Group</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/user_deck">User Deck</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/user_point">User Point</a>触らないでください</li>
                <li><a href="/tcgcreatoreternalbetaver2/virtual_variable">Virtual Variable</a>仮想グローバル変数を作成します</li>
                </ul>
                </div>       
    )
}
