export const MonsterEffectPage =  ()=>{
    return (
        <div className="container">
            <h2>効果の種類</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/monster_effect_condition">条件</a></th><td>条件により次の効果処理が分岐します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/alt_val">軽減条件</a></th><td>変数を軽減するかの条件で次の効果処理が分岐します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/chain_condition">チェーン条件</a></th><td>効果処理の前のtriggerの種類によって次の効果処理が分岐します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/phase_condition">タイミングフェイズ条件</a></th><td>現在のフェイズ及びタイミングにより次の効果処理が分岐します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/invalid">無効</a></th><td>一つ前にチェーンを組まれた効果を無効にします</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/move">移動</a></th><td>カードを移動します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/fusion_choose">融合選択</a></th><td>融合するカードを選択します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/fusion_material">融合素材</a></th><td>融合の素材にするカードを選択します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/fusion_unique_effect">融合固有効果</a></th><td>融合召喚時に固有効果を使います</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/move_under">移動under</a></th><td>カードの下にあるカードを移動します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/move_relation_card">移動リレーション</a></th><td>カードに紐づいているカードを移動します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/move_to_under">下に重ねる</a></th><td>フィールドのカードの下にカードを重ねます</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/move_to_top">上に重ねる</a></th><td>フィールドのカードの上にカードを重ねます</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/move_simple">シンプル移動</a></th><td>カードを移動します（非推奨）</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/create_token">カード生成</a></th><td>フィールドにカードを生成します（トークン等に有効です  ）</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/delete_card">カード削除</a></th><td>カードをゲームから削除します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_global_variable">変数変動</a></th><td>グローバル変数を変更します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_global_variable_order">変数変動順番</a></th><td>非推奨です</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_global_variable_order2">変数変動順番相手</a></th><td>非推奨です</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/alt_val_change">変数軽減</a></th><td>変数が変動する際軽減します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/acc_val_flush">変数変動flush</a></th><td>変数が変動する際、accumlate_flagでためていた場合一斉に変動します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_global_variable_by_monster">変数変動byモンスター</a></th><td>グローバル変数をカードによって変動させます（非推奨です）</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_global_variable_multiple">変数変動複数 </a></th><td>グローバル変数を複数変更します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_global_variable_show">変数表示変更</a></th><td>グローバル変数を画面に表示するかを変更します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_monster_variable">モンスター変数変動</a></th><td>カードのパラメータを変更します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_monster_variable_relation">モンスター変数変動リレーション</a></th><td>カードに紐づけられたカードのカードパラメータを変更します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_monster_relation">モンスターリレーション</a></th><td>カードにカードを紐づけます</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_monster_relation_change">モンスターリレーション付け替え</a></th><td>カードの紐づけを別のカードに紐づけかえます</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_monster_relation_clear">モンスターリレーションクリア</a></th><td>カードの紐づけを取り消します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_monster_relation_all_clear">モンスターリレーション全部クリア</a></th><td>カードの紐づけを指定する場所から全て取り消します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/copy_monster">モンスターコピー</a></th><td>カードの効果を得ます</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/copy_monster_specify">モンスターコピーモンスター指定</a></th><td>カードの効果をコピーするカードを指定してコピーします</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/copy_effect">効果コピー</a></th><td>カードの効果をコピーして使用します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/copy_effect2">効果コピー2</a></th><td>現在は使用できません</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/copy_effect_cost">効果コピー(コスト)</a></th><td>効果をコピーします（Costで指定しMonsterEffectはそれで選択された効果が使用されます）</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/choose">選択</a></th><td>カードを選んで<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/choose_as">選択自分as</a></th><td>カードを<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>の中から選択してそれを別の<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/choose_as_under">選択自分as_under</a></th><td>カードを<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に属するカードの下のカードの中から選択してそれを別の<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/choose_as_rewrite">選択リライト自分</a></th><td>現在は使えません</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/choose_opponent">選択相手</a></th><td>効果の相手がカードを選んで<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/choose_clear">選択クリア</a></th><td><a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納されている情報を削除します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/choose_reload">選択リロード</a></th><td><a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納されている情報を最新にします</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/choose_reload2">選択リロード2</a></th><td><a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納されている情報を最新にします</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/phase_choose">フェイズ移動</a></th><td>フェイズを移動します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/turn_end">ターンエンド</a></th><td>ターンを終了します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/shuffle">シャッフル</a></th><td>カードをシャッフルします</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/clear">フラグクリア</a></th><td>カードについているフラグをクリアします</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/raise">レイズ</a></th><td>カードに紐づいていいない効果を発動します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_appointment">優先権移行</a></th><td>優先権を移行します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/next_timing">タイミング次に移動</a></th><td>タイミングにしめされている次のタイミングに移行します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_timing">タイミング移動</a></th><td>タイミングを移行します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/raise_eternal">永続レイズ</a></th><td>現在は使えません</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/coin">コイントス</a></th><td>50%の確率で次の効果処理が決まります</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/random">ランダム分岐</a></th><td>等確率で次の効果が決まります</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/yes_or_no">Yes Or No</a></th><td>効果の主体のプレイヤーに同意不同意を表示し、その結果に基づいて次の処理を決めます</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/yes_or_no_opponent">Yes Or No相手</a></th><td>効果の主体でないプレイヤーに同意不同意を表示し、その結果に基づいて次の処理を決めます</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/choose_multiple">分岐複数</a></th><td>効果の主体のプレイヤーに選択を複数しめし、それにより次の効果処理を決めます</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/choose_multiple_opponent">分岐複数相手</a></th><td>効果の主体でないのプレイヤーに選択を複数しめし、それにより次の効果処理を決めます</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/move_to_timing_variable">タイミング変数移動</a></th><td><a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>をタイミングの<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に移動します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/chain_variable">チェーン変数設定</a></th><td>効果処理中のみに有効なglobal変数を設定します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/chain_variable_opponent">チェーン変数設定相手</a></th><td>効果処理中のみに有効なglobal変数を相手が設定します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/global_variable_change">グローバル変数設定</a></th><td>global変数を設定します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/global_variable_change_opponent">グローバル変数設定相手</a></th><td>global変数を相手が設定します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/relation_multiple">多対多</a></th><td>多対多のカード紐づけをプレイヤーが設定します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/relation_multiple">多対多相手</a></th><td>多対多のカード紐づけを相手プレイヤーが設定します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/change_appoint">優先権操作</a></th><td>優先権をmonster effectに書いてある情報をもとに操作します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/force">強制発現</a></th><td>現在非推奨です</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/mute">ミュート</a></th><td>一時的にコストのログをでなくします</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/music">音楽鳴らす</a></th><td>音楽を鳴らします</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/sound">効果音鳴らす</a></th><td>効果音を鳴らします</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/cancel">キャンセル</a></th><td>コストの時のみ有効です。効果をキャンセルします</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/win">勝利</a></th><td>効果処理のユーザーが勝利します</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/lose">敗北</a></th><td>効果処理のユーザーがします</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/draw">引き分け</a></th><td>ゲームが引き分けとなります</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/each">Each</a></th><td><a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に入っているカードを一つずつ取り出しそれぞれに処理を施します </td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/do_cost">コスト実行</a></th><td>コストの処理を行います</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/do_eternal">永続リセット</a></th><td>処理の途中に永続効果をリセットします</td></tr>
                    <tr><th><a href="/tcgcreatoreternalbetaver2/comment">コメント</a></th><td>処理は行われず、logだけが残ります</td></tr>
                </tbody>
            </table>
        </div>
    )
}
