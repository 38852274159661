export const PlaceElement =  ()=>{
    return (
        <div className="container">
            <p>場所はデッキ、墓地、手札フィールドなどの場所を表します（プレイヤ―は現在使われておりません。</p>
            <p>以下の例では場所として自分のデッキを表しています。</p>
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_place1.png`}></img></p>
            追加を押すと図のように複数の場所を作ることができます。この例では自分デッキまたは自分フィールドをさしています（かつは使わないでください、またはを使ってください）
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_place2.png`}></img></p>
        </div>
    )
}
