
export const TutorialGlobalVariable =  ()=>{
    return(
        <div className="container">
            <h2>グローバル変数の作成</h2>
            <p>ここではグローバル変数の値を作成していきます。グローバル変数とはライフや召喚権など、カードとは紐づかないゲームのパラメーターを表します</p>
            <img src={`${process.env.PUBLIC_URL}/tutorial_global_variable_choose.png`}></img>
            <p>では上図のようにGlobal Variablesを選択してグローバル変数を作っていきましょう</p>
            <h3>ライフの作成</h3>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_global_variable_add.png`}/></p>
            <p>上図のようにAdd Global Variablesを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_global_variable_input.png`}/></p>
            <p>上図の様に入力します。それぞれ以下のような意味があります</p>
            <ul>
                <li>①グローバル変数の名前です。左上に表示されるほか、管理画面で特定するのにも使用されます</li>
                <li>②グローバル変数の表示順序を決めます。大きな値のものほど上にン表示されます</li>
                <li>③グローバル変数を左上に表示するかを決めます。このフェイズは表示しません。 </li>
                <li>④ゲーム開始時のグローバル変数の値を決めます。この例では初期ライフは５０００です</li>
                <li>⑤グローバル変数が個々のプレイヤーに属するか、共通にするかを決めます。ライフは両者にあるので、「特になし」にします</li>
                <li>⑥作成したグローバル変数を保存します </li>
                </ul>

        </div>
    )
}
