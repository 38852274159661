export const Document = () =>{
    return (
        <div className="container">
            <ul>
                <li><h3><a href="/tcgcreatoreternalbetaver2/dialogbox" >ダイアログボックス</a></h3>処理の条件や効果を作るためのダイアログボックスです</li>
                <li><h3><a href="/tcgcreatoreternalbetaver2/equation_editor">数式エディタ</a></h3>処理で計算に使う数式を使うためのエディタの解説です</li>
                <li><h3><a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a></h3>処理で使うカードを保持する変数についての解説です</li>
                <li><h3><a href="/tcgcreatoreternalbetaver2/monster_effect_page">カード処理種類</a></h3>カード効果の種類です</li>
                <li><h3><a href="/tcgcreatoreternalbetaver2/log">ログ</a></h3>カードの効果のログの書き方です</li>
            </ul>
        </div>
    )
}
