export const FusionMaterial =  ()=>{
    return (
        <div className="container">
            <h2>融合素材</h2>
           <p>融合の素材にするカードを選択します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/fusion_material.png`}></img></p>
           <p>上の例では自分の手札(①)または(②)自分の墓地（③)からmaterial(④)という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に融合の素材とするカードを格納することを示しています</p>
        </div>
    )
}
