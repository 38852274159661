export const Coin = () => {
    return (
        <div className="container">
            <h2>コイントス</h2>
            <p>50%の確率で次の効果処理が決まります</p>
            <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/coin.png`}></img></p>
            <p>５０％の確率でMonster EffectWrapperのMonster Nextに５０％の確率でMonster Effect　Next2に進みます 

            </p>
        </div>
    )
}
