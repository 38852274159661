export const ChangeMonsterVariable = () => {
    return (
        <div className="container">
            <h2>モンスター変数変動</h2>
           <p>カードのパラメータを変更します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/change_monster_variable.png`}></img></p>
           <p>この例ではtrigger(①)という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に攻撃力(②)をどちらかのターンの開始が１回(③、つまりターンエンド時まで)までモンスター効果の効果種別(④)で5アップ(⑤)することを表します</p>
        </div>
    )
}
