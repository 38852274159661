import React from 'react';
import {ConstraintElement} from './constraint_element';
import {ConstraintExample} from './constraint_example';

export const Constraint =  ()=>{
  return (
  <React.Fragment>
    <ConstraintElement />
    <ConstraintExample />
  </React.Fragment>
  )
};
