export const RelationMultipleOpponent = () => {
    return (
        <div className="container">
            <h2>多対多相手</h2>
            <p>多対多のカード紐づけを相手プレイヤーが設定します</p>
            <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/relation_multiple_opponent1.png`}></img></p>
           <p><img src={`${process.env.PUBLIC_URL}/relation_multiple_opponent2.png`}></img></p>
            <p>この例では紐づけ元(①)で%attacker(⑥)に重ね掛なしで(⑤)でbattle(②)という名でモンスター効果(③)でリレーションを受ける(④)で紐づけ先(①)自分（相手にっとって）フィールド(②)でcannotdefendが０のカードとの紐づけをプレイヤーが決めます
            </p>
        </div>
    )
}
