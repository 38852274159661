import ReactDOM from 'react-dom/client';
import React from 'react';
import logo_img from './tcgcreator_logo.png'
export const Logo =  ()=>{
    return (
        <header>
            <div className="container text-center">
                <h1><a href="/tcgcreatoreternalbetaver2"><img className="mw-100" src={logo_img} alt="tcgcreator eternal beta"></img></a></h1>
            </div>
        </header>
    )
}
