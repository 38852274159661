import ReactDOM from 'react-dom/client';
import React from 'react';

export const NavInstall =  ()=>{
    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/tcgcreatoreternalbetaver2/install" id="navbarInstallDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               インストール
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarInstallDropdown">
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/download">ダウンロード</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/install">インストール</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/launch">インストール後の起動方法</a>
                </div>
        </li>
    )
}
