import ReactDOM from 'react-dom/client';
import React from 'react';
export const PacCostWrapperElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Cost Next</th><td>次のコストです。（Pacの中のCost Wrapperの次のコストがない場合このCost Wrapperが選ばれます)</td></tr>
                    <tr><th>Cost Next2</th><td>次のコストです。(Pacの中のCost WrapperのCost Next2がない場合このCost Wrapperが選ばれます</td></tr>
                    <tr><th>Pac Next</th><td>次のまとめられたコストです。Cost Nextが選ばれていない場合こちらに移動します</td></tr>
                    <tr><th>Pac Next2</th><td>次のまとめられたコストです。条件等で条件を満たしておらずかつCost Next2が空の場合こちらが処理されます</td></tr>
                    <tr><th>Monster Effect Kind</th><td>指定された場合Pacないのコストの効果は全てのこの効果として上書きされます</td></tr>
                    <tr><th>Monster Effect Kind Rel</th><td>指定された場合Pacないのコストの紐づけ効果は全てのこの効果として上書きされます</td></tr>
                    <tr><th>Pac Cost</th><td>このPac WrapperでラップされるPac Cost(いくつかのCost Wrapperをひとまとめにしたもの)をしていします</td></tr>
                    <tr><th>Pac Cost Name</th><td>このラッパーの名前です。記号は名前に使わないでください</td></tr>
                    <tr><th>Log</th><td>このコストが実行された場合に残されるLogです。<a href="/tcgcreatoreternalbetaver2/explian_log">こちらで詳しく解説します</a></td></tr>
                </tbody>
            </table>
        </div>
    )
}
