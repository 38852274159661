import React from 'react';
import {SpecialCardElement} from './special_card_element';
import {SpecialCardExample} from './special_card_example';

export const SpecialCard =  ()=>{
  return (
  <React.Fragment>
    <SpecialCardElement />
    <SpecialCardExample />
  </React.Fragment>
  )
};
