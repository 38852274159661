import React from 'react';
import {PhaseElement} from './phase_element';
import {PhaseExample} from './phase_example';

export const Phase =  ()=>{
  return (
  <React.Fragment>
    <PhaseElement />
    <PhaseExample />
  </React.Fragment>
  )
};
