export const TutorialMonsterVariable =  ()=>{
    return(
        <div className="container">
            <h2>カードパラメータの作成</h2>
            <h3>カードパラメータの種類を作成</h3>
            <p>トレーディングカードゲームのカードパラメータの種類としては数値（これはすでに作ってあります）が普通ですが、種族など文字情報のものもあります。
            ここでは種族と属性と種類を作っていきます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_kind_choose.png`}></img></p>
            <p>上図のようにMonster Variable Kindを選択します </p>
            <h4>属性の作成</h4>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_kind_add.png`}></img></p>
            <p>上図のようにAdd Monster　Variable Kindを選択します</p>
            <p>属性は火、風、土、水の４属性を作ります。</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_kind_input.png`}></img></p>
            <p>上図の様に入力します。それぞれ以下のような意味があります</p>
            <ul>
                <li>①カードパラメータの取りうる値を示します。取りうる値を|で区切り列挙します</li>
                <li>②カードパラメータの種類の名前を表します</li>
                <li>③カードパラメータの種類を保存します</li>
                </ul>
            <h4>種族の作成</h4>
            <p>種族は人間、ドラゴン、魔法使いを作っていきます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_kind_input2.png`}></img></p>
            <p>上図の様に入力します</p>
            <h4>種類の作成</h4>
            <p>種類はクリーチャー、魔法、罠を作っていきます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_kind_input3.png`}></img></p>
            <p>上図の様に入力します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_kind_result.png`}></img></p>
            <p>上図の様になればOKです</p>
            <h3>カードパラメータを作成</h3>
            <p>カードのパラメータとして、攻撃力、レベル、ライフ、種類、種族、属性と作っていきたいと思います</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_choose.png`}></img></p>
            <p>上図のようにMonster Variablessを選択します </p>
            <h4>攻撃力の作成</h4>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_add.png`}></img></p>
            <p>上図のようにAdd Monster　Variablesを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_input.png`}/></p>
            <p>上図の様に入力します。それぞれ以下のような意味があります</p>
            <ul>
                <li>①カードパラメータの名前です。攻撃力というパラメータなので「攻撃力」と入力します</li>
                <li>② ①と同じものを入力します</li>
                <li>③ カードパラメーターの種類を入力します。攻撃力は数値をとるので「数値」を選択します</li>
                <li>④ デッキ構築画面でパラメータを表示するかを決めます。表示する場合は１を選択します</li>
                <li>⑤ デッキ構築画面でパラメータの表示する順番を決めます。大きなものから表示されます</li>
                <li>⑥ すでにカードが作られていた場合に、初期値を決めます。まだカードを作っていないので何を入れてもかまいません</li>
                </ul>
            <h4>ライフの作成</h4>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_input2.png`}/></p>
            <p>上図の様に入力します。基本的に攻撃力と同じです</p>
            <h4>レベルの作成</h4>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_input5.png`}/></p>
            <p>上図の様に入力します。基本的に攻撃力と同じです</p>
            <h4>属性の作成</h4>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_input3.png`}/></p>
            <p>上図の様に入力します。基本的に攻撃力と同じですがMonster Variable Kind　Idに属性を入力します。これにより属性のとる値を数値以外に指定できます</p>
            <h4>種族の作成</h4>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_input4.png`}/></p>
            <p>上図の様に入力します。Monster Variable Kind　Idに種族を入力します。</p>
            <h4>種類の作成</h4>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_input6.png`}/></p>
            <p>上図の様に入力します。Monster Variable Kind　Idに種類を入力します。</p>
            <p>これでカードパラメータの作成は終了です。下図のようになるはずです(順番は違います)</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_variable_result.png`}/></p>
        </div>
    )
    }
