import ReactDOM from 'react-dom/client';
import React from 'react';
export const DuelElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Id</th><td>行われているデュエルの部屋番号です</td></tr>
                    <tr><th>User Deck1</th><td>先に入ったユーザーのデッキです</td></tr>
                    <tr><th>User Deck2</th><td>後に入ったユーザーのデッキです</td></tr>
                    <tr><th>Default Deck1</th><td>先に入ったゲストのデッキです</td></tr>
                    <tr><th>Default Deck2</th><td>後に入ったゲストのデッキです</td></tr>
                    <tr><th>Ai Deck2</th><td>NPCのデッキです</td></tr>
                    <tr><th>Deck Choose Flag1</th><td>現在は使われていません。無視してください</td></tr>
                    <tr><th>Deck Choose Flag2</th><td>現在は使われていません。無視してください</td></tr>
                    <tr><th>Field</th><td>行われているデュエルのフィールドです  </td></tr>
                    <tr><th>Turn Count</th><td>行われているデュエルが何ターン目からを表しています  </td></tr>
                    <tr><th>Ask</th><td>行われているデュエルで選択をしているプレイヤーを表します</td></tr>
                    <tr><th>Ask2</th><td>行われているデュエルで効果を選択をしているプレイヤーを表します</td></tr>
                    <tr><th>Retrieve</th><td>現在効果処理解決中かを示します</td></tr>
                    <tr><th>Ask Kind</th><td>行われているデュエルで選択をしている効果の種類を表します</td></tr>
                    <tr><th>Asked</th><td>現在は使われていません。無視してください</td></tr>
                    <tr><th>Ask Det</th><td>現在選択中の効果の具体的な処理内容です</td></tr>
                    <tr><th>Answer</th><td>現在は使われていません。無視してください</td></tr>
                    <tr><th>User Turn</th><td>どちらのプレイヤーのターンかを示します</td></tr>
                    <tr><th>Phase</th><td>現在どのフェイズかを示します</td></tr>
                    <tr><th>Phase Whether1_1</th><td>どのフェイズでユーザーが割り込みできるかを示します</td></tr>
                    <tr><th>Phase Whether1_2</th><td>どのフェイズでユーザーが割り込みできるかを示します</td></tr>
                    <tr><th>Phase Whether2_1</th><td>どのフェイズでユーザーが割り込みできるかを示します</td></tr>
                    <tr><th>Phase Whether2_2</th><td>どのフェイズでユーザーが割り込みできるかを示します</td></tr>
                    <tr><th>Timing Whether1_1</th><td>どのタイミングでユーザーが割り込みできるかを示します</td></tr>
                    <tr><th>Timing Whether1_2</th><td>どのタイミングでユーザーが割り込みできるかを示します</td></tr>
                    <tr><th>Timing Whether2_1</th><td>どのタイミングでユーザーが割り込みできるかを示します</td></tr>
                    <tr><th>Timing Whether2_2</th><td>どのタイミングでユーザーが割り込みできるかを示します</td></tr>
                    <tr><th>Kind Whether1_1</th><td>どの効果種別にユーザーが割り込みできるかを示します</td></tr>
                    <tr><th>Kind Whether1_2</th><td>どの効果種別にユーザーが割り込みできるかを示します</td></tr>
                    <tr><th>Kind Whether2_1</th><td>どの効果種別にユーザーが割り込みできるかを示します</td></tr>
                    <tr><th>Kind Whether2_2</th><td>どの効果種別にユーザーが割り込みできるかを示します</td></tr>
                    <tr><th>Audio</th><td>現在流れている音楽を示します</td></tr>
                    <tr><th>Global Variable</th><td>デュエルで使われているライフなどの変数を表します</td></tr>
                    <tr><th>User1</th><td>初めに対戦部屋に入ったユーザーを示します</td></tr>
                    <tr><th>User2</th><td>二番目に対戦部屋に入ったユーザーを示します</td></tr>
                    <tr><th>Each</th><td>効果でeachをしているときの繰り返し回数を表します</td></tr>
                    <tr><th>Chain</th><td>効果解決中のチェーン数チェーンフラグがついてないものを含めて表します</td></tr>
                    <tr><th>Virtual Chain</th><td>効果解決中のチェーン数を表します</td></tr>
                    <tr><th>Chain Det</th><td>効果解決中の処理の詳細を示します</td></tr>
                    <tr><th>Chain User</th><td>効果解決中の処理のプレイヤーを示します</td></tr>
                    <tr><th>Chain Variable</th><td>効果解決中のみ有効な変数を示します</td></tr>
                    <tr><th>Mess</th><td>効果解決中のカード変数（trigger等)の情報を示します </td></tr>
                    <tr><th>Variable Mess</th><td>効果解決中の変数を示します</td></tr>
                    <tr><th>Multiple Mess</th><td>現在使われておりません</td></tr>
                    <tr><th>In Cost</th><td>コスト処理を解決中であることを示します</td></tr>
                    <tr><th>In Cost Cancel</th><td>コスト処理がキャンセル可能かを示します</td></tr>
                    <tr><th>In Trigger Waiting</th><td>効果処理を解決中かを示します</td></tr>
                    <tr><th>In Cost Force</th><td>現在は使われていません。無視してください</td></tr>
                    <tr><th>Cost Result</th><td>未解決のコスト処理の結果を保存します</td></tr>
                    <tr><th>Cost</th><td>処理中のコストのカード変数を示します</td></tr>
                    <tr><th>Cost Det</th><td>現在はどのコストを処理中かを示します</td></tr>
                    <tr><th>Cost User</th><td>処理中のコストのプレイヤーを示します</td></tr>
                    <tr><th>Trigger Waiting</th><td>処理待ちの効果の列を示します</td></tr>
                    <tr><th>Trigger Force</th><td>処理待ちの強制効果の列を示します</td></tr>
                    <tr><th>Appoint</th><td>優先権のプレイヤーを示します</td></tr>
                    <tr><th>Change Appoint Flag</th><td>優先権のプレイヤーが変更するかを示します</td></tr>
                    <tr><th>Timing</th><td>現在のタイミングを示します</td></tr>
                    <tr><th>Timing2</th><td>現在のタイミングを示します</td></tr>
                    <tr><th>Timing3</th><td>現在のタイミングを示します</td></tr>
                    <tr><th>Timing Fresh</th><td>現在のタイミングに入ったばかりかを示します</td></tr>
                    <tr><th>None</th><td>処理待ちの変更のために優先権を変更するかを示します</td></tr>
                    <tr><th>Timing Waiting</th><td>現在は使われていません。無視してください</td></tr>
                    <tr><th>Timing Mess</th><td>現在のタイミングのカード変数を示します</td></tr>
                    <tr><th>Current Priority</th><td>現在考慮中のtriggerの優先度を示します（優先度が高いものほど先に考慮されます)</td></tr>
                    <tr><th>Pac</th><td>現在は使われていません。無視してください</td></tr>
                    <tr><th>In Pac</th><td>現在処理中のPacを示します</td></tr>
                    <tr><th>In Pac Cost</th><td>現在処理中のPac Costを示します</td></tr>
                    <tr><th>No Invoke Eternal Effect</th><td>現在有効の永続効果を示します</td></tr>
                    <tr><th>Invoke Invalid Eternal Effect</th><td>現在有効の永続効果を示します</td></tr>
                    <tr><th>No Choose Eternal Effect</th><td>現在有効の永続効果を示します</td></tr>
                    <tr><th>No eternal Eternal Effect</th><td>現在有効の永続効果を示します</td></tr>
                    <tr><th>Not effected Eternal Effect</th><td>現在有効の永続効果を示します</td></tr>
                    <tr><th>Change Val Eternal Effect</th><td>現在有効の永続効果を示します</td></tr>
                    <tr><th>Sound Effect1</th><td>プレイヤー１に鳴らす効果音を示します</td></tr>
                    <tr><th>Sound Effect2</th><td>プレイヤー2に鳴らす効果音を示します</td></tr>
                    <tr><th>Trigger Log</th><td>Triggerで書かれるログを表します</td></tr>
                    <tr><th>Log</th><td>ログを表します</td></tr>
                    <tr><th>Current Log</th><td>現在処理中のログを表します</td></tr>
                    <tr><th>Message Log</th><td>プレイヤーのチャットのログを表します</td></tr>
                    <tr><th>Log Turn</th><td>現在ターンのログを表します</td></tr>
                    <tr><th>Cost Log</th><td>コストで発生したログを示します</td></tr>
                    <tr><th>Duel Id</th><td>デュエルに紐づ得られた一意の値を示します</td></tr>
                    <tr><th>Winner </th><td>人間同士の対戦の時の勝者を示します</td></tr>
                    <tr><th>Winner Ai</th><td>人間とＮＰＣの対戦の時の勝者を示します</td></tr>
                    <tr><th>In Eternal</th><td>Trigger以外で処理中かを示します</td></tr>
                    <tr><th>In Copy</th><td>現在処理をコピー中かを示します</td></tr>
                    <tr><th>Eternal Det</th><td>Trigger以外で処理中の効果を示します</td></tr>
                    <tr><th>In Pac Eternal</th><td>Trigger以外で処理中のpac効果を示します</td></tr>
                    <tr><th>Eternal Mess</th><td>現在は使われていません。無視してください</td></tr>
                    <tr><th>Eternal User</th><td>現在は使われていません。無視してください</td></tr>
                    <tr><th>Time 1</th><td>プレイヤ―１の考慮時刻を示します(configのlimit_timeを超えると負けになります)</td></tr>
                    <tr><th>Time 2</th><td>プレイヤ―2の考慮時刻を示します(configのlimit_timeを超えると負けになります)</td></tr>
                    <tr><th>Waiting</th><td>現在対戦募集中かを示します</td></tr>
                    <tr><th>End Time</th><td>対戦の終了時刻を示します(configのroom_timeを超えると入室可能になります)</td></tr>
                    <tr><th>Can Be Chained</th><td>処理に割り込み可能かを示します</td></tr>
                    <tr><th>Mute</th><td>ログを残さないミュートモードになっているのを示します</td></tr>
                    <tr><th>Force</th><td>処理待ちのTriggerに強制のものが含まれているかを示します</td></tr>
                    <tr><th>Trigger Name</th><td>処理を行うかどうかをユーザーに効く時の文言を示します</td></tr>
                    <tr><th>In Execute</th><td>現在効果処理中かを示します</td></tr>
                    <tr><th>Alt Global</th><td>デュエルで使用中の変数を軽減する数値を示します</td></tr>
                    <tr><th>Accumulate Global</th><td>デュエルで使用中の変数を変更をためた数値を示します</td></tr>
                    <tr><th>Wait time</th><td>デュエルを待っているプレイヤーが待ち始めた時刻を示します（configのlimit_timeをすぎると退室になります)</td></tr>
                    <tr><th>Once Per Duel1</th><td>プレイヤー１がデュエル中に１回の処理を行ったかを示します</td></tr>
                    <tr><th>Once Per Duel2</th><td>プレイヤー2がデュエル中に１回の処理を行ったかを示します</td></tr>
                    <tr><th>Once Per Turn1</th><td>プレイヤー１がターン中に１回の処理を行ったか示します</td></tr>
                    <tr><th>Once Per Turn Monster1</th><td>プレイヤー１がカード毎にターン中に１回の処理を行ったか示します</td></tr>
                    <tr><th>Once Per Turn2</th><td>プレイヤー2がターン中に１回の処理を行ったか示します</td></tr>
                    <tr><th>Once Per Turn Monster2</th><td>プレイヤー2がカード毎にターン中に１回の処理を行ったか示します</td></tr>
                    <tr><th>Once Per Turn Group1</th><td>プレイヤー１がターン中に１回の処理群を行ったか示します</td></tr>
                    <tr><th>Once Per Turn Monster1</th><td>プレイヤー１がカード毎にターン中に１回の処理群を行ったか示します</td></tr>
                    <tr><th>Once Per Turn2</th><td>プレイヤー2がターン中に１回の処理群を行ったか示します</td></tr>
                    <tr><th>Once Per Turn Monster2</th><td>プレイヤー2がカード毎にターン中に１回の処理群を行ったか示します</td></tr>
                    <tr><th>Once Per Turn Exist1</th><td>プレイヤー１がカード毎そのカードが存在した場合にターン中に１回の処理を行ったか示します</td></tr>
                    <tr><th>Once Per Turn Exist2</th><td>プレイヤー2がカード毎そのカードが存在した場合にターン中に１回の処理を行ったか示します</td></tr>
                    <tr><th>Once Per Turn Relate1</th><td>プレイヤー１がカード毎そのカードが紐づいていたカードにターン中に１回の処理を行ったか示します</td></tr>
                    <tr><th>Once Per Turn Relate2</th><td>プレイヤー2がカード毎そのカードが紐づいていたカードにターン中に１回の処理を行ったか示します</td></tr>

                    <tr><th>Tmponce Per Duel1</th><td>プレイヤー１がデュエル中に１回の処理を行ったかを示します</td></tr>
 $                  <tr><th>Tmponce Per Duel2</th><td>プレイヤー2がデュエル中に１回の処理を行ったかを示します</td></tr>
                    <tr><th>Tmponce Per Turn1</th><td>プレイヤー１がターン中に１回の処理を一時的に保存します</td></tr>
                    <tr><th>Tmponce Per Turn Monster1</th><td>プレイヤー１がカード毎にターン中に１回の処理を一時的に保存します</td></tr>
                    <tr><th>Tmponce Per Turn2</th><td>プレイヤー2がターン中に１回の処理を一時的に保存します</td></tr>
                    <tr><th>Tmponce Per Turn Monster2</th><td>プレイヤー2がカード毎にターン中に１回の処理を一時的に保存します</td></tr>
                    <tr><th>Tmponce Per Turn Group1</th><td>プレイヤー１がターン中に１回の処理群を一時的に保存します</td></tr>
                    <tr><th>Tmponce Per Turn Monster1</th><td>プレイヤー１がカード毎にターン中に１回の処理群を一時的に保存します</td></tr>
                    <tr><th>Tmponce Per Turn2</th><td>プレイヤー2がターン中に１回の処理群を一時的に保存します</td></tr>
                    <tr><th>Tmponce Per Turn Monster2</th><td>プレイヤー2がカード毎にターン中に１回の処理群を一時的に保存します</td></tr>
                    <tr><th>Tmponce Per Turn Exist1</th><td>プレイヤー１がカード毎そのカードが存在した場合にターン中に１回の処理を一時的に保存します</td></tr>
                    <tr><th>Tmponce Per Turn Exist2</th><td>プレイヤー2がカード毎そのカードが存在した場合にターン中に１回の処理を一時的に保存します</td></tr>
                    <tr><th>Tmponce Per Turn Relate1</th><td>プレイヤー１がカード毎そのカードが紐づいていたカードにターン中に１回の処理を一時的に保存します</td></tr>
                    <tr><th>Tmponce Per Turn Relate2</th><td>プレイヤー2がカード毎そのカードが紐づいていたカードにターン中に１回の処理を一時的に保存します</td></tr>
                    <tr><th>Is Ai</th><td>現在のデュエルがNPC対戦モードかを示します</td></tr>
                    <tr><th>Guest Flag</th><td>現在のプレイヤー１がゲストかを示します   </td></tr>
                    <tr><th>Guest Id</th><td>現在のプレイヤー１がゲストの場合の仮IDを示します</td></tr>
                    <tr><th>Guest Name</th><td>現在のプレイヤー１がゲストの場合の名前を示します</td></tr>
                    <tr><th>Guest Flag2</th><td>現在のプレイヤー2がゲストかを示します   </td></tr>
                    <tr><th>Guest Id2</th><td>現在のプレイヤー2がゲストの場合の仮IDを示します</td></tr>
                    <tr><th>Guest Name2</th><td>現在のプレイヤー2がゲストの場合の名前を示します</td></tr>
                    <tr><th>Ai Choosing</th><td>NPCのデッキを選択中かを示します</td></tr>
                    <tr><th>Current Trigger</th><td>現在処理中のtriggerを示します</td></tr>
                    <tr><th>Effect</th><td>プレイヤー１に向けたエフェクト効果を示します</td></tr>
                    <tr><th>Effect2</th><td>プレイヤー2に向けたエフェクト効果を示します</td></tr>
                    <tr><th>Effect Flag</th><td>エフェクト効果が何個目かを示します</td></tr>
                    <tr><th>Background Image</th><td>現在の背景を示します</td></tr>
                    <tr><th>Change Turn Flag</th><td>現在は使われておりません。無視してください</td></tr>
                    <tr><th>Already Choosed</th><td>処理の選択が終わったかを示します</td></tr>











                    




































                </tbody>
            </table>
        </div>
    )
}