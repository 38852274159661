export const TutorialDefaultDeck =  ()=>{
    return (
        <div className="container">
            <h2>デッキの作成</h2>
            ここではゲームで使用する構築済みデッキを作成します。
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_default_deck_choose.png`}></img></p>
            <p>上図のようにDefault Deckを選択します </p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_default_deck_name.png`}></img></p>
            <p>上図のように構築済みデッキの名前を入力し、実行をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_default_deck_card.png`}></img></p>
            <p>構築済みデッキのカードを入力していきます。上図のように全て入れてしまいましょう</p>
        </div>
    )
}
