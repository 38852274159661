export const ChooseAsUnder = () => {
    return (
        <div className="container">
            <h2>選択自分as_under</h2>
           <h3>使い方</h3>
            <p>カードを<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に属するカードの下のカードの中から選択してそれを別の<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納します</p>
           <p><img src={`${process.env.PUBLIC_URL}/choose_as_under.png`}></img></p>
           <p>この効果では<a href="/tcgcreatoreternalbetaver2/dialogbox">ダイアログボックス</a>を使って入力していきます。koka(②）のカードの下のカードの中から0枚以上1枚以下を(①）koka2(③)
           に格納していきます</p>
        </div>
    )
}
