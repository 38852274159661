export const MoveRelationxCard =  ()=>{
    return (
        <div className="container">
            <h2>移動リレーション  </h2>
           <p>カードに紐づいているカードを移動します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/move_relation_monster.png`}></img></p>
           この例では自分フィールドの(①)battle(②)という紐づけを受けている(④)カードを自分手札(⑤)に移動しています  
        </div>
    )
}
