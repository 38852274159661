import React from 'react';
import './index.css';
import {EnemyDeckChoiceElement} from './enemy_deck_choice_element';
import {EnemyDeckChoiceExample} from './enemy_deck_choice_example';

export const EnemyDeckChoice =  ()=>{
  return (
  <React.Fragment>
    <EnemyDeckChoiceElement />
    <EnemyDeckChoiceExample />
  </React.Fragment>
  )
};
