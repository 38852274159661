export const TutorialPhase =  ()=>{
    return (
        <div className="container">
            <h2>フェイズの作成</h2>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_choose.png`}/></p>
            <p>上図のようにPhaseを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_add.png`}/></p>
            <p>上図のようにAdd Phaseを選択します</p>
            <p>ここではドローフェイズ、メインフェイズ、バトルフェイズ、エンドフェイズのあるゲームを作成したいと思います</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_draw.png`}/></p>
            <p>まずはドローフェイズです。それぞれ以下のような意味があります</p>
            <ul>
                <li>①フェイズの表示順序を表します。ドローフェイズは一番上に表示したいので大きな値を入れてください。ただしstart_phaseより大きな値は入れないでください </li>
                <li>②フェイズの名前です。画面左上に表示します</li>
                <li>③フェイズを左上します </li>
                <li>④優先権放棄を表示するかを決めます。ドローフェイズでは優先権を放棄するのを明示的に決めたいので、チェックを入れています   </li>
                <li>⑤作成したフェイズを保存します</li>
                </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_main.png`}/></p>
            <p>次はメインフェイズを作ります。基本的にドローフェイズと同じですが、優先権を明示的に放棄しないのでpriのチェックは外しておきます</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_battle.png`}/></p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_end.png`}/></p>
            <p>バトルフェイズ及びエンドフェイズも同様に作ります </p>
            <p>下図のようになればフェイズ作成は終了です</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_phase_result.png`}/></p>
        </div>
    )
}
