import React from 'react';
import './index.css';
import {HandElement} from './hand_element';
import {HandExample} from './hand_example';

export const Hand =  ()=>{
  return (
  <React.Fragment>
    <HandElement />
    <HandExample />
  </React.Fragment>
  )
};
