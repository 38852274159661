export const Raise = () => {
    return (
        <div className="container">
            <h2>レイズ</h2>
            <p>カードに紐づいていいない効果を発動します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/raise.png`}></img></p>
           <p>この例ではターンエンドを発動しています

           </p>
        </div>
    )
}
