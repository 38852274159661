import ReactDOM from 'react-dom/client';
import React from 'react';
export const EternalTriggerExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            Etenral Triggerは効果処理の間に行われる効果処理です  
            <p>
            <img src={`${process.env.PUBLIC_URL}/eternal_trigger_example1.png`}></img>
            <img src={`${process.env.PUBLIC_URL}/eternal_trigger_example2.png`}></img>
            </p>
            <p>この例では効果処理が行われる都度にドローする処理を示しています</p>
            
        </div>
    )
}
