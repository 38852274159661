import ReactDOM from 'react-dom/client';
import React from 'react';
export const FieldKindElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Field Name</th><td>フィールドの種類のなめです</td></tr>
                    <tr><th>Mine Or Other</th><td>フィールドの種類が特定のプレイヤーに属するかをしめします。特になしだといずれかのプレイヤーに属し、共通だといずれのプレイヤーにも属しません</td></tr>
                </tbody>
            </table>
        </div>
    )
}