export const Invalid =  ()=>{
    return (
        <div className="container">
            <h2>無効</h2>
           <p>一つ前にチェーンを組まれた効果を無効にします </p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/invalid.png`}></img></p>
           <p>Monster Effect Valで無効を選ぶだけです。特にそのほかは必要ありません
           </p>
        </div>
    )
}
