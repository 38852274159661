export const ChangeAppoint = () => {
    return (
        <div className="container">
            <h2>優先権操作</h2>
            <p>優先権をmonster effectに書いてある情報をもとに操作します</p>
            <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/change_appoint.png`}></img></p>
            <p>この例では現在の処理のユーザーではないユーザーに優先権を渡しています。以下がmonster effectに書くものです。not chain(現在の処理でないユーザーに優先権がわたります) chain(現在の処理のユーザーに優先権が渡ります)  user_turn(現在のターンプレイヤーに優先権がうつります) 
            </p>
        </div>
    )
}
