import ReactDOM from 'react-dom/client';
import React from 'react';
export const DuelDeckExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            <p>
                DuelDeckはデュエル中のデッキを保存するテーブルです。デバッグ用のものです
            </p>
            
        </div>
    )
}