export const DeleteCard =  ()=>{
    return (
        <div className="container">
            <h2>カード削除</h2>
           <p>カードをゲームから削除します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/delete_card.png`}></img></p>
           この例では自分のデッキ(①)の一番上(②③)のカードを消去しています
        </div>
    )
}
