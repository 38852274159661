import React from 'react';
import {MonsterElement} from './monster_element';
import {MonsterExample} from './monster_example';

export const Monster =  ()=>{
  return (
  <React.Fragment>
    <MonsterElement />
    <MonsterExample />
  </React.Fragment>
  )
};
