import ReactDOM from 'react-dom/client';
import React from 'react';
export const EternalEffectExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            Etenral Effectは永続効果の処理を書くためのテーブルです   
            <p>
            <img src={`${process.env.PUBLIC_URL}/eternal_effect_example1.png`}></img>
            <img src={`${process.env.PUBLIC_URL}/eternal_effect_example2.png`}></img>
            <img src={`${process.env.PUBLIC_URL}/eternal_effect_example3.png`}></img>
            <img src={`${process.env.PUBLIC_URL}/eternal_effect_example4.png`}></img>
            </p>
            <p>フィールドに存在する場合自分フィールドのカードの攻撃力を１上がる効果を示しています</p>
            
        </div>
    )
}
