export const MonsterExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            Monsterはカードデータを格納したテーブルです   
            <p>
            <img src={`${process.env.PUBLIC_URL}/monster_example.png`}></img>
            </p>
            <p>この例では「戦士」という名のカードを作っています。「種族」はドラゴン、「攻撃力」は1です</p>
            
        </div>
    )
}
