export const ChooseClear = () => {
    return (
        <div className="container">
            <h2>選択クリア</h2>
            <p><a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納されている情報を削除します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/choose_clear.png`}></img></p>
           <p>①にあるようにMonster Effectに直接入力します。(dialogboxは使いません)この例ではkoka及びkoka2の情報を削除しています

           </p>
        </div>
    )
}
