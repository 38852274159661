export const PhaseMove = () => {
    return (
        <div className="container">
            <h2>フェイズ移動</h2>
            <p>フェイズを移動します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/phase_move.png`}></img></p>
           <p>フェイズを移行します。この例ではドローフェイズに移動しています

           </p>
        </div>
    )
}
