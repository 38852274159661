import ReactDOM from 'react-dom/client';
import React from 'react';

export const NavTutorial =  ()=>{
    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/tcgcreatoreternalbetaver2/tutorial" id="navbarTutorialDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                チュートリアル
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarTutorialDropdown">
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_field">フィールドの作成</a>
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_phase">フェイズの作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_monster_variable">カードパラメータの作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_global_variable">グローバル変数の作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_deck">デッキの作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_grave">墓地の作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_hand">手札の作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_monster">カードの作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_start_up">ゲーム開始処理の作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_to_phase">フェイズ移動の作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_default_deck">構築済みデッキの作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_test">ひとまずテスト</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_draw_phase">ドローフェイズ処理の作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_summon">クリーチャー召喚の作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_battle">クリーチャーどうしの戦闘の作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_direct_attack">直接攻撃の作成</a> 
                <a className="dropdown-item" href="/tcgcreatoreternalbetaver2/tutorial_end_phase">エンドフェイズ処理</a> 
                </div>
        </li>        
    )
}
