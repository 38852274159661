export const CardVariableElement =  ()=>{
    return (
        <div className="container">
            モンスター変数条件はMonsterVariableテーブルに含まれているモンスターパラメータの条件を表します   
            以下の例では攻撃力が１以上、かつ３以下を表します
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_card_variable1.png`}></img></p>
            MonsterVariableの種類に数値以外を与えた場合はその値が使えます
            以下の例では上記条件に加えて、種族がドラゴンであることを示しています
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_card_variable2.png`}></img></p>
        </div>
    )
}
