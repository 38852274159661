export const MonsterEffectElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Monster Effect Val</th><td>処理される効果の種類を表します。くわしくは<a href="/tcgcreatoreternalbetaver2/effect_and_cost_kind">効果または効果種類</a>をご覧ください</td></tr>
                    <tr><th>Monster Effect</th><td>処理の中身を書いたjsonです。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Monster Effect Condition</th><td>処理の条件を書いたjsonです。以下の2つのケースで使います。(１、Cost Valが条件の時. 2、Cost Valが選択の時に追加の条件をつしかしたい場合）。jsonを人が書くのは困難なので、右側の追加で<a href="/tcgcreatoreternalbetaver2/dialog_box">dialog box</a>を使って入力します</td></tr>
                    <tr><th>Prompt</th><td>Monster Effect Valが選択の場合に表示される文字列です。CostWrapperにも設定がある場合そちらが優先されます</td></tr>
                    <tr><th>Sentence</th><td>Monster Effect Valが選択の時に選択時に表示される文字列です   </td></tr>
                    <tr><th>Monster Effect Name</th><td>効果名前です</td></tr>
                    <tr><th>Effect Flag</th><td>永続効果をリセットするかを判定します</td></tr>
                    <tr><th>Change Val Monster Flag</th><td>効果でカードの変数に変更があった場合にTrigger Monster Change Valを呼びだすかを判断します チェックした場合呼び出します</td></tr>
                    <tr><th>Accumulate Flag</th><td>グローバル変数が変動する際に一回一回処理するのではなく、一度に処理するためのフラグです</td></tr>
                </tbody>
            </table>
        </div>
    )
}
