export const Music = () => {
    return (
        <div className="container">
            <h2>音楽鳴らす</h2>
            <p>音楽を鳴らします</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/music.png`}></img></p>
           <p>monster effectに書いた音楽を鳴らします。音楽はstatic/tcgcreator/musicに格納してください。音楽は繰り返し流されます</p>
        </div>
    )
}
