export const MonsterEffectCondition =  ()=>{
    return (
        <div className="container">
            <h2>条件</h2>
           <p>条件の真偽によって、MonsterEffectWrapperやCostWrapperの次の処理が決まります</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/monster_effect_condition.png`}></img></p>
           <p>Monster Conditionの右の追加をクリックして<a href="/tcgcreatoreternalbetaver2/dialogbox">ダイアログボックス</a>に条件を記入します</p>
           <p><img src={`${process.env.PUBLIC_URL}/monster_condition1.png`}></img></p>
           <p><img src={`${process.env.PUBLIC_URL}/monster_condition2.png`}></img></p>
           <p>自分ライフが3000以上で(①)で自分デッキ(②)の枚数が１０枚上３０枚以下(③)かつ、(④)自分の墓地(⑤)が０枚以上５枚以下(⑥)の場合にMonsterEffectNextが実行され、それ以外の場合MonsterEffectNext2が実行されます
           </p>
        </div>
    )
}
