export const GlobalVariableChange = () => {
    return (
        <div className="container">
            <h2>グローバル変数設定</h2>
            <p>global変数を設定します</p>
            <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/global_variable_change.png`}></img></p>
            <p>この例では自分ライフ(①)を最小値が2000(②)最大値が4000(③)になるようにユーザーからの入力で設定します

            </p>
        </div>
    )
}
