export const ChooseMultiple = () => {
    return (
        <div className="container">
            <h2>分岐複数</h2>
            <p>効果の主体のプレイヤーに選択を複数しめし、それにより次の効果処理を決めます</p>
            <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/choose_multiple.png`}></img></p>
            <p>この例ではプレイヤーに対して手札を１枚すてるか(①)、１ドローするか(②)を選ばせ、それにより次のMonsterEffectWrapperが選択されます

            </p>
        </div>
    )
}
