import React from 'react';
import {TriggerTimingMonsterChangeValElement} from './trigger_timing_monster_change_val_element';
import {TriggerTimingMonsterChangeValExample} from './trigger_timing_monster_change_val_example';

export const TriggerTimingMonsterChangeVal =  ()=>{
  return (
  <React.Fragment>
    <TriggerTimingMonsterChangeValElement />
    <TriggerTimingMonsterChangeValExample />
  </React.Fragment>
  )
};
