export const TriggerExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            カードの効果の効果処理条件コスト、効果処理等を表します  
            <p>
            <img src={`${process.env.PUBLIC_URL}/trigger_example1.png`}></img>
            <img src={`${process.env.PUBLIC_URL}/trigger_example2.png`}></img>
            <img src={`${process.env.PUBLIC_URL}/trigger_example3.png`}></img>
            <img src={`${process.env.PUBLIC_URL}/trigger_example4.png`}></img>
            <img src={`${process.env.PUBLIC_URL}/trigger_example5.png`}></img>
            </p>
        <p>この例では自分ターンに手札が１枚以下のときかつフィールドに存在するときに１ドローする処理を表しています</p>
            
        </div>
    )
}
