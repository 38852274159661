import React from 'react';
import './index.css';
import {EnemyDeckExample} from './enemy_deck_example';

export const EnemyDeck =  ()=>{
  return (
  <React.Fragment>
    <EnemyDeckExample />
  </React.Fragment>
  )
};
