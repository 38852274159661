export const Cancel = () => {
    return (
        <div className="container">
            <h2>効果をキャンセルします</h2>
            <p>コストの時のみ有効です。効果をキャンセルします</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/cancel.png`}></img></p>
           <p>効果がキャンセルされ、コストも実行されません</p>
        </div>
    )
}
