export const ChangeGlobalVariableByMonster = () => {
    return (
        <div className="container">
            <h2>変数変動byモンスター</h2>
           <p>グローバル変数をカードによって変動させます（非推奨です）</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/change_global_variable_by_monster.png`}></img></p>
           <p>この例では自分のライフ(②)を自分の墓地(①)のカードの攻撃力の合計＊1000(③)減らす(④) 処理を表しています</p>
        </div>
    )
}
