import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Header} from './header';
import {Footer} from './footer';
import {EternalEffectElement} from './eternal_effect_element';
import {EternalEffectExample} from './eternal_effect_example';
import reportWebVitals from './reportWebVitals';

export const EternalEffect =  ()=>{
  return (
  <React.Fragment>
    <EternalEffectElement />
    <EternalEffectExample />
  </React.Fragment>
  )
};
