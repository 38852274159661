import React from 'react';
import './index.css';
import {FieldKindElement} from './field_kind_element';
import {FieldKindExample} from './field_kind_example';

export const FieldKind =  ()=>{
  return (
  <React.Fragment>
    <FieldKindElement />
    <FieldKindExample />
  </React.Fragment>
  )
};
