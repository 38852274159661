import ReactDOM from 'react-dom/client';
import React from 'react';
export const EndChainElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Priority</th><td>この値が最も高い効果が最初に処理されます   </td></tr>
                    <tr><th>Monster Effect</th><td>効果処理をしまします</td></tr>
                    <tr><th>Monster Effect Kind</th><td>処理の種類です。</td></tr>
                    <tr><th>Monster Effect Next</th><td>次の効果です。（次のMonster Effect Wrapperを選択します)</td></tr>
                    <tr><th>Monster Effect Next2</th><td>次の効果です。例えばMonster Effectで条件を選択した場合条件を満たさない場合こちらが処理されます。</td></tr>
                    <tr><th>Monster Effect Name</th><td>この処理の名前です。記号は名前に使わないでください</td></tr>
                    <tr><th>Log</th><td>この効果が実行された場合に残されるLogです。<a href="/tcgcreatoreternalbetaver2/explian_log">こちらで詳しく解説します</a></td></tr>
                    <tr><th>None Timing</th><td>現在は使われておりません。無視してください</td></tr>
                </tbody>
            </table>
        </div>
    )
}
