export const Log =  ()=>{
  return (
    <div className="container">
        <h1>ログ</h1>
        <p>ここではログの表示について紹介していきたいと思います</p>
        <h2>ログの特殊文字</h2>
        <p>ログの特殊文字はログを表示する際に特定の値に置き換わるものです。必ずカッコで囲ってください。</p>
        <div className="list-group list-group-numbered">
            <span className="list-group-item"><span className="h3">(@)</span> 効果によって影響を受けるカードを表します</span>
            <span className="list-group-item"><span className="h3">(*)</span> 効果で変動する数値の値を表します</span>
            <span className="list-group-item"><span className="h3">(%)</span> 効果で変動する数値の値を表しますが、マイナスした値を表します</span>
            <span className="list-group-item"><span className="h3">(&)</span> 効果によって紐づけられたり、コピーされたりしたカードを表します</span>
            <span className="list-group-item"><span className="h3">(/1/)</span> 効果の主体のプレイヤーを表します</span>
            <span className="list-group-item"><span className="h3">(/2/)</span> 効果の主体でないプレイヤーを表します</span>
            <span className="list-group-item"><span className="h3">($deck;5;1;1)</span>$はデッキや墓地手札のカードを表示します。左記では効果の主体のプレイヤーのデッキを上から５枚表示します </span>
            <span className="list-group-item"><span className="h3">(#)</span> 何も表示しません</span>

        </div>

    </div>
  )
}