export const ToElement =  ()=>{
    return (
        <div className="container">
            カードの移動先を表します
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_to1.png`}></img></p>
            上記例では自分フィールドに移動します
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_to2.png`}></img></p>
            上記例ではkokaという選ばれたフィールドに移動します
            <p><img src={`${process.env.PUBLIC_URL}/dialogbox_to3.png`}></img></p>
            上記例ではデッキの１番上に移動します

        </div>
    )
}
