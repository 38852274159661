export const HandExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
             Handは手札の情報を表すテーブルです  
            <p>
            <img src={`${process.env.PUBLIC_URL}/grave_example.png`}></img>
            </p>
            <p>この例では両方のプレイヤーがそれぞれの手札内容はプレイヤーには公開されない、手札内で効果が発動したり、永続効果があることがありえるということになります</p>
            
        </div>
    )
}
