export const GraveExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
             Graveは墓地の情報を表すテーブルです  
            <p>
            <img src={`${process.env.PUBLIC_URL}/grave_example.png`}></img>
            </p>
            <p>この例では両方のプレイヤーがそれぞれの墓地内容はプレイヤーには公開されない、表示順序の優先度は１００、墓地内で効果が発動したり、永続効果があることがありえるということになります</p>
            
        </div>
    )
}
