import React from 'react';
import {MonsterVariableElement} from './monster_variable_element';
import {MonsterVariableExample} from './monster_variable_example';

export const MonsterVariable =  ()=>{
  return (
  <React.Fragment>
    <MonsterVariableElement />
    <MonsterVariableExample />
  </React.Fragment>
  )
};
