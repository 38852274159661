import ReactDOM from 'react-dom/client';
import React from 'react';
export const DuelHandElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Room Number</th><td>行われているデュエルの部屋番号です</td></tr>
                    <tr><th>Mine Or Other</th><td>手札の持ち主を表しています</td></tr>
                    <tr><th>Hand Id</th><td>行われているデュエルで割り振られている手札のIDです</td></tr>
                    <tr><th>Deck Name</th><td>手札の名前です</td></tr>
                    <tr><th>Hand Content</th><td>現在行われているデュエルの手札の中身です</td></tr>
                </tbody>
            </table>
        </div>
    )
}