import ReactDOM from 'react-dom/client';
import React from 'react';
export const DuelGraveElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Room Number</th><td>行われているデュエルの部屋番号です</td></tr>
                    <tr><th>Mine Or Other</th><td>墓地の持ち主を表しています</td></tr>
                    <tr><th>Grave Id</th><td>行われているデュエルで割り振られている墓地のIDです</td></tr>
                    <tr><th>Deck Name</th><td>墓地の名前です</td></tr>
                    <tr><th>Deck Content</th><td>すみません。間違えです。無視してください</td></tr>
                    <tr><th>Grave Content</th><td>現在行われているデュエルの墓地の中身です</td></tr>
                </tbody>
            </table>
        </div>
    )
}