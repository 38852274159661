import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Header} from './header';
import {Footer} from './footer';
import {DuelHandElement} from './duel_hand_element';
import {DuelHandExample} from './duel_hand_example';
import reportWebVitals from './reportWebVitals';

export const DuelHand =  ()=>{
  return (
  <React.Fragment>
    <DuelHandElement />
    <DuelHandExample />
  </React.Fragment>
  )
};
