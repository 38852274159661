export const TutorialMonster =  ()=>{
    return (
        <div className="container">
            <h2>カードの作成</h2>
            カードの作成方法は２通りあります。一つは管理画面から入れる方法、もう一つはcsvから入力する方法です
            まずは管理画面から入力する方法を見てみます
            <h3>管理画面からのカードの作成</h3>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_choose.png`}></img></p>
            <p>上図のようにMonsterを選択します </p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_add.png`}></img></p>
            <p>上図のようにAdd Monsterを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_input.png`}></img></p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_input2.png`}></img></p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_input3.png`}></img></p>
            <p>上図の様に入力します。それぞれ以下のような意味があります</p>
            <ul>
                <li>①カード名です。ここでは将軍というカードを作ります</li>
                <li>②カードテキストです</li>
                <li>③カードをデッキにいれられる数です</li>
                <li>④カードがいれられるデッキです。ここでは普通のデッキに入るようにしています</li>
                <li>④カードがいれられるデッキです。ここでは普通のデッキに入るようにしています</li>
                <li>⑤⑥は発動する効果及び永続効果と紐づけます。まだここでは入力しません。後ほど紐づけます</li>
                <li>⑦このカードの画像ファイルを入れます。画像ファイルはtcgcreator/static/tcgcreator/imgに入れます</li>
                <li>⑧無視してください</li>
                <li>⑨トークンかを表します。このカードはトークンでないのでチェックを外します</li>
                <li>⑩ここからはMonster Variableで作ったカードパラメータを入れていきます。ここでは攻撃力が１２００という意味です</li>
                <li>⑪ライフは1200にしています</li>
                <li>⑫属性は火にしています。気を付けなくてはならないのは①に対応するものは右のセレクトメニューで選べないので自分で入力しないといけない点です</li>
                <li>⑬種族です注意点は属性と変わりませんここでは人間を選択しています</li>
                <li>⑭⑮rotateとshowは０を入力します</li>
                <li>⑯レベルは８を入力しています</li>
                <li>⑰種類はクリーチャーです。注意点は属性種族と代わりません</li>

                </ul>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_result.png`}></img></p>
            <p>上図のようになれば成功です</p>
            <h3>csvからのカードの作成</h3>
            カードデータはcsvファイルから入力することもできます。csvはexcelで編集し保存可能なので便利です
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_csv_input.png`}></img></p>
            <p>上図のようにExcelで入力します。画像がカードの画像、カード名、カードテキストはそのままの意味です。枚数制限はデッキに入れられる枚数です。残りは自分で入れたカードパラメータです。注意する点としてはshowとrotateは入れないこと、そして、ほかのパラメータは全て入れる必要があるということです</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_csv_data.png`}></img></p>
            <p>入れおえたらExcelでdata.csvという名でtcgcreatorが入っているフォルダに保存します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_url.png`}></img></p>
            <p>最後に上記urlをurl欄に入れます、OKと表示されれば成功です</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_monster_result2.png`}></img></p>
            <p>上図のようになるはずです</p>
        </div>
    )
}
