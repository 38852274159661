import React from 'react';
import './index.css';
import {LockElement} from './lock_element';

export const Lock =  ()=>{
  return (
  <React.Fragment>
    <LockElement />
  </React.Fragment>
  )
};
