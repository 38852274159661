export const CopyEffect = () => {
    return (
        <div className="container">
            <h2>効果コピー</h2>
           <p>カードの効果をコピーして使用します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/copy_in_trigger.png`}></img></p>
           <p><img src={`${process.env.PUBLIC_URL}/copy_effect.png`}></img></p>
           <p>この例ではkoka(①)という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に格納されたカードのコストも含めてコピー(②)して、モンスター効果を(③)を強制的に(④)コピーします。この時triggerのcopyにチェックを入れたものがコピーされます。ちなみにcopy2はこの効果では使用しません。

           </p>
        </div>
    )
}
