export const TutorialField =  ()=>{
    return (
        <div className="container">
            <h2>フィールド種類の作成</h2>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_kind_choose.png`}/></p>
            <p>図のようにField Kindsを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_add_field_kind.png`}/></p>
            <p>右上のadd field_kindをクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_kind_input.png`}/></p>
            <p>上図の用に入力します。それぞれ以下のような意味があります</p>
            <ul>
                <li>①フィールドの種類の名前です。処理には特に影響を及ぼしません</li>
                <li>②フィールドの所有者の種類を表します。「特になし」だとフィールドはどちらかのプレイヤーに属し、「共通」だとそのフィールドはどちらのプレイヤーにも属さないことを示します</li>
                <li>③フィールドの種類を保存します。①と②を入力し終わった後にクリックしてください。 </li>
                </ul>
            <p>同様に下図のように入力してください</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_kind_input2.png`}/></p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_kind_input3.png`}/></p>
            <p>下図のようになったらフィールドの種類の入力は完了です</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_kind_result.png`}/></p>
            <h2>フィールドの大きさの作成</h2>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_size_choose.png`}/></p>
            <p>図のようにField Sizesを選択します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_add_field_size.png`}/></p>
            <p>右上のadd field_sizeをクリックします</p>
            <p>上図の用に入力します。それぞれ以下のような意味があります</p>
            <ul>
                <li>①フィールドの横幅です</li>
                <li>②フィールドの縦幅です</li>
                <li>③フィールドの大きさを保存します。①と②を入力し終わった後にクリックしてください。 </li>
                </ul>
            <h2>フィールドの作成</h2>
            <p>いよいよフィールドの作成に着手します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_choose.png`}/></p>
            <p>図のようにFieldsを選択します</p>
            <h3>相手魔法ゾーンの設定</h3>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_each_choose.png`}/></p>
            <p>図のように一番左上の編集を選択してください</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_input.png`}/></p>
            <p>上図の用に入力します。それぞれ以下のような意味があります</p>
            <ul>
                <li>①フィールドをカードがない場合でも表示します</li>
                <li>②フィールドの種類です。２が魔法ゾーンになっています </li>
                <li>③フィールドの持ち主を示します。一番上は相手から一番近い場所なので相手のゾーンとなっています </li>
                <li>③フィールドを保存します。①と②と③を入力し終わった後にクリックしてください。 </li>
                </ul>
            <p>次に一番上の全ての編集を同様に行います</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_each_choose2.png`}/></p>
            <p>下図のようになれば成功です</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_result.png`}/></p>
            <h3>相手モンスターゾーンの設定</h3>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_each_choose3.png`}/></p>
            <p>図のように2段目の左上の編集を選択してください</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_input2.png`}/></p>
            <p>上図の用に入力します。それが終わったら２段目の全てに同様の入力を行ってください</p>
            <p>下図のようになれば成功です</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_result2.png`}/></p>
            <h3>共通フィールドゾーンの設定</h3>
            <p>真ん中の編集をクリックします</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_input3.png`}/></p>
            <p>上図の用に入力します。</p>
            <p>下図のようになれば成功です</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_result3.png`}/></p>
            <h3>自分モンスターゾーン及び魔法ゾーンの設定</h3>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_each_choose5.png`}/></p>
            <p>4段目を以下のように設定します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_input4.png`}/></p>
            <p>下図のようになれば自分モンスターゾーンの設定は成功です</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_result4.png`}/></p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_each_choose6.png`}/></p>
            <p>5段目を以下のように設定します</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_input5.png`}/></p>
            <p>下図のようになればフィールドの設定は成功です</p>
            <p><img src={`${process.env.PUBLIC_URL}/tutorial_field_result5.png`}/></p>
        </div>
    )
}
