export const Mute = () => {
    return (
        <div className="container">
            <h2>ミュート</h2>
            <p>一時的にコストのログをでなくします</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/mute.png`}></img></p>
           <p>コストのログがでなくなります。タイミングが代わった時に一斉に出力されます</p>
        </div>
    )
}
