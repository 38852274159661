import {DropDownDialogBox} from './dropdown_dialogbox';
import {DropDownEquationEditor} from './dropdown_equation_editor';
import {DropDownCardVariable} from './dropdown_card_variable';
import {DropDownMonsterEffect} from './dropdown_monster_effect';
import {DropDownLog} from './dropdown_log';
export const NavDocument =  ()=>{
    return (
        <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" href="/tcgcreatoreternalbetaver2/document" id="navbarDocumentDropdown" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            ドキュメント                
            </a>
            <ul className="dropdown-menu" aria-labelledby="navbarDocumentDropdown">
            <DropDownDialogBox></DropDownDialogBox>
            <DropDownEquationEditor></DropDownEquationEditor>
            <DropDownCardVariable></DropDownCardVariable>
            <DropDownMonsterEffect></DropDownMonsterEffect>
            <DropDownLog></DropDownLog>
            </ul>
         </li>
    )
}
