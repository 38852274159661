import ReactDOM from 'react-dom/client';
import React from 'react';
export const ConstraintElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Monster Variable</th><td>デッキに制限をかけるカードのパラメーターです</td></tr>
                    <tr><th>Except Val</th><td>Monster Variableに指定されていても例外的にあらゆるデッキに入れられる値です。</td></tr>
                    <tr><th>Limit </th><td>Monster Varialbleに指定されているもの最大種類数を指定します</td></tr>
                </tbody>
            </table>
        </div>
    )
}