import ReactDOM from 'react-dom/client';
import React from 'react';
export const DefaultDeckChoiceExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
              DefaultDeckChoiceはゲストが使うデフォルト
            <p>
            <img src={`${process.env.PUBLIC_URL}/default_deck_choice_example.png`}></img>
            </p>
            <p>default deck groupに名前はないのですが、数字で判断することになります</p>
            
        </div>
    )
}
