export const TriggerTimingMonsterChangeValExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            カードのパラメーターが変動した時の効果処理を保存するテーブルです 
            <p>
            <img src={`${process.env.PUBLIC_URL}/trigger_timing_monster_change_val_example1.png`}></img>
            <img src={`${process.env.PUBLIC_URL}/trigger_timing_monster_change_val_example2.png`}></img>
            </p>
        <p>この例では戦士が１以上カードパラメータが変化したときに１ドローする効果を作っています</p>
            
        </div>
    )
}
