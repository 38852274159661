export const UserPointElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>User</th><td>勝敗のユーザーです</td></tr>
                    <tr><th>Win</th><td>ユーザーが対人で勝った数です</td></tr>
                    <tr><th>Win Ai</th><td>ユーザーがNPCに勝った数です</td></tr>
                    <tr><th>Lose</th><td>ユーザーが対人で勝った数です</td></tr>
                    <tr><th>Point</th><td>ユーザーのポイントです</td></tr>
                    <tr><th>Draw</th><td>引き分けの数です</td></tr>
                </tbody>
            </table>
        </div>
    )
}