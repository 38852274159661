import React from 'react';
import './index.css';
import {DuelGraveElement} from './duel_grave_element';
import {DuelGraveExample} from './duel_grave_example';

export const DuelGrave =  ()=>{
  return (
  <React.Fragment>
    <DuelGraveElement />
    <DuelGraveExample />
  </React.Fragment>
  )
};
