import React from 'react';
import {TriggerTimingElement} from './trigger_timing_element';
import {TriggerTimingExample} from './trigger_timing_example';

export const TriggerTiming =  ()=>{
  return (
  <React.Fragment>
    <TriggerTimingElement />
    <TriggerTimingExample />
  </React.Fragment>
  )
};
