import React from 'react';
import {PacElement} from './pac_element';
import {PacExample} from './pac_example';

export const Pac =  ()=>{
  return (
  <React.Fragment>
    <PacElement />
    <PacExample />
  </React.Fragment>
  )
};
