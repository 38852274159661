
export const DropDownDialogBox =  ()=>{
    return (
                <li className="dropdown">
            <a href="/tcgcreatoreternalbetaver2/dialogbox" className="dropdown-item">ダイアログボックス</a>
            <ul className="dropdown-submenu dropdown-menu">
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_global_variable" className="dropdown-item">グローバル変数条件</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_group" className="dropdown-item">条件複数</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_place" className="dropdown-item">カードが存在する場所</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_flag" className="dropdown-item">カードのフラグ</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_card_name" className="dropdown-item">カードの名前の条件</a></li>

                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_turn_count" className="dropdown-item">カードのターン</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_card_from" className="dropdown-item">カードが移動してきた場合</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_card_variable" className="dropdown-item">カードのパラメーター条件</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_card_custom_variable" className="dropdown-item">カードのパラメーター条件(登録されていないもの)</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_under" className="dropdown-item">カードの下のカード</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_relation" className="dropdown-item">カードの紐づけ条件</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_monster_effect_valid" className="dropdown-item">カードの効果が有効か</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_monster_exist" className="dropdown-item">カード有無</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_field_place" className="dropdown-item">フィールドのカードの存在する場所</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_exclude" className="dropdown-item">選択や移動から除外するカード</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_card_num" className="dropdown-item">カード枚数</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_as" className="dropdown-item">カード変数</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_variable" className="dropdown-item">カード変数変動</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_add_relation" className="dropdown-item">カード紐づけ追加</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_as_monster_to" className="dropdown-item">移動後のカード変数</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_to" className="dropdown-item">移動先</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_other" className="dropdown-item">同名カード不可等</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/dialogbox_choose" className="dropdown-item">効果を受けるか</a></li>

            </ul>
            </li>
            )
}
