export const VirtualVariableElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Variable Name</th><td>疑似グローバル変数の名前です</td></tr>
                    <tr><th>Priority</th><td>疑似グローバル変数を表示する場合の表示順です（大きいほうが上になります）</td></tr>
                    <tr><th>Show</th><td>疑似グローバル変数が表示されるかを表します</td></tr>
                    <tr><th>Value</th><td>疑似グローバル変数のとる値を決めます。<a href="/tcgcreatoreternalbetaver2/equation_editor">数式エディタ</a>で入力します</td></tr>
                    <tr><th>Center</th><td>無視してください</td></tr>
                    <tr><th>Font Size</th><td>疑似グローバル変数を表示する際のフォントの大きさです</td></tr>

                </tbody>
            </table>
        </div>
    )
}
