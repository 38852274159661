export const Comment = () => {
    return (
        <div className="container">
            <h2>コメント</h2>
            <p>処理は行われず、logだけが残ります</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/comment.png`}></img></p>
        </div>
    )
}
