import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Header} from './header';
import {Footer} from './footer';
import {MonsterEffectWrapperElements} from './monster_effect_wrapper_element';
import {MonsterEffectWrapperExample} from './monster_effect_wrapper_example';
import reportWebVitals from './reportWebVitals';

export const MonsterEffectWrapper =  ()=>{
  return (
  <React.Fragment>
    <MonsterEffectWrapperElements />
    <MonsterEffectWrapperExample />
  </React.Fragment>
  )
};
