import ReactDOM from 'react-dom/client';
import React from 'react';
export const FieldSizeExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
                FieldSizeはフィールドの大きさを設定する項目です (注意：FieldSizeを設定するとFieldの情報は全て初期化されます)
            <p>
            <img src={`${process.env.PUBLIC_URL}/field_size_example.png`}></img>
            </p>
            <p>この例では「フィールド」という名前の特定のプレイヤーに属するフィールドの種類を作っています</p>
            
        </div>
    )
}
