export const MonsterVariableKindElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Monster Variable Sentence</th><td>カードの変数の取りうる値を示します</td></tr>
                    <tr><th>Monster Variable Name</th><td>カードの変数の種類の名前を示します</td></tr>
                </tbody>
            </table>
        </div>
    )
}