export const MonsterEffectKindElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Monster Effect Name</th><td>カード効果の種類の名前を表します</td></tr>
                    <tr><th>Monster Effect Show</th><td>無視してください</td></tr>
                </tbody>
            </table>
        </div>
    )
}