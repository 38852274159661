import ReactDOM from 'react-dom/client';
import React from 'react';
export const TimingElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Next Timing</th><td>このタイミングの次のタイミングを示します</td></tr>
                    <tr><th>Timing Name</th><td>タイミングの名前です</td></tr>
                    <tr><th>Timing Auto</th><td>自動的に次のタイミングになることを示します。現在不推奨です</td></tr>
                    <tr><th>Timing Whether Show</th><td>タイミングに割り込みをするかを選択できるようにするかを示します</td></tr>
                    <tr><th>Pri</th><td>優先権放棄を表示するかを表します</td></tr>
                </tbody>
            </table>
        </div>
    )
}