export const SpecialCardExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            特別なカードの情報を保存するテーブルです 
            <p>
            <img src={`${process.env.PUBLIC_URL}/special_card_example.png`}></img>
            </p>
        <p>この例では戦士というカードがデッキに入っている場合デッキの最低サイズが０最高サイズが５になることを表しています </p>
            
        </div>
    )
}
