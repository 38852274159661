import ReactDOM from 'react-dom/client';
import React from 'react';
export const ConstraintExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            Constraintはデッキの組み方に制限をもうけるためのテーブルです。
            <p>
            <img src={`${process.env.PUBLIC_URL}/constraint_example.png`}></img>
            </p>
            この例では種族で2以外の種族を１種類まで入れることができます。注意しなければならないのはexcept_valに指定できるのは数値だということです。（文字列で保存しても数値を持ってくる必要がある)
        </div>
    )
}
