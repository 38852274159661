export const FusionChoose =  ()=>{
    return (
        <div className="container">
            <h2>融合選択</h2>
           <p>融合するカードを選択します</p>
           <h3>使い方</h3>
           <p><img src={`${process.env.PUBLIC_URL}/fusion_monster.png`}></img></p>
           <p>上の例では自分の手札(①)から１枚fusion(②)という<a href="/tcgcreatoreternalbetaver2/card_variable">カード変数</a>に融合するカードを格納することを示しています</p>
        </div>
    )
}
