import ReactDOM from 'react-dom/client';
import React from 'react';
export const FieldSizeElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Field X</th><td>フィールドの横幅です</td></tr>
                    <tr><th>Field Y</th><td>フィールドの縦幅です</td></tr>
                </tbody>
            </table>
        </div>
    )
}