
export const DropDownMonsterEffect =  ()=>{
    return (
                <li className="dropdown">
            <a href="/tcgcreatoreternalbetaver2/monster_effect_page" className="dropdown-item">効果の種類</a>
            <ul className="dropdown-submenu dropdown-menu">
                <li><a href="/tcgcreatoreternalbetaver2/monster_effect_condition" className="dropdown-item">条件</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/alt_val" className="dropdown-item">軽減条件</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/chain_condition" className="dropdown-item">チェーン条件</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/phase_condition" className="dropdown-item">タイミングフェイズ条件</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/invalid" className="dropdown-item">無効</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/move" className="dropdown-item">移動</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/fusion_choose" className="dropdown-item">融合選択</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/fusion_material" className="dropdown-item">融合素材</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/fusion_unique_effect" className="dropdown-item">融合固有効果</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/move_under" className="dropdown-item">移動under</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/move_relation_card" className="dropdown-item">移動リレーション</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/move_to_under" className="dropdown-item">下に重ねる</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/move_to_top" className="dropdown-item">上に重ねる</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/move_simple" className="dropdown-item">シンプル移動</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/create_token" className="dropdown-item">カード生成</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/delete_card" className="dropdown-item">カード消去</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_global_variable" className="dropdown-item">変数変動</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_global_variable_order" className="dropdown-item">変数変動順番</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_global_variable_order2" className="dropdown-item">変数変動順番相手</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/alt_val_change" className="dropdown-item">変数軽減</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/acc_val_flush" className="dropdown-item">変数変動flush</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_global_variable_by_monster" className="dropdown-item">変数変動byモンスター</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_global_variable_multiple" className="dropdown-item">変数変動複数</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_global_variable_show" className="dropdown-item">変数表示変更</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_monster_variable" className="dropdown-item">モンスター変数変動</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_monster_variable_relation" className="dropdown-item">モンスター変数変動リレーション</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_monster_relation" className="dropdown-item">モンスターリレーション</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_monster_relation_change" className="dropdown-item">モンスターリレーション付け替え</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_monster_relation_clear" className="dropdown-item">モンスターリレーションクリア</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_monster_relation_all_clear" className="dropdown-item">モンスターリレーション全部クリア</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/copy_monster" className="dropdown-item">モンスターコピー</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/copy_monster_specify" className="dropdown-item">モンスターコピーモンスター指定</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/copy_effect" className="dropdown-item">効果コピー</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/copy_effect2" className="dropdown-item">効果コピー2</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/copy_effect_cost" className="dropdown-item">効果コピー(コスト)</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/choose" className="dropdown-item">選択</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/choose_as" className="dropdown-item">選択自分as</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/choose_as_under" className="dropdown-item">選択自分as_under</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/choose_as_rewrite" className="dropdown-item">選択リライト自分</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/choose_opponent" className="dropdown-item">選択相手</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/choose_clear" className="dropdown-item">選択クリア</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/choose_reload" className="dropdown-item">選択リロード</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/choose_reload2" className="dropdown-item">選択リロード2</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/phase_choose" className="dropdown-item">フェイズ移動</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/turn_end" className="dropdown-item">ターンエンド</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/shuffle" className="dropdown-item">シャッフル</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/clear" className="dropdown-item">フラグクリア</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/raise" className="dropdown-item">レイズ</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_appointment" className="dropdown-item">優先権移行</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/next_timing" className="dropdown-item">タイミング次に移行</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_timing" className="dropdown-item">タイミング移行</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/raise_eternal" className="dropdown-item">永続レイズ</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/coin" className="dropdown-item">コイントス</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/random" className="dropdown-item">ランダム分岐</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/yes_or_no" className="dropdown-item">Yes Or No</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/yes_or_no_opponent" className="dropdown-item">Yes Or No相手</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/choose_multiple" className="dropdown-item">分岐複数</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/choose_multiple_opponent" className="dropdown-item">分岐複数相手</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/move_to_timing_variable" className="dropdown-item">タイミング変数移動</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/chain_variable" className="dropdown-item">チェーン変数設定</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/chain_variable_opponent" className="dropdown-item">チェーン変数設定相手</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/global_variable_change" className="dropdown-item">グローバル変数設定</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/global_variable_change_opponent" className="dropdown-item">グローバル変数設定相手</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/relation_multiple" className="dropdown-item">多対多</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/relation_multiple_opponent" className="dropdown-item">多対多相手</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/change_appoint" className="dropdown-item">優先権操作</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/force" className="dropdown-item">強制発現</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/mute" className="dropdown-item">ミュート</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/music" className="dropdown-item">音楽鳴らす</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/sound" className="dropdown-item">効果音鳴らす</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/cancel" className="dropdown-item">キャンセル</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/win" className="dropdown-item">勝利</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/lose" className="dropdown-item">敗北</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/draw" className="dropdown-item">引き分け</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/each" className="dropdown-item">each</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/do_cost" className="dropdown-item">コスト実行</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/do_eternal" className="dropdown-item">永続リセット</a></li>
                <li><a href="/tcgcreatoreternalbetaver2/comment" className="dropdown-item">コメント</a></li>

            </ul>
            </li>
            )
}
