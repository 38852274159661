
export function Game() {
  return (
    <div className="container">
        <h2>tcgcreator eternal betaで実際に作っているゲーム</h2>
        <ul>
            <li>
                <h3>
        <a href="https://www.tcgcreator.xyz/spimate/index">新スピマテオンライン</a></h3>ヒロさんが作られた５枚から遊べるｔｃｇです
        </li>
            <li>
                <h3>
        <a href="https://www.tcgcreator.xyz/waruen/index">ワルエン</a></h3>ネモンドさんが作られたデッキがライフのドローしまくりなtcgです
        </li>
            <li>
        <h3><a href="https://www.tcgcreator.xyz/cradle/index">クレイドル</a></h3>記卓部さんが作られたレベルアップに特徴のあるtcgです
        </li>
            <li>
       <h3><a href="https://www.tcgcreator.xyz/tcgcreator/index/">カードル♪LIVE ON WEB</a></h3>カードルさんが作られたライブでファンをあつめる数を競うtcgです
        </li>
        </ul>

    </div>
  )
}