export const LockElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>Lock1</th><td>部屋１がロックされていることを示します</td></tr>
                    <tr><th>Time1</th><td>ロックした時刻を表します。ロック開始から10秒後に自動的に解除されます</td></tr>
                    <tr><th>Lock2</th><td>部屋2がロックされていることを示します</td></tr>
                    <tr><th>Time2</th><td>ロックした時刻を表します。ロック開始から10秒後に自動的に解除されます</td></tr>
                    <tr><th>Lock3</th><td>部屋3がロックされていることを示します</td></tr>
                    <tr><th>Time3</th><td>ロックした時刻を表します。ロック開始から10秒後に自動的に解除されます</td></tr>
                </tbody>
            </table>
        </div>
    )
}