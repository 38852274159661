export const VirtualVariableExample =  ()=>{
    return (
        <div className="container">
            <h2>使い方</h2>
            疑似グローバル変数のとる値を表したテーブルです
            <img src={`${process.env.PUBLIC_URL}/virtual_variable_example.png`}></img>
        <p>この例ではフィールドのカードの数という疑似グローバル変数が自分フィールドのカードの数の値を取ることを表しています</p>
            
        </div>
    )
}
