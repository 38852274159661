export const UserDeckGroupElement =  ()=>{
    return (
        <div className="container">
            <h2>テーブルの項目名</h2>
            <table className="table table-striped">
                <tbody>
                    <tr><th>User</th><td>デッキの所有者のユーザーです</td></tr>
                    <tr><th>Deck Name</th><td>デッキ群の名前です</td></tr>
                    <tr><th>User Deck Id</th><td>デッキ群のIDです</td></tr>
                </tbody>
            </table>
        </div>
    )
}